import React, { useEffect, useState } from "react";
import {
  Box,
  Tabs,
  Chip,
  Typography,
  Tooltip,
  Grid,
  ClickAwayListener,
  IconButton,
} from "@mui/material";
import { ProductTabPanel } from "../productTabPanel/ProductTabPanel";
import { User } from "oidc-client";
import AlternativeProducts from "../productResultItem/AlternativeProducts";
import DisassembledProduct from "../productResultItem/disassembledProduct/DisassembledProduct";
import {
  InformationCircleIcon,
  LoadingIndicator,
} from "@rockwell-automation-inc/ra-meatball";
import SearchProduct from "../../interfaces/SearchProduct";
import ProductApiService from "../../services/apis/ProductApiService";
import ProductComponentResponse from "../../interfaces/ProductComponentResponse";

interface AlternativeProductTabsProps {
  catalogNumber: string;
  user: User | undefined;
}

export const AlternativeProductTabs = (props: AlternativeProductTabsProps) => {
  const { catalogNumber, user } = props;

  const [value, setValue] = React.useState(0);

  const handleChange = (newValue: number) => {
    setValue(newValue);
    if (indexSameFamily === newValue) {
      const tabClick = new CustomEvent("sameFamilyClick", {
        detail: {
          action: "Same Family Click",
          properties: {
            category: "WebApp",
            label: "Same Family",
            catalogNumber: catalogNumber,
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    } else if (indexSimilar === newValue) {
      const tabClick = new CustomEvent("curatedSuggestionsClick", {
        detail: {
          action: "Curated Suggestions Click",
          properties: {
            category: "WebApp",
            label: "Curated Suggestions",
            catalogNumber: catalogNumber,
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    } else if (indexDisassembled === newValue) {
      const tabClick = new CustomEvent("disassembledDirectReplacementClick", {
        detail: {
          action: "Disassembled Direct Replacement Click",
          properties: {
            category: "WebApp",
            label: "Disassembled Direct Replacement",
            catalogNumber: catalogNumber,
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    }
  };

  function a11yProps(index: number) {
    return {
      id: `alternative-product-tab-${index}`,
      "aria-controls": `alternative-product-tabpanel-${index}`,
    };
  }

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const indexSameFamily = 0;
  const indexSimilar = 1;
  const indexDisassembled = 2;

  const [loading, setLoading] = useState(true);
  const LOCALE = "US";
  const [products, setProducts] = useState<Array<SearchProduct>>([]);
  const [products2, setProducts2] = useState<Array<SearchProduct>>([]);
  const [productComponentsArray, setProductComponentsArray] =
    useState<ProductComponentResponse>();
  const [showTabs, setShowTabs] = useState<string>("none");
  const defaultMessage =
    "Sorry, there are no alternative products available for this product.";

  useEffect(() => {
    if (catalogNumber === "") {
      return;
    }

    const api2 = ProductApiService.GetAlternativeProducts(
      catalogNumber,
      LOCALE
    );
    api2.then((response) => {
      setLoading(false);
      if (response.data.products.length > 10) {
        setProducts2(response.data.products.slice(0, 10));
      } else {
        setProducts2(response.data.products);
      }
    });
  }, [catalogNumber]);

  useEffect(() => {
    if (catalogNumber.length > 0) {
      const searchapi = ProductApiService.GetProductComponents(catalogNumber);

      searchapi
        .then((response) => {
          const searchComponents: ProductComponentResponse = response.data;
          setProductComponentsArray(searchComponents);
        })
        .catch((error) => {
          // Handle error
        });
    }
  }, [catalogNumber]);

  useEffect(() => {
    if (
      products?.length > 0 ||
      products2?.length > 0 ||
      productComponentsArray !== undefined
    ) {
      setShowTabs("");
    }
  }, [products, products2, productComponentsArray]);

  return (
    <>
      {loading ? (
        <LoadingIndicator></LoadingIndicator>
      ) : (
        <>
          {products2?.length === 0 &&
          productComponentsArray?.components.length === 0 ? (
            <>
              <Typography>{defaultMessage}</Typography>
            </>
          ) : (
            <Box sx={{ display: showTabs }}>
              <Grid sx={{ mb: 2 }}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "26px",
                    }}
                  >
                    Alternative Product Categories
                  </Typography>
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Tooltip
                      title="DISCLAIMER: This tool and the information provided herin are provided 'As is' and intended to provide general technical and commercial information. Users should always validate the suggested information by evaluating with a distributor product specialist to determine if the application would be suitable for a given product before making any purchasing or technical decisions. Inventory values may change any time, and we recommend you confirm availability with Rockwell Customer Care."
                      open={open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      onClose={handleTooltipClose}
                      sx={{ ml: 1 }}
                    >
                      <IconButton>
                        <InformationCircleIcon
                          onClick={handleTooltipOpen}
                        ></InformationCircleIcon>
                      </IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </Grid>
              </Grid>
              <Tabs value={value} sx={{ borderBottom: "none" }}>
                {products2 === undefined ||
                  (products2.length > 0 && (
                    <>
                      <Chip
                        sx={{ mr: 1 }}
                        label="Curated Suggestions"
                        variant={value === 1 ? "filled" : "outlined"}
                        color="info"
                        onClick={() => handleChange(1)}
                        {...a11yProps(indexSimilar)}
                      />
                    </>
                  ))}
                {user &&
                  productComponentsArray !== undefined &&
                  productComponentsArray.components.length > 0 && (
                    <Chip
                      label="Disassembled Direct Replacement"
                      variant={value === 2 ? "filled" : "outlined"}
                      color="info"
                      onClick={() => handleChange(2)}
                      {...a11yProps(indexDisassembled)}
                    />
                  )}
              </Tabs>
              <ProductTabPanel value={value} index={indexSameFamily}>
                {products !== undefined && products.length > 0 && (
                  <AlternativeProducts
                    catalogNumber={catalogNumber}
                    endPointToUse={0}
                  ></AlternativeProducts>
                )}
              </ProductTabPanel>
              <ProductTabPanel value={value} index={indexSimilar}>
                {products2 === undefined ||
                  (products2.length > 0 && (
                    <AlternativeProducts
                      catalogNumber={catalogNumber}
                      endPointToUse={1}
                    ></AlternativeProducts>
                  ))}
              </ProductTabPanel>
              <ProductTabPanel value={value} index={indexDisassembled}>
                {user &&
                  productComponentsArray !== undefined &&
                  productComponentsArray.components.length > 0 && (
                    <DisassembledProduct
                      catalogNumber={catalogNumber}
                    ></DisassembledProduct>
                  )}
              </ProductTabPanel>
            </Box>
          )}
        </>
      )}
    </>
  );
};
