import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";

const initialState = 25;

export const numberOfResultsPerPage = createSlice({
  name: "numberOfResultsPerPage",
  initialState,
  reducers: {
    setNumberOfResultsPerPage: (state, action: PayloadAction<number>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setNumberOfResultsPerPage } = numberOfResultsPerPage.actions;

export const selectNumberOfResultsPerPage = (state: RootState) =>
  state.numberOfResultsPerPage;

export default numberOfResultsPerPage.reducer;
