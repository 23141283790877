import { Box, Button, SxProps } from "@mui/material";
import LayoutType from "../../constants/LayoutType";
import { ReactElement } from "react";
import { ListViewIcon } from "@rockwell-automation-inc/ra-meatball";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";

interface LayoutSwitchButtonProps {
  currentLayout: string;
  handleLayoutChange: (layoutType: string) => void;
  sx?: SxProps;
}

const LayoutSwitchButton: React.FC<LayoutSwitchButtonProps> = ({
  currentLayout,
  handleLayoutChange,
  sx,
}) => {
  const onButtonClick = (layout: string) => {
    handleLayoutChange(layout);
    if (layout === "Grid") {
      const tabClick = new CustomEvent("gridViewClick", {
        detail: {
          action: "Grid View Click",
          properties: {
            category: "WebApp",
            label: "Grid View",
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    } else if (layout === "List") {
      const tabClick = new CustomEvent("listViewClick", {
        detail: {
          action: "List View Click",
          properties: {
            category: "WebApp",
            label: "List View",
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    }
  };

  interface LayoutButtonProps {
    id: string;
    title: string;
    currentLayout: string;
    onButtonClick: (layout: string) => void;
    icon?: ReactElement;
    sx?: SxProps;
  }
  const LayoutButton: React.FC<LayoutButtonProps> = ({
    id,
    title,
    currentLayout,
    onButtonClick,
    icon,
  }) => {
    return (
      <Button
        disableRipple
        disableFocusRipple
        startIcon={icon && icon}
        onClick={() => onButtonClick(id)}
        sx={{
          textDecoration: currentLayout === id ? "underline" : "none",
          ":hover": {
            textDecoration: "underline",
            backgroundColor: "inherit",
          },
          fontSize: "16px",
          lineHeight: "24px",
          height: "36px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&:active": {
            backgroundColor: "inherit",
          },
          "&:focus": {
            backgroundColor: "inherit",
          },
        }}
      >
        {title}
      </Button>
    );
  };
  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      <LayoutButton
        id={LayoutType.Grid}
        title={"Grid View"}
        currentLayout={currentLayout}
        onButtonClick={onButtonClick}
        icon={
          <GridViewOutlinedIcon
            sx={{
              width: "12px",
              height: "12px",
            }}
          />
        }
      />
      <LayoutButton
        id={LayoutType.List}
        title={"List View"}
        currentLayout={currentLayout}
        onButtonClick={onButtonClick}
        icon={
          <ListViewIcon
            sx={{
              width: "12px",
              height: "12px",
            }}
          />
        }
      />
    </Box>
  );
};
export default LayoutSwitchButton;
