import {
  Container,
  Typography,
  Grid,
  Button,
  Box,
  useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import configData from "../../configFiles/home-page-cards.json";
import {
  ChevronRightIcon,
  TeaserCard,
  TeaserCardContent,
  TeaserCardMedia,
  TeaserCardSubtitle,
  TeaserCardTitle,
} from "@rockwell-automation-inc/ra-meatball";
import { Link as MuiLink } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Masthead from "../../components/masthead/Masthead";
import config from "../../config";
import ROUTES from "../../constants/Routes";
import { GetURLQueryVariable } from "../../util/GetURLQueryVariable";
import React, { useEffect, useState } from "react";
import Aside from "../../components/aside/Aside";
import backgroundImage from "../../components/aside/Background-texture_Red-blue.png";

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const thisConfig: any = config;
  const [isOpen, setIsOpen] = useState(false);

  const handleSplashInfo = () => {
    setIsOpen(!isOpen);
  };

  const handleLink = (launch: string, link: string) => {
    switch (launch) {
      case "InTab":
        handleRoute(link);
        break;
      case "NewTab":
        handleRedirect(link);
        break;
    }
    const homePageProducts = new CustomEvent("homeProductSelection", {
      detail: {
        action: "HomePageSelection",
        properties: {
          category: "WebApp",
          label: "Selected Product",
          product: link,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(homePageProducts);
  };

  const isNormalScreen = useMediaQuery("(min-width: 1105px)");

  const handleSystemDesignerNavigation = (
    e: React.MouseEvent<HTMLDivElement>,
    link: string
  ) => {
    let target = "_self";
    if (e.ctrlKey) {
      target = "_blank";
    }
    window.open(
      `${link}?projectGuid=${GetURLQueryVariable(
        "projectGuid"
      )}&projectId=${GetURLQueryVariable("projectId")}`,
      target
    );
    const tabClick = new CustomEvent("powerControlAdvisorNavigation", {
      detail: {
        action: "Power Control Advisor Navigation",
        properties: {
          category: "WebApp",
          label: "Power Control Click from Home Page",
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleRoute = (link: string) => {
    const tempConfig: any = config;
    const path = link.replace(tempConfig.DOMAIN_URL, "");
    navigate(path);
  };

  const handleRedirect = async (link: string) => {
    window.open(link, "_blank");
  };

  const PromotionalTeaserButtonGroup = () => {
    return (
      <>
        <Button
          variant="outlined"
          color="white"
          style={{ fontSize: "1rem" }}
          onClick={() => window.open(configData.ActionGroups[3].Link, "_blank")}
        >
          Click Here to Download
        </Button>
      </>
    );
  };

  const isDev = (tool: any) => {
    const url = window.location.href;

    if (
      (tool.Description === "Drives" && url.includes("advisordev")) ||
      (tool.Description === "Drives" && url.includes("localhost"))
    ) {
      return "/familysearch";
    }
    return tool.Link;
  };

  const featureFlag = () => {
    if (window.location.origin.includes("dev")) {
      return (
        <Box display="flex" justifyContent="end">
          <Button
            onClick={handleSplashInfo}
            sx={{
              width: "auto",
              boxShadow: "0 1px 5px 0 rgb(0 0 0 / 25%)",
              background:
                "linear-gradient(90deg,rgba(0,0,0,.63) 0,transparent) 50%/cover no-repeat",
              backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.63) 0%, rgba(0, 0, 0, 0) 100%), url("${backgroundImage}")`,
            }}
          >
            <Typography fontWeight="bold" color="#fff">
              What's new
            </Typography>
          </Button>
          {isOpen && (
            <Aside
              isOpen={isOpen}
              handleClose={() => {
                setIsOpen(false);
              }}
            />
          )}
        </Box>
      );
    }
  };

  return (
    <>
      <Masthead isBanner sticky={false} />

      <Container
        sx={{ mb: 0, mt: 4, pt: 0, pb: 4, pl: 0, pr: 0 }}
        style={{ backgroundColor: "white" }}
      >
        {/*<Grid*/}
        {/*  container*/}
        {/*  justifyContent="center"*/}
        {/*  direction="row"*/}
        {/*  alignItems="center"*/}
        {/*  sx={{ pt: 4, mb: 2, width: "100%" }}*/}
        {/*>*/}
        {/*  <Box sx={{ width: "80%" }}>*/}
        {/*    <SearchForm />*/}
        {/*  </Box>*/}
        {/*</Grid>*/}
        <Grid container direction="column" rowGap={2}>
          <Grid
            container
            direction="row"
            sx={{ py: 4 }}
            justifyContent="space-between"
          >
            <Grid container direction="column" md={7} sx={{ mb: 2 }}>
              <Box sx={{ mb: 4, display: "flex", alignItems: "flex-end" }}>
                <Typography
                  variant="h4"
                  component="h4"
                  fontWeight="bold"
                  sx={{ pr: 3, lineHeight: 1 }}
                >
                  {configData.ActionGroups[0].Group}
                </Typography>
                <MuiLink
                  component={Link}
                  className="browse-link"
                  to={ROUTES.BROWSE}
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "500",
                    color: "primary",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  VIEW ALL
                  <ChevronRightIcon />
                </MuiLink>
              </Box>
              <Grid container direction="row" spacing={2}>
                {configData.ActionGroups[0].Tools?.map((tool, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={3}
                      justifyContent="center"
                      alignItems="center"
                      key={index}
                    >
                      <TeaserCard
                        sx={{
                          ml: "auto",
                          mr: "auto",
                          width: "100%",
                          height: 250,
                          py: 2,
                          px: 1,
                        }}
                        onClick={() => handleLink(tool.Launch, isDev(tool))}
                        raVariant="vertical"
                      >
                        <TeaserCardMedia
                          image={tool.Icon}
                          style={{ backgroundSize: "contain" }}
                        />
                        <TeaserCardContent>
                          <TeaserCardTitle
                            variant="h6"
                            fontWeight="bold"
                            textAlign="center"
                            sx={{ fontSize: "1.2rem" }}
                          >
                            {tool.Description}
                          </TeaserCardTitle>
                        </TeaserCardContent>
                      </TeaserCard>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid container direction="column" md={4.5} sx={{ mb: 2 }}>
              {featureFlag()}
              <Box display="flex" flexDirection="row" sx={{ mb: 4 }}>
                <Typography
                  variant="h4"
                  component="h4"
                  fontWeight="bold"
                  sx={{ lineHeight: 1 }}
                >
                  {configData.ActionGroups[2].Group}
                </Typography>
              </Box>
              <Grid
                container
                direction="column"
                flexGrow="1"
                spacing={2}
                minHeight="20rem"
              >
                {configData.ActionGroups[2].Tools.map((actionGroup) => {
                  return (
                    <Grid
                      item
                      direction="row"
                      lg={6}
                      sx={{ height: { xs: "100%", md: "50%" } }}
                    >
                      <TeaserCard
                        sx={{
                          ml: "auto",
                          mr: "auto",
                          width: "100%",
                          height: "100%",
                          pt: 2,
                          px: 1,
                        }}
                        onClick={(e) => {
                          if (actionGroup.LinkConfig) {
                            handleSystemDesignerNavigation(
                              e,
                              thisConfig[actionGroup.LinkConfig]
                            );
                          } else {
                            handleSystemDesignerNavigation(e, actionGroup.Link);
                          }
                        }}
                        raVariant="vertical"
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          sx={{ height: "50%" }}
                        >
                          <Box
                            sx={{
                              height: "100%",
                              width: "50%",
                              backgroundImage: `url(${
                                actionGroup.Icon.split(";")[0]
                              })`,
                              backgroundPosition: "center",
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              imageRendering: "-webkit-optimize-contrast",
                            }}
                          />
                          <Box
                            sx={{
                              height: "100%",
                              width: "50%",
                              backgroundImage: `url(${
                                actionGroup.Icon.split(";")[1]
                              })`,
                              backgroundPosition: "center",
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              imageRendering: "-webkit-optimize-contrast",
                            }}
                          />
                        </Box>
                        <TeaserCardContent
                          sx={{
                            maxHeight: "110px !important",
                            minHeight: "110px !important",
                          }}
                        >
                          <TeaserCardTitle variant="h6" fontWeight="bold">
                            {actionGroup.Tool}
                          </TeaserCardTitle>
                          <TeaserCardSubtitle
                            sx={{ textTransform: "none", fontSize: "1rem" }}
                          >
                            {actionGroup.DisplayText}
                          </TeaserCardSubtitle>
                        </TeaserCardContent>
                      </TeaserCard>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/*<TeaserPromotional*/}
        {/*  subtitleText={configData.ActionGroups[3]?.Description || ""}*/}
        {/*  titleText={configData.ActionGroups[3]?.DisplayText || ""}*/}
        {/*  components={<PromotionalTeaserButtonGroup />}*/}
        {/*/>*/}
      </Container>
    </>
  );
};

export default Home;
