import MuiDrawer from "@mui/material/Drawer";
import { useEffect, useState } from "react";
import ConfiguratorLayout from "../configuratorLayout/ConfiguratorLayout";
import { IConfiguratorFromTemplateResponse } from "../interfaces/IConfiguratorFromTemplateResponse";

type Anchor = "left" | "right";

interface ConfiguratorDrawerProps {
  drawerPosition: Anchor;
  setOpenDrawer: boolean;
  isValidConfiguration: boolean;
  isLoading: boolean;
  handleConfiguratorDrawerClose: Function;
  updateConfigurator: Function;
  resetConfiguration: Function;
  configFromTemplateDataFromParent: IConfiguratorFromTemplateResponse;
}

const Drawer = (props: ConfiguratorDrawerProps) => {
  const {
    drawerPosition,
    setOpenDrawer,
    handleConfiguratorDrawerClose,
    isValidConfiguration,
    configFromTemplateDataFromParent,
    updateConfigurator,
    resetConfiguration,
    isLoading,
  } = props;

  return (
    <>
      <MuiDrawer
        anchor={drawerPosition}
        open={setOpenDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "4px",
            padding: "24px 24px 32px 24px",
          },
        }}
      >
        <ConfiguratorLayout
          isLoading={isLoading}
          handleConfiguratorDrawerClose={handleConfiguratorDrawerClose}
          isValidConfiguration={isValidConfiguration}
          configFromTemplateDataFromParent={configFromTemplateDataFromParent}
          updateConfigurator={updateConfigurator}
          resetConfiguration={resetConfiguration}
        ></ConfiguratorLayout>
      </MuiDrawer>
    </>
  );
};

export default Drawer;
