import axios, { AxiosRequestConfig } from "axios";
import config from "../../config";
import { getApiUrl } from "./ApiUtil";
import { MulesoftApiResponse } from "../../interfaces/MulesoftApiResponse";

class MulesoftApiService {
  api: string;
  config: AxiosRequestConfig;

  constructor() {
    this.api = "MULESOFT_API";
    this.config = {
      headers: {
        client_id: config["MULESOFT_API_CLIENT_ID"],
        client_secret: config["MULESOFT_API_CLIENT_SECRET"],
        correlation_id: config["MULESOFT_API_CORRELATION_ID"],
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };
  }

  getByEmailId(emailId: string) {
    const url = `${getApiUrl(
      this.api
    )}/api/v1/product/advisor?emailId=${encodeURIComponent(emailId)}`;
    return axios.get<MulesoftApiResponse>(url, this.config);
  }
}

const mulesoftApiService = new MulesoftApiService();
export default mulesoftApiService;
