import { IConfigSpec, initializeConfig } from "platform-services";

let config: IConfigSpec = {
  ...initializeConfig(process.env.REACT_APP_STAGE),
  PART_COMMUNITY_API:
    process.env.REACT_APP_STAGE === "release"
      ? window.env.PART_COMMUNITY_API
      : "https://webapi.partcommunity.com/service/reversemap?catalog=rockwellautomation&part=",
  DISPLAY_CADENAS_URL:
    process.env.REACT_APP_STAGE === "release"
      ? window.env.DISPLAY_CADENAS_URL
      : "https://rockwellautomation-embedded.partcommunity.com?catalog=rockwellautomation&hidePortlets=searchHeader&hidePortlets=navigation&part=",
};

export default config;
