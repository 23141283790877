import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SyntheticEvent } from "react";

interface PageSelectorProps {
  totalCount: number;
  totalPages: number;
  pageNumber: number;
  numberOfResultsPerPage: number;
  rowsPerPageOptions: number[];
  handleChangeProductsPerPage: (event: SelectChangeEvent) => void;
  handleChangePageClick: (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => void;
  handleChangePageSelect: (event: SelectChangeEvent) => void;
}

const PageSelector: React.FC<PageSelectorProps> = ({
  totalCount,
  totalPages,
  pageNumber,
  numberOfResultsPerPage,
  rowsPerPageOptions,
  handleChangePageClick,
  handleChangePageSelect,
  handleChangeProductsPerPage,
}) => {
  return (
    <Box
      sx={{
        my: 2,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
        }}
      >
        <InputLabel
          id="products-per-page-select-label"
          sx={{
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: "bold",
          }}
        >
          Items Per Page:
        </InputLabel>
        <FormControl size="small">
          <Select
            labelId="products-per-page-label"
            id="products-per-page-select"
            value={numberOfResultsPerPage.toString()}
            onChange={handleChangeProductsPerPage}
            sx={{ cursor: "default" }}
            MenuProps={{
              disablePortal: true,
              disableRestoreFocus: true,
              disableScrollLock: true,
              marginThreshold: null,
            }}
            onOpen={(event: SyntheticEvent) => {
              const { id } = event.target as HTMLElement;
              const el = document.getElementById(id);
              el?.parentElement?.classList.add("Mui-focused");
            }}
            onClose={(event) => {
              const el = event.target as HTMLElement;
              el.closest(".Mui-focused")?.classList.remove("Mui-focused");
            }}
          >
            {rowsPerPageOptions.map((i) => {
              return (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
        }}
      >
        {(pageNumber - 1) * numberOfResultsPerPage + 1}-
        {(pageNumber - 1) * numberOfResultsPerPage + numberOfResultsPerPage >
        totalCount
          ? totalCount
          : (pageNumber - 1) * numberOfResultsPerPage + numberOfResultsPerPage}
        {` `}of{` `}
        {totalCount.toLocaleString("US")}
        <IconButton
          disabled={!(pageNumber > 1)}
          onClick={(event) => handleChangePageClick(event, pageNumber - 1)}
        >
          <ChevronLeftIcon />
        </IconButton>
        <FormControl size="small">
          <Select
            id="page-select"
            value={pageNumber.toString()}
            onChange={handleChangePageSelect}
            sx={{ cursor: "default" }}
            MenuProps={{
              disablePortal: true,
              disableRestoreFocus: true,
              disableScrollLock: true,
              marginThreshold: null,
              sx: {
                maxHeight: 292,
              },
            }}
            onOpen={(event: SyntheticEvent) => {
              const { id } = event.target as HTMLElement;
              const el = document.getElementById(id);
              el?.parentElement?.classList.add("Mui-focused");
            }}
            onClose={(event) => {
              const el = event.target as HTMLElement;
              el.closest(".Mui-focused")?.classList.remove("Mui-focused");
            }}
          >
            {[...Array(totalPages)].map((value: any, index: number) => {
              const currentPage = index + 1;
              return (
                <MenuItem
                  key={index}
                  value={currentPage}
                  selected={pageNumber === currentPage}
                >
                  {currentPage}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        of {totalPages.toLocaleString("US")}
        <IconButton
          disabled={!(pageNumber < totalPages)}
          onClick={(event) => handleChangePageClick(event, pageNumber + 1)}
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default PageSelector;
