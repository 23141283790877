import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import GroupAccordion from "../accordions/GroupAccordion";
import { IGroup } from "../interfaces/IGroup";
import AttributeSelector from "../attributeSelector/AttributeSelector";
import { IConfiguratorFromTemplateResponse } from "../interfaces/IConfiguratorFromTemplateResponse";
import { IAttribute } from "../interfaces/IAttribute";
import { INavigationMapping } from "../interfaces/INavigationMapping";
import productApiService from "platform-services/dist/services/apis/ProductApiService";
import Response from "../../../../services/apis/Response";
import ProductApiService from "../../../../services/apis/ProductApiService";
import ROUTES from "../../../../constants/Routes";
import { useNavigate } from "react-router-dom";

interface ConfigurationSummaryProps {
  configFromTemplateDataFromParent: IConfiguratorFromTemplateResponse;
  updateConfigurator: Function;
  handleConfiguratorDrawerClose: Function;
  isValidConfiguration: boolean;
}

const ConfiguratorSummary = (props: ConfigurationSummaryProps) => {
  const navigate = useNavigate();
  const {
    configFromTemplateDataFromParent,
    updateConfigurator,
    handleConfiguratorDrawerClose,
    isValidConfiguration,
  } = props;
  const [configData, setConfigData] =
    useState<IConfiguratorFromTemplateResponse>();
  const [selectedAttribute, setSelectedAttribute] = useState<IAttribute>(); //current attribute selected in accordion
  const [groupAttributeIndex, setGroupAttributeIndex] = useState<number>(0); // current attribute selected index in its group array
  const [currentGroupIndex, setCurrentGroupIndex] = useState<number>(0); // current group index in group array
  const [showingGroupArray, setShowingGroupArray] = useState<
    INavigationMapping[]
  >([]); // array of groups that are in accordion
  const [isBackButtonDisabled, setIsBackButtonDisabled] =
    useState<boolean>(false);
  const [isContinueButtonDisabled, setIsContinueButtonDisabled] =
    useState<boolean>(false);
  const [modifiedPresentationGroups, setModifiedPresentationGroups] =
    useState<IGroup[]>();

  const handleConfigurationAccept = async () => {
    if (configData) {
      let route = `${ROUTES.PRODUCT}?catalogNumber=${configData?.pid}&type=Unknown`;
      navigate(route);
    }
    // try{
    //   if(configData){
    //     const result = await ProductApiService.GetDetailedProduct(configData?.pid,"Unknown");
    //     const response: any = new Response(result);
    //     if(response.isSuccessful()){
    //       console.log('detailed product repsone', response);
    //       let route = `${ROUTES.PRODUCT}?catalogNumber=${configData?.pid}&type=Unknown`;
    //       navigate(route);
    //     }
    //   }
    // } catch (e) {

    // }
    handleConfiguratorDrawerClose();
  };

  const handleConfigurationContinue = () => {
    if (isContinueButtonDisabled) {
      setIsContinueButtonDisabled(false);
    }
    if (
      groupAttributeIndex ===
      showingGroupArray[currentGroupIndex].groupAttributes.length - 1
    ) {
      const nextGroup = showingGroupArray.find(
        (group) => group.index === currentGroupIndex + 1
      );
      if (nextGroup) {
        setCurrentGroupIndex(nextGroup.index);
        setGroupAttributeIndex(0);
      } else {
        setIsContinueButtonDisabled(true);
      }
    } else {
      setGroupAttributeIndex(groupAttributeIndex + 1);
    }
  };

  const handleConfigurationBack = () => {
    if (isContinueButtonDisabled) {
      setIsContinueButtonDisabled(false);
    }
    if (groupAttributeIndex !== 0) {
      setGroupAttributeIndex(groupAttributeIndex - 1);
    } else {
      const prevGroup = showingGroupArray.find(
        (group) => group.index === currentGroupIndex - 1
      );
      if (prevGroup) {
        setCurrentGroupIndex(prevGroup.index);
        setGroupAttributeIndex(prevGroup.groupAttributes.length - 1);
      }
    }
  };

  const getGroupAttributeData = (attributes: string[]) => {
    let hasAtLeastOneSelectable = false;
    attributes.forEach((attribute) => {
      if (configFromTemplateDataFromParent?.attributes) {
        for (
          let i = 0;
          i < configFromTemplateDataFromParent.attributes.length;
          i++
        ) {
          if (
            attribute === configFromTemplateDataFromParent.attributes[i].id &&
            configFromTemplateDataFromParent.attributes[i].hasSelectables
          ) {
            hasAtLeastOneSelectable = true;
            return hasAtLeastOneSelectable;
          }
        }
      }
    });
    return hasAtLeastOneSelectable;
  };

  const handleSetSelectedAttribute = (
    attribute: IAttribute,
    groupAttributeIndex: number,
    groupIndex: number
  ) => {
    // instead of currentgroupindex , use the values coming in from the function
    // then at the end of the if else , set current group index, current group with the passed in values

    if (currentGroupIndex === 0 && groupAttributeIndex === 0) {
      setIsBackButtonDisabled(true);
    } else {
      setIsBackButtonDisabled(false);
    }
    setSelectedAttribute(attribute);
  };

  const groupArray = (index: number, groupAttributes: IAttribute[]) => {
    const isUnique = !showingGroupArray.some((group) => group.index === index);
    if (isUnique && groupAttributes.length > 0) {
      const keyValueArray = {
        index: index,
        groupAttributes: groupAttributes,
      };
      setShowingGroupArray((prevShowingGroupArray) => [
        ...prevShowingGroupArray,
        keyValueArray,
      ]);
    }
  };

  useEffect(() => {
    setConfigData(configFromTemplateDataFromParent);
  });

  useEffect(() => {
    let modGroups: IGroup[] = [];
    configData?.presentation?.groups.map((group: IGroup, index: any) => {
      if (
        group.attributes.length > 0 &&
        getGroupAttributeData(group.attributes) &&
        configData &&
        group.displayText.toLowerCase().includes("accessor") === false
      ) {
        modGroups.push(group);
      }
      if (modGroups) {
        setModifiedPresentationGroups(modGroups);
      }
    });
  }, [configData]);

  useEffect(() => {
    setCurrentGroupIndex(0);
    setGroupAttributeIndex(0);
  }, [configData?.cid]);

  useEffect(() => {
    if (showingGroupArray[0]?.index) {
      setCurrentGroupIndex(showingGroupArray[0].index);
    }
  }, [showingGroupArray]);

  return (
    <>
      <Box
        sx={{
          //border: "solid 1px blue",
          width: "100%",
          height: "75%",
          display: "flex",
        }}
      >
        <Box
          sx={{
            //border: "solid 1px blue",
            width: "40%",
            height: "100%",
            //padding: "5px",
            marginRight: "5px",
            overflow: "auto",
          }}
        >
          {modifiedPresentationGroups?.map((group: IGroup, index: any) => {
            if (configData) {
              return (
                <GroupAccordion
                  key={index}
                  group={group}
                  groupAttributeIndex={groupAttributeIndex}
                  currentGroupIndex={currentGroupIndex}
                  index={index}
                  groupArray={groupArray}
                  configFromTemplateDataFromParent={configData}
                  handleSetSelectedAttribute={handleSetSelectedAttribute}
                ></GroupAccordion>
              );
            }
          })}
        </Box>
        {selectedAttribute && (
          <Box
            sx={{
              //border: "solid 1px blue",
              width: "60%",
              height: "100%",
              //padding: "5px",
              //position: "relative",
            }}
          >
            <AttributeSelector
              selectedAttribute={selectedAttribute}
              configFromTemplateDataFromParent={
                configFromTemplateDataFromParent
              }
              updateConfigurator={updateConfigurator}
              setIsContinueButtonDisabled={setIsContinueButtonDisabled}
            ></AttributeSelector>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          height: "5vw",
          alignItems: "end",
        }}
      >
        <Box>
          <Button
            sx={{ mr: 2 }}
            variant="outlined"
            onClick={() => handleConfiguratorDrawerClose()}
          >
            Cancel
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="outlined"
            disabled={isBackButtonDisabled}
            onClick={() => handleConfigurationBack()}
          >
            Back
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="outlined"
            disabled={isContinueButtonDisabled}
            onClick={() => handleConfigurationContinue()}
          >
            Continue
          </Button>
          <Button
            disabled={!isValidConfiguration}
            variant="contained"
            onClick={() => handleConfigurationAccept()}
          >
            Accept
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ConfiguratorSummary;
