import { Avatar, Box, ButtonBase, CardMedia, Typography } from "@mui/material";
import { TeaserCard } from "@rockwell-automation-inc/ra-meatball";
import { IAttribute } from "../interfaces/IAttribute";
import defaultImage from "../../assets/Image_Unavailable.svg";
import PATHS from "../../constants/Paths";
import { useEffect, useState } from "react";

interface AttributeCardProps {
  attribute: IAttribute;
  handleCardSelect: Function;
}

const AttributeCard = (props: AttributeCardProps) => {
  const { attribute, handleCardSelect } = props;
  const [atr, setAtr] = useState<IAttribute>();

  const handleSelectClick = () => {
    handleCardSelect(atr);
  };

  return (
    <>
      {atr?.isShowing && (
        <ButtonBase
          onClick={() => handleSelectClick()}
          sx={{ height: "30%", width: "50%", padding: "5px" }}
        >
          <TeaserCard
            raVariant="horizontal"
            hover={false}
            sx={{
              fontFamily: "inherit",
              borderRadius: "4px",
              justifyContent: "center",
              width: "100%",
              gap: "8px",
              border: atr?.isSelected ? "solid 2px #003E7E" : "",
              padding: "10px",
              boxShadow: "0px 1px 5px 0px #00000040",
              ":hover": {
                boxShadow: "2px 2px 10px 2px #00000040",
              },
            }}
          >
            <Box
              sx={{
                width: "25%",
                height: "100%",
                //border: "1px solid blue"
              }}
            >
              {attribute.thumbnail ? (
                <CardMedia
                  sx={{
                    width: "100%",
                    height: "100%",
                    backgroundSize: "contain",
                    objectFit: "contain",
                  }}
                  component="img"
                  image={`${PATHS.IMG_BASE}${attribute.thumbnail}`}
                  alt="component_img"
                />
              ) : (
                <Avatar
                  variant="square"
                  src={defaultImage}
                  sx={{
                    height: "100%",
                    width: "100%",
                    bgcolor: "transparent",
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                width: "75%",
                height: "100%",
                //border: "1px solid blue"
              }}
            >
              {attribute.displayText}
            </Box>
          </TeaserCard>
        </ButtonBase>
      )}
    </>
  );
};

export default AttributeCard;
