import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CatalogProduct from "../../interfaces/CatalogProduct";
import { RootState } from "../store";

interface Catalog {
  value?: Array<CatalogProduct>;
}

const initialState: Catalog = {
  value: [],
};

export const catalog = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    setCatalog: (state, action: PayloadAction<Array<CatalogProduct>>) => {
      state.value = action.payload;
    },
  },
});

export const { setCatalog } = catalog.actions;

export const selectCatalog = (state: RootState) => state.catalog.value;

export default catalog.reducer;
