import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MulesoftApiResponse } from "../../interfaces/MulesoftApiResponse";
import MulesoftApiService from "../../services/apis/MulesoftApiService";
import {
  getSessionMulesoftApiResponse,
  setSessionMulesoftApiResponse,
} from "../../services/util/SessionStorage";
import { RootState } from "../store";

const initialState: Partial<MulesoftApiResponse> =
  getSessionMulesoftApiResponse() || { isDistributor: false };

export const fetchMulesoftApiResponseAsync = createAsyncThunk<
  MulesoftApiResponse,
  { emailId: string },
  { state: RootState }
>(
  "mulesoftApiResponse/fetchMulesoftApiResponseStatus",
  async (params: { emailId: string }, { getState }) => {
    let response: MulesoftApiResponse = {
      isDistributor: false,
    } as MulesoftApiResponse;
    if (params.emailId) {
      try {
        const result =
          getSessionMulesoftApiResponse() ||
          (await MulesoftApiService.getByEmailId(params.emailId))?.data;
        if (result) {
          response = {
            ...result,
            isDistributor: result?.programType === "Authorized Distributor",
          };
        }
      } catch {}
    }
    setSessionMulesoftApiResponse(response);
    return response;
  }
);

export const mulesoftSlice = createSlice({
  name: "mulesoftApiResponse",
  initialState,
  reducers: {
    setMulesoftApiResponse: (
      state,
      action: PayloadAction<MulesoftApiResponse>
    ) => {
      state = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchMulesoftApiResponseAsync.fulfilled,
      (state, action) => {
        state = action.payload;
        return state;
      }
    );
  },
});

export const { setMulesoftApiResponse } = mulesoftSlice.actions;

export const selectMulesoftApiResponse = (state: RootState) =>
  state.mulesoftApiResponse;

export default mulesoftSlice.reducer;
