import { AxiosRequestConfig, AxiosResponse } from "axios";
import { getApiUrl } from "./ApiUtil";
import axios from "./Axios";
import { Project } from "../../interfaces/Project";

class PstV1ApiService {
  api: string;
  config: AxiosRequestConfig;

  constructor() {
    this.api = "PST_V1_API";

    this.config = {
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
      },
    };
  }

  getLoadConfiguratorFromTemplate(
    templateId: string | null,
    libraryId: string | undefined,
    locale: string | undefined
  ): Promise<any> {
    let queryParams = `?req=load&tid=${templateId}`;
    if (libraryId) {
      queryParams += `&cat=${libraryId}`;
    }
    queryParams += `&loc=${locale}`;
    const url = `${getApiUrl(this.api)}/api/Config/${queryParams}`;
    console.log("make request load config", url);
    return axios.get(url, this.config);
  }

  // NOT BEING USED YET
  getConfigurationTemplate(cid: string) {
    const url = `${getApiUrl(this.api)}/api/Config/${cid}`;
    console.log("make request config template", url);
    return axios.get(url, this.config);
  }
  //

  getToggleConfiguration(cid: string, eid: string) {
    let queryParams = `req=toggle&target=${eid}`;
    const url = `${getApiUrl(this.api)}/api/Config/${cid}?${queryParams}`;
    console.log("make request toggleconfig url", url);
    return axios.get(url, this.config);
  }

  // BELOW CALLS ARE NOT FOR THIS API. ONLY FOR REFFERANCE //

  getUserProjects() {
    const url = `${getApiUrl(this.api)}/api/Project/GetUserProjects`;
    return axios.get(url, this.config);
  }

  postUser() {
    const url = `${getApiUrl(this.api)}/api/User`;
    return axios.post(url, null, this.config);
  }

  getProjectById(id: number): Promise<AxiosResponse<Project>> {
    const url = `${getApiUrl(
      this.api
    )}/api/Project/GetUserProjectById?projectId=${id}`;
    return axios.get<Project>(url, this.config);
  }
}

const pstV1ApiService = new PstV1ApiService();
export default pstV1ApiService;
