import { Box, Skeleton } from "@mui/material";

interface SearchSidebarLoaderProps {
  size?: number;
}

const SearchSidebarLoader = ({ size = 3 }: SearchSidebarLoaderProps) => {
  return (
    <>
      {[...Array(size)].map((value: any, index: number) => (
        <Box key={index}>
          <Skeleton
            variant="rounded"
            height={"24px"}
            sx={{ mt: "12px", mb: "2rem" }}
          />
          {[...Array(size)].map((value, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                gap: "1rem",
                mt: "1rem",
                mb: "2rem",
              }}
            >
              <Skeleton variant="rounded" width={"21px"} height={"21px"} />
              <Skeleton variant="rounded" width={"100%"} />
            </Box>
          ))}
        </Box>
      ))}
    </>
  );
};

export default SearchSidebarLoader;
