import { Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import "./ProductCard.scss";
import ProductApiService from "../../services/apis/ProductApiService";
import Carousel from "../carousel/Carousel";
import ProductCard from "./ProductCard";
import SearchProduct from "../../interfaces/SearchProduct";
import CompareBar from "../compareBar/CompareBar";
import { LoadingIndicator } from "@rockwell-automation-inc/ra-meatball";

interface AltProductCardProps {
  catalogNumber: string;
  endPointToUse: number;
  handleSelectedProduct?: () => void;
}
const AlternativeProducts = (props: AltProductCardProps) => {
  const { catalogNumber, endPointToUse } = props;
  const [loading, setLoading] = useState(true);
  const LOCALE = "US";
  const [products, setProducts] = useState<Array<SearchProduct>>();

  useEffect(() => {
    if (catalogNumber === "") {
      return;
    }
    if (endPointToUse === 0) {
      const api = ProductApiService.GetShorterLeadTimeProducts(
        catalogNumber,
        LOCALE
      );
      api.then((response) => {
        setLoading(false);

        if (response.data.products.length > 10) {
          setProducts(response.data.products.slice(0, 10));
        } else {
          setProducts(response.data.products);
        }
      });
    } else {
      const api = ProductApiService.GetAlternativeProducts(
        catalogNumber,
        LOCALE
      );
      api.then((response) => {
        setLoading(false);
        if (response.data.products.length > 10) {
          setProducts(response.data.products.slice(0, 10));
        } else {
          setProducts(response.data.products);
        }
      });
    }
  }, [catalogNumber, endPointToUse]);

  const noProductsMessage = () => {
    if (products === undefined || products.length < 1) {
      return (
        <Typography sx={{ mt: 2 }}>
          There are no alternative products in the system. For a more detailed
          analysis, please contact customer support.
        </Typography>
      );
    }
  };

  return (
    <>
      {loading ? (
        <>
          <LoadingIndicator></LoadingIndicator>
        </>
      ) : (
        <>
          <Grid>
            <Grid item xs={12}>
              {products === undefined}
              {products === undefined ||
                (products.length > 0 && (
                  <Carousel
                    CardComponent={ProductCard}
                    products={products}
                  ></Carousel>
                ))}
            </Grid>
          </Grid>
          {noProductsMessage()}
          <CompareBar includeParentProduct={true}></CompareBar>
        </>
      )}
    </>
  );
};

export default AlternativeProducts;
