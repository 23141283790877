import CatalogProduct from "../interfaces/CatalogProduct";

const findNestedProduct = (
  productList: Array<CatalogProduct> | undefined,
  catalogGroupId: string,
  setBreadcrumb?: Function
): CatalogProduct | undefined => {
  if (productList)
    for (let i = 0; i < productList.length; i++) {
      const product = productList[i];
      if (product.catalogGroupId === catalogGroupId) {
        return product;
      }

      const nestedProduct: CatalogProduct | undefined = findNestedProduct(
        product.childGroups,
        catalogGroupId
      );
      if (nestedProduct) {
        if (setBreadcrumb)
          setBreadcrumb((breadcrumb: Array<CatalogProduct>) => [
            ...breadcrumb,
            product,
          ]);

        return nestedProduct;
      }
    }
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export { findNestedProduct, scrollToTop };
