import { styled } from "@mui/material/styles";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell as MuiTableCell,
} from "@mui/material";
import { DetailedProduct } from "../../interfaces/DetailedProduct";
import { softwareSelectionCheck } from "../../util/ProductCatalog";

interface ProductMetadataProps {
  product: DetailedProduct;
}

const TableCell = styled(MuiTableCell)`
  padding: 0 0 0.5rem;
  border: 0;
`;

const TableCellHeading = styled(TableCell)`
  font-weight: bold;
  max-width: 160px;
`;

const ProductMetadata = (props: ProductMetadataProps) => {
  const { product } = props;

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {product.prodLifeCycleStatus && (
            <TableRow>
              <TableCellHeading>Product Lifecycle:</TableCellHeading>
              <TableCell>{product?.prodLifeCycleStatus}</TableCell>
            </TableRow>
          )}

          {product?.stockStatusDisplay ? (
            <TableRow>
              <TableCellHeading>
                {product?.stockStatusDisplay.split(":").length <= 1
                  ? product?.stockStatusDisplay
                  : product?.stockStatusDisplay.split(":")[0] + ":"}
              </TableCellHeading>
              <TableCell>
                {product?.stockStatusDisplay.split(":").length >= 1 &&
                  product?.stockStatusDisplay.split(":")[1]}
              </TableCell>
            </TableRow>
          ) : (
            <>
              {product?.estimatedLeadTime && (
                <TableRow>
                  <TableCellHeading>Estimated Lead Time:</TableCellHeading>
                  <TableCell>{product?.estimatedLeadTime} days</TableCell>
                </TableRow>
              )}
            </>
          )}

          <TableRow>
            <TableCellHeading>List Price:</TableCellHeading>
            <TableCell>
              {softwareSelectionCheck(product.templateId, product.listPrice)
                ? "N/A"
                : product.displayPrice}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCellHeading>Available Quantity:</TableCellHeading>
            <TableCell>
              {product?.availableQty == null
                ? "Unknown"
                : product?.availableQty}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductMetadata;
