import {
  Box,
  Typography,
  Unstable_Grid2 as Grid,
  Button,
  Container,
  SvgIcon,
  SvgIconProps,
} from "@mui/material";
import {
  DownloadIcon,
  FileDocIcon,
  GenerateDocumentIcon,
  InformationCircleIcon,
} from "@rockwell-automation-inc/ra-meatball";
import DocumentItem from "../common/DocumentItem/DocumentItem";
import { DocumentType } from "../../interfaces/DocumentType";

interface ProductDocumentsProps {
  documents: Array<any> | undefined;
}

interface DocumentDrawingProps {
  document: DocumentType;
}

const ProductDocuments = (props: ProductDocumentsProps) => {
  const { documents } = props;

  if (!documents || documents.length === 0) {
    return (
      <Box display="flex" justifyContent="center" sx={{ p: 4 }}>
        <Typography variant="h6">No documents available</Typography>
      </Box>
    );
  }

  const documentList = documents.filter((i) => i.type === "General");

  interface DocumentItemProps {
    document: DocumentType;
  }

  return (
    <Container maxWidth="sm">
      {documentList.length > 0 && (
        <Grid display="flex" flexDirection="column" sx={{ py: 2 }}>
          <Typography variant="h6" fontWeight="bold">
            Documents
          </Typography>

          {documentList.map((document: DocumentType, index) => {
            return <DocumentItem key={index} document={document} />;
          })}
        </Grid>
      )}
    </Container>
  );
};

export default ProductDocuments;
