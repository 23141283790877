import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { SearchIcon } from "@rockwell-automation-inc/ra-meatball";
import { useEffect, useState } from "react";

interface SearchProps {
  handleAttributeSearchSubmit: Function;
  resetIsShowing: Function;
}

const Search = (props: SearchProps) => {
  const { handleAttributeSearchSubmit, resetIsShowing } = props;
  const [query, setQuery] = useState<string>("");
  const [searchButtonDisabled, setSearchButtonDisabled] =
    useState<boolean>(true);
  const [searchFieldDisabled, setSearchFieldDisabled] =
    useState<boolean>(false);
  const errorMessage: string = "Search must be less then 20 characters";
  const maxQueryLength: number = 20;

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= maxQueryLength) {
      setSearchFieldDisabled(false);
      setSearchButtonDisabled(false);
      setQuery(event.target.value);
    } else {
      setSearchFieldDisabled(true);
      setSearchButtonDisabled(true);
    }
    if (event.target.value.length === 0 || event.target.value === undefined) {
      resetIsShowing();
      setSearchButtonDisabled(true);
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAttributeSearchSubmit(query);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      display="flex"
      flexDirection="row"
      position="relative"
      sx={{ width: "100%", height: "80px", paddingTop: "10px" }}
    >
      <TextField
        sx={{ width: "100%" }}
        error={searchFieldDisabled}
        label="Search"
        helperText={searchFieldDisabled ? errorMessage : ""}
        value={query}
        onChange={handleQueryChange}
        onKeyDown={(event) => handleKeyPress(event)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={searchButtonDisabled}
                aria-label="Search"
                disableRipple
                sx={{
                  padding: "7px 22px 7px 22px",
                  borderRadius: "4px",
                  color: "#FFFFFF",
                  backgroundColor: "#2A79C4",
                  width: "62px",
                  height: "32px",
                }}
                onClick={() => handleAttributeSearchSubmit(query)}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default Search;
