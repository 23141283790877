export const softwareSelectionCheck = (
  templateId: string,
  listPrice?: number
) => {
  return templateId && templateId?.startsWith("RSOFT") && listPrice === 1;
};

export const softwareSelectionCheckWithDisplay = (
  templateId?: string,
  displayPrice?: string
) => {
  return (
    templateId &&
    templateId?.startsWith("RSOFT") &&
    Number(displayPrice?.replace(/[^0-9\.-]+/g, "")) === 1
  );
};
