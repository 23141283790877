import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  Modal,
  Grid,
  CardContent,
  TableRow,
  SelectChangeEvent,
  Container,
  Button,
  Card,
  Menu,
  Drawer,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "./ProductSelectorModal.scss";
import SearchApiService from "../../../services/apis/SearchApiService";
import SearchCriteriaProductsResponse from "../../../interfaces/SearchCriteriaProductsResponse";
import SearchCriteriaRequest from "../../../interfaces/SearchCriteriaRequest";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  setNumberOfResultsPerPage,
  selectNumberOfResultsPerPage,
} from "../../../redux/slices/numberOfResultsPerPageSlice";
import { SelectableAccessory } from "../../../interfaces/Accessory";
import QuantityAdjusterButton from "../QuantityAdjusterButton/QuantityAdjusterButton";
import PageSelector from "../PageSelector/PageSelector";
import SearchProductAttribute from "../../../interfaces/SearchProductAttribute";
import SearchProductAttributeGroup from "../../../interfaces/SearchProductAttributeGroup";
import SearchSidebar from "../../searchSidebar/SearchSidebar";
import FilterIcon from "@mui/icons-material/FilterAlt";
import SearchCriteriaAttributes from "../../../interfaces/SearchCriteriaAttributes";
import SearchCriteriaTemplate from "../../../interfaces/SearchCriteriaTemplate";
import SearchCriteriaAttributesResponse from "../../../interfaces/SearchCriteriaAttributesResponse";
import CatalogProduct from "../../../interfaces/CatalogProduct";
import { findNestedProduct } from "../../../helpers/Utils";
import { selectCatalog } from "../../../redux/slices/catalogSlice";
import { PricingAndAvailability } from "../../../interfaces/PricingAndAvailability";
import {
  LoadingIndicator,
  LoadingSpinner,
  TeaserCard,
  TeaserCardContent,
  TeaserCardSubtitle,
  TeaserCardTitle,
} from "@rockwell-automation-inc/ra-meatball";
import { act } from "@testing-library/react";

const drawerWidth = 350;

interface ProductSelectorModalProps {
  isOpen: boolean;
  searchCriteria: string;
  tids: string;
  productAccessories: SelectableAccessory[];
  accessory: SelectableAccessory;
  handleSubmit: (selectableAccessories: SelectableAccessory[]) => void;
  handleClose: () => void;
  _window?: () => Window;
}

interface PageAccessory {
  totalCount: number;
  totalPages: number;
  accessories: SelectableAccessory[];
}

const ProductSelectorModal: React.FC<ProductSelectorModalProps> = ({
  isOpen,
  searchCriteria,
  tids,
  productAccessories,
  accessory,
  handleSubmit,
  handleClose,
  _window,
}) => {
  const [activeFilters, setActiveFilters] = useState("");
  const [_error, setError] = useState(false);
  const dispatch = useAppDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchCriteriaAccessories, setSearchCriteriaAccessories] =
    useState<PageAccessory>({
      totalCount: 0,
      totalPages: 1,
      accessories: [],
    });
  const [productsloading, setProductsLoading] = useState(true);
  const [criteriaLoading, setCriteriaLoading] = useState(true);
  const navigate = useNavigate();
  const numberOfResultsPerPage = useAppSelector(selectNumberOfResultsPerPage);
  const LOCALE = "US";
  const container =
    _window !== undefined ? () => _window().document.body : undefined;
  const [isSearchFiltersOpen, setIsSearchFiltersOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [groupId, setGroupId] = useState("");
  const location = useLocation();

  const [searchCriteriaAttributes, setSearchCriteriaAttributes] =
    useState<SearchCriteriaAttributes>({
      productAttributes: [],
      userCountryName: "string",
    });
  const [searchCriteriaTids, setSearchCriteriaTids] = useState<
    Array<SearchCriteriaTemplate>
  >([]);
  const [pricingAndAvailability, setPricingAndAvailability] =
    useState<PricingAndAvailability>({
      minPrice: null,
      maxPrice: null,
      defaultMinPrice: null,
      defaultMaxPrice: null,
      minListPriceDisplay: null,
      maxListPriceDisplay: null,
      readyToShip: false,
      isReadyToShip: false,
      maxAvailability: null,
    });
  const catalog = useAppSelector(selectCatalog);
  const [breadcrumb, setBreadcrumb] = useState<Array<CatalogProduct>>([]);

  const handleDrawerToggle = () => {
    setIsSearchFiltersOpen(!isSearchFiltersOpen);
  };

  const parseProductSearch = (productSearch: Promise<any>) => {
    productSearch
      .then((response: SearchCriteriaProductsResponse) => {
        const arr: SelectableAccessory[] = response.data.products?.map(
          (product, index) => {
            return {
              catalogNumber: product.catalogNumber,
              description: product.description,
              templateName: product.templateId,
              id: product.id,
              quantity:
                productAccessories.find(
                  (i) => i.catalogNumber === product.catalogNumber
                )?.quantity || 0,
            };
          }
        );
        const selectableAccessoriesRef: PageAccessory = {
          totalCount: response.data.totalCount,
          totalPages: response.data.totalPages,
          accessories: arr,
        };
        setSearchCriteriaAccessories(selectableAccessoriesRef);
        setProductsLoading(false);
      })
      .catch((_error: Promise<any>) => {
        setProductsLoading(false);
        setError(true);
      });
  };

  const parsePricingAndAvailability = (
    searchCriteriaProductsResponse: Promise<any>
  ) => {
    searchCriteriaProductsResponse.then(
      (response: SearchCriteriaProductsResponse) => {
        setPricingAndAvailability({
          ...pricingAndAvailability,
          ...response.data,
        });
      }
    );
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
    setProductsLoading(true);
    setCriteriaLoading(true);
    setGroupId(new URLSearchParams(location.search).get("g") || "");

    const request: SearchCriteriaRequest = {
      //searchCriteria: searchCriteria,
      searchCriteria: tids,
      tids: [],
      //tids: tids !== '' ? tids.split(',') : [],
      numberOfResultsPerPage: numberOfResultsPerPage,
      locale: LOCALE,
      pageNumber: pageNumber,
      catalogGroupId: groupId,
      attributes: activeFilters.trim() ? JSON.parse(activeFilters) : [],
      minPrice: pricingAndAvailability.minPrice,
      maxPrice: pricingAndAvailability.maxPrice,
      maxAvailability: pricingAndAvailability.maxAvailability,
      readyToShip: pricingAndAvailability.readyToShip,
    };
    //attribute search here for filters//

    const productSearch =
      SearchApiService.PostSearchCriteriaFilteredProducts(request);
    parseProductSearch(productSearch);

    if (productSearch) {
      parsePricingAndAvailability(productSearch);
    }

    const attributeSearch =
      SearchApiService.PostSearchCriteriaAttributes(request);
    parseAttributeSearch(attributeSearch);
  }, [
    isOpen,
    numberOfResultsPerPage,
    pageNumber,
    activeFilters,
    pricingAndAvailability.minPrice,
    pricingAndAvailability.maxPrice,
    pricingAndAvailability.readyToShip,
    pricingAndAvailability.maxAvailability,
  ]);

  // const QuantityChangeHandler = (e: string, product: SelectableAccessory) => {
  //   handleQtyChange(Number(e), product);
  // };

  const getValue = (accessory: SelectableAccessory) => {
    return (
      searchCriteriaAccessories.accessories.find((i) => i.id === accessory.id)
        ?.quantity || 0
    );
  };

  const handlePriceAndAvailabilityChange = (
    pricingAndAvailability: PricingAndAvailability
  ) => {
    setPricingAndAvailability(pricingAndAvailability);
  };

  const handleChangeProductsPerPage = (event: SelectChangeEvent) => {
    dispatch(setNumberOfResultsPerPage(parseInt(event.target.value)));
    setPageNumber(1); // reset to the first page
  };

  const handleChangePageClick = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    handleChangePage(newPage);
  };

  const handleChangePage = (newPageNumber: number) => {
    if (
      newPageNumber > 1 ||
      newPageNumber < searchCriteriaAccessories.totalPages
    ) {
      setPageNumber(newPageNumber);
    }
  };

  const handleChangePageSelect = (event: SelectChangeEvent) => {
    handleChangePage(parseInt(event.target.value));
  };

  const handleFilterChange = (
    name: string,
    attribute: SearchProductAttribute,
    filterAdd: boolean
  ) => {
    const filters: Array<SearchProductAttributeGroup> = activeFilters.trim()
      ? JSON.parse(activeFilters)
      : [];
    const filterIndex = filters.findIndex((o) => o.name === name);
    if (filterAdd) {
      if (filterIndex !== -1) {
        // TODO check to see if attribute already exists
        filters[filterIndex].values.push(attribute);
      } else {
        filters.push({
          name: name,
          values: [attribute],
        });
      }
    } else {
      filters[filterIndex].values.forEach((currentAttribute, index, array) => {
        if (currentAttribute.name === attribute.name) {
          array.splice(index, 1);
        }
      });
    }

    // remove empty filters to prevent sending superfluous data
    filters.forEach((group, index, array) => {
      if (group.values.length === 0) {
        array.splice(index, 1);
      }
    });

    setActiveFilters(JSON.stringify(filters));
  };

  const handleTemplateChange = (templateId: string, templateAdd: boolean) => {
    let tidsArray = tids !== "" ? tids.split(",") : [];

    if (templateAdd) {
      tidsArray = [...tidsArray, templateId];
    } else {
      tidsArray = tidsArray.filter((tid) => tid !== templateId);
    }

    //setTids(tidsArray.join(','));
  };

  const parseAttributeSearch = (attributeSearch: Promise<any>) => {
    attributeSearch
      .then((response: SearchCriteriaAttributesResponse) => {
        setSearchCriteriaAttributes(response.data);
        setCriteriaLoading(false);
      })
      .catch((error: Promise<any>) => {
        // ???
      });
  };
  const findTemplateParent: any = (
    productList: Array<CatalogProduct>,
    templateId: string
  ) => {
    productList.forEach((product) => {
      product.templates.forEach((template) => {
        if (template.templateId === templateId) {
          findNestedProduct(catalog, product.catalogGroupId, setBreadcrumb);
          setBreadcrumb((breadcrumb) => [...breadcrumb, product]);

          return product;
        }
      });

      const nestedProduct = findTemplateParent(product.childGroups, templateId);
      if (nestedProduct) {
        return nestedProduct;
      }
    });
  };

  const handleQtyAdd = (Accessory: SelectableAccessory) => {
    const arr = searchCriteriaAccessories.accessories.map((accesoryRef) => {
      if (accesoryRef.id === Accessory.id) {
        return { ...accesoryRef, quantity: accesoryRef.quantity + 1 };
      } else {
        return accesoryRef;
      }
    });
    setSearchCriteriaAccessories({
      ...searchCriteriaAccessories,
      accessories: arr,
    });
    const tabClick = new CustomEvent("quantityAdded", {
      detail: {
        action: "Quantity Increased",
        properties: {
          category: "WebApp",
          label: "Quantity Increased in Product Selector Modal",
          product: Accessory.catalogNumber,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleQtySubtract = (Accessory: SelectableAccessory) => {
    if (Accessory.quantity >= 1) {
      const arr = searchCriteriaAccessories.accessories.map((accesoryRef) => {
        if (accesoryRef.id === Accessory.id) {
          return { ...accesoryRef, quantity: accesoryRef.quantity - 1 };
        } else {
          return accesoryRef;
        }
      });
      setSearchCriteriaAccessories({
        ...searchCriteriaAccessories,
        accessories: arr,
      });
    }
  };

  const handleQtyChange = (Accessory: SelectableAccessory, qty: number) => {
    if (qty >= 0) {
      const arr = searchCriteriaAccessories.accessories.map((accesoryRef) => {
        if (accesoryRef.id === Accessory.id) {
          return { ...accesoryRef, quantity: qty };
        } else {
          return accesoryRef;
        }
      });
      setSearchCriteriaAccessories({
        ...searchCriteriaAccessories,
        accessories: arr,
      });
    }
  };

  const handleSubmitClick = (selectableAccessory: SelectableAccessory[]) => {
    const arr = selectableAccessory.map((item) => {
      return {
        ...item,
        parentAccessory: accessory,
      };
    });
    handleSubmit(arr);
  };

  return (
    <>
      <Modal open={isOpen} onClose={handleClose} style={{ zIndex: 100000 }}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 950,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: 2,
              marginBottom: 2,
              borderBottom: "solid",
              borderWidth: 2,
              borderColor: "#e5e6e9",
            }}
          >
            <Typography variant="h6" component="h2">
              Products
            </Typography>
            <CloseIcon
              sx={{ color: "#2a76c4" }}
              onClick={handleClose}
            ></CloseIcon>
          </Box>
          <Grid
            height={500}
            overflow={"auto"}
            flexDirection={"row"}
            sx={{ p: 2 }}
          >
            <Grid flexDirection={"column"}>
              {!isSearchFiltersOpen && (
                <Button
                  startIcon={<FilterIcon />}
                  onClick={handleDrawerToggle}
                  sx={{ mb: 1 }}
                >
                  Search Filters
                </Button>
              )}

              {isSearchFiltersOpen && (
                <Button onClick={handleDrawerToggle} sx={{ mb: 1, ml: 1 }}>
                  Product Selection
                </Button>
              )}

              {/* TODO: fix duplicate markup */}
              {criteriaLoading && isSearchFiltersOpen && <LoadingIndicator />}
              {!criteriaLoading && isSearchFiltersOpen && (
                <Box>
                  <SearchSidebar
                    searchCriteriaGroupTids={[]}
                    searchCriteriaAttributes={searchCriteriaAttributes}
                    handleTemplateChange={handleTemplateChange}
                    handleFilterChange={handleFilterChange}
                    tids={tids}
                    searchCriteria={searchCriteria}
                    handlePriceAndAvailabilityChange={
                      handlePriceAndAvailabilityChange
                    }
                    pricingAndAvailability={pricingAndAvailability}
                    catalogTreeLoading={false}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                  />
                </Box>
              )}
              {productsloading && !isSearchFiltersOpen && <LoadingIndicator />}

              {!isSearchFiltersOpen &&
                !productsloading &&
                searchCriteriaAccessories.accessories.map(
                  (product: SelectableAccessory, index) => {
                    const isSubtractDisabled = () => {
                      const quantity = productAccessories.find(
                        (i) => i.id === product.id
                      )?.quantity;
                      if (quantity && quantity <= 1) {
                        return true;
                      }
                      if (!quantity && product.quantity <= 1) {
                        return true;
                      } else {
                        return false;
                      }
                    };

                    return (
                      <Grid key={index}>
                        <TeaserCard
                          hover={false}
                          raVariant="horizontal-outlined"
                          sx={{ width: "100%", height: "125px", mb: 2 }}
                          style={{ justifyContent: "space-between" }}
                        >
                          <TeaserCardContent style={{ maxWidth: "400px" }}>
                            <TeaserCardTitle>
                              {product.catalogNumber}
                            </TeaserCardTitle>
                            <TeaserCardSubtitle
                              style={{
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}
                            >
                              {product.description}
                            </TeaserCardSubtitle>
                          </TeaserCardContent>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignContent="flex-end"
                            justifyContent="center"
                            sx={{ px: 2 }}
                          >
                            <QuantityAdjusterButton
                              value={getValue(product)}
                              handleQtyAdd={() => handleQtyAdd(product)}
                              handleQtyChange={(e) =>
                                handleQtyChange(product, Number(e))
                              }
                              handleQtySubtract={() =>
                                handleQtySubtract(product)
                              }
                              subtractDisabled={isSubtractDisabled()}
                            ></QuantityAdjusterButton>
                          </Box>
                        </TeaserCard>
                      </Grid>
                    );
                  }
                )}
            </Grid>
          </Grid>

          {!isSearchFiltersOpen && (
            <>
              <Box>
                <PageSelector
                  handleChangePageClick={(
                    event: React.ChangeEvent<unknown>,
                    newPage: number
                  ) => handleChangePageClick(event, newPage)}
                  handleChangePageSelect={(event: SelectChangeEvent) =>
                    handleChangePageSelect(event)
                  }
                  handleChangeProductsPerPage={(event: SelectChangeEvent) =>
                    handleChangeProductsPerPage(event)
                  }
                  totalCount={searchCriteriaAccessories.totalCount}
                  totalPages={searchCriteriaAccessories.totalPages}
                  pageNumber={pageNumber}
                  numberOfResultsPerPage={numberOfResultsPerPage}
                  rowsPerPageOptions={[25, 50, 100]}
                ></PageSelector>
              </Box>
              <Box sx={{ width: "100%", justifyContent: "flex-end" }}>
                <Button
                  sx={{ ml: "auto" }}
                  onClick={() =>
                    handleSubmitClick(
                      searchCriteriaAccessories.accessories.filter(
                        (i) => i.quantity > 0
                      )
                    )
                  }
                >
                  Submit
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ProductSelectorModal;
