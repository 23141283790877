import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Modal from "@mui/material/Modal";
import {
  Box,
  Typography,
  Table,
  TableContainer,
  TableHead as MuiTableHead,
  TableBody,
  TableRow,
  TableCell,
  CardMedia,
  Switch,
} from "@mui/material";
import { DetailedProduct } from "../../interfaces/DetailedProduct";
import SearchProduct from "../../interfaces/SearchProduct";
import MUICloseIcon from "@mui/icons-material/Close";
import ProductBomButtonCompare from "../productBomButton/ProductBomButtonCompare";
import Response from "../../services/apis/Response";
import PATHS from "../../constants/Paths";
import ProductApiService from "../../services/apis/ProductApiService";
import ProductSearchAttributeComparisonsResponse from "../../interfaces/ProductSearchAttributeComparisonsResponse";
import ProductSearchAttributeComparisons from "../../interfaces/ProductSearchAttributeComparisons";
import { SelectableAccessory } from "../../interfaces/Accessory";
import { useSelector } from "react-redux";
import { LoadingIndicator } from "@rockwell-automation-inc/ra-meatball";
import LoginAlert from "../LoginAlert/LoginAlert";
import toast from "../../util/toast";
import { useAppDispatch } from "../../redux/hooks";
import { selectSelProject } from "../../redux/slices/selectedProjectSlice";
import defaultImage from "../../assets/Image_Unavailable.svg";
import { softwareSelectionCheckWithDisplay } from "../../util/ProductCatalog";
import { PostBOMItem } from "../../interfaces/PostBOMItem";
import { BOM } from "../../interfaces/BOM";
import { selectSelBOM, setSelBOM } from "../../redux/slices/selectedBOMSlice";
import { PostBOM } from "../../interfaces/PostBOM";
import { getConfigBOMItemThumbPrint } from "../../util/ConfigBOMItemThumbprint";
import {
  BOMApiService,
  BOMItemApiService,
  selectUser,
  useAuthContext,
  usePSAppSelector,
} from "platform-services";

interface ProductResponse {
  data: DetailedProduct;
}

interface CompareModalProps {
  open: boolean;
  onClose: () => void;
  compareList: SearchProduct[];
  includeParentProduct: boolean;
  parentProductCatalogNumber: string;
}

interface ProductSearchDetailsResponse {
  data: ProductSearchAttributeComparisonsResponse;
}

function CompareModal({
  open,
  onClose,
  compareList,
  includeParentProduct,
  parentProductCatalogNumber,
}: CompareModalProps) {
  const authContext = useAuthContext();
  const dispatch = useAppDispatch();
  const selProject = useSelector(selectSelProject);
  const selBOM = useSelector(selectSelBOM);
  const [modalTitle, setModalTitle] = useState<string>();
  const [productSearchCompare, setProductSearchCompare] =
    useState<ProductSearchAttributeComparisons[]>();
  const [showDifference, setShowDifference] = useState<boolean>(true);
  const [productsLoading, setProductsLoading] = useState<boolean>(true);
  const [catalogNumber, SetCatalogNumber] = useState<string>("");
  const [sourceType, setSourceType] = useState("");
  const [product, setProduct] = useState<DetailedProduct>();
  const user = usePSAppSelector(selectUser);
  const location = useLocation();
  const selectedAccessories: SelectableAccessory[] = [];
  const LOCALE = "US";
  const [qty, setQty] = useState(1);
  const [savingToast, setSavingToast] = useState<any>();

  const handleQuickAdd = async (catalogNumber: string) => {
    SetCatalogNumber(catalogNumber);
    const tabClick = new CustomEvent("trackAddToBom", {
      detail: {
        action: "Product Add to Bom from Compare Modal",
        properties: {
          category: "WebApp",
          label: "Product Add to Bom from Compare Modal",
          product: catalogNumber,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleAvailabilityChange = () => {
    setShowDifference(!showDifference);
    const tabClick = new CustomEvent("showDifferencesSwitchClick", {
      detail: {
        action: "Show Differences Switch Click",
        properties: {
          category: "WebApp",
          label: "Show Differences Switch",
          products: compareList,
          showDifferenceSwitch: !showDifference,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const addProductToSelectedBOM = async (
    product: DetailedProduct,
    savingToast: any,
    newBOM?: BOM | undefined
  ) => {
    const bomItem: PostBOMItem = {
      name: product.catalogNumber,
      productType: "Product",
      description: product.description,
      listPrice: product.listPrice,
      photo: product.photo,
      sourceType: product.sourceType,
      quantity: qty,
      configThumbprint: getConfigBOMItemThumbPrint(product),
      children: [],
    };

    try {
      if (newBOM) {
        const result = await BOMItemApiService.postBOMItem(newBOM.id, bomItem);

        const response = new Response(result);

        if (response.isSuccessful()) {
          setSavingToast(
            toast.loadedSuccess(
              savingToast,
              `${product.catalogNumber} added to: ${selProject?.name}`
            )
          );
        }
      }
      if (newBOM === undefined && selBOM) {
        const result = await BOMItemApiService.postBOMItem(selBOM.id, bomItem);

        const response = new Response(result);

        if (response.isSuccessful()) {
          setSavingToast(
            toast.loadedSuccess(
              savingToast,
              `${product.catalogNumber} added to: ${selProject?.name}`
            )
          );
        }
      }
    } catch (e) {
      toast.dismiss(savingToast);
      toast.error(
        `Unable to add ${product.catalogNumber} to "${selProject?.name}"`
      );
      console.log(e);
    }
    const addToBom = new CustomEvent("trackAddToBom", {
      detail: {
        action: "Add To Bom from Compare",
        properties: {
          category: "WebApp",
          label: bomItem,
          productQty: qty,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(addToBom);
  };

  const createNewBOM = async (savingToast: any) => {
    let bom: BOM;
    if (selProject?.guid !== undefined) {
      const postBom: PostBOM = {
        name: "DEFAULT BOM",
        projectId: selProject?.guid,
      };
      try {
        const result = await BOMApiService.postBOM(postBom);
        const response = new Response(result);
        if (response.isSuccessful()) {
          dispatch(setSelBOM(response.getData()));
          bom = response.getData();
          return bom;
        }
      } catch (e) {
        toast.dismiss(savingToast);
        toast.error(
          `Unable to add ${product?.catalogNumber} to bom "${postBom.name}"`
        );
        console.log(e);
      }
    }
  };

  const handleAddToBom = async () => {
    if (!product) return;
    if (selBOM === null) {
      createNewBOM(savingToast).then((newBOM: BOM | undefined) => {
        addProductToSelectedBOM(product, savingToast, newBOM);
      });
    } else {
      addProductToSelectedBOM(product, savingToast);
    }
  };

  useEffect(() => {
    if (product) {
      handleAddToBom();
    }
  }, [product]);

  useEffect(() => {
    setSourceType(new URLSearchParams(location.search).get("type") || "");
  }, [location.search]);

  useEffect(
    () => {
      if (catalogNumber === "") {
        return;
      }
      setSavingToast(toast.loading("Saving..."));
      const product = ProductApiService.GetDetailedProduct(
        catalogNumber,
        sourceType
      );
      product
        .then((response: ProductResponse) => {
          setProduct(response.data);
        })
        .catch((error) => {
          // TODO
          console.log(error);
        });
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [catalogNumber]
  );

  useEffect(() => {
    if (compareList.length > 0) {
      const compareNames = compareList.map((i) => i.catalogNumber);
      if (includeParentProduct && parentProductCatalogNumber.length > 0) {
        compareNames.unshift(parentProductCatalogNumber);
      }
      const searchapi =
        ProductApiService.PostSearchProductAttributeComparisons(compareNames);

      searchapi
        .then((response: ProductSearchDetailsResponse) => {
          setProductSearchCompare(response.data.productComparisons);
          setModalTitle(
            response.data.modalTitle.length > 0
              ? response.data.modalTitle
              : "Rockwell Automation Products"
          );
          setProductsLoading(false);
        })
        .catch((error) => {
          setProductsLoading(false);
          // Handle error
        });
    }
  }, [compareList]);

  const onLoginButtonClicked = () => {
    authContext.signinRedirect();
  };

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: 700,
            minWidth: 500,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              paddingBottom: "20px",
            }}
          >
            <MUICloseIcon
              sx={{ color: "#2a76c4" }}
              onClick={onClose}
            ></MUICloseIcon>
          </Box>
          {productsLoading && <LoadingIndicator></LoadingIndicator>}
          {!productsLoading && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: 2,
                  marginBottom: 2,
                }}
              >
                <Typography variant="h6" component="h2">
                  {modalTitle}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ color: "#858383", paddingRight: "5px" }}>
                    Show Differences Only
                  </Typography>
                  <Switch
                    size="medium"
                    checked={showDifference}
                    onChange={handleAvailabilityChange}
                  ></Switch>
                </Box>
              </Box>

              <TableContainer sx={{ height: 500 }}>
                <Table stickyHeader>
                  <MuiTableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #e5e6e9",
                          padding: "0px",
                          borderBottom: "none",
                          width: "120px",
                        }}
                      ></TableCell>

                      {productSearchCompare
                        ?.filter((product) => product.attributeName === "Photo")
                        .map((p: ProductSearchAttributeComparisons) =>
                          p.attributeValues.map((attribute, index) => (
                            <TableCell
                              key={attribute.attrSeqIdx + index + 0}
                              sx={{
                                ":not(:last-child)": {
                                  borderRight: "1px solid #e5e6e9",
                                },
                                borderBottom: "none",
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  height: "120px",
                                }}
                              >
                                <Box
                                  sx={{
                                    position: "relative",
                                    height: "120px",
                                  }}
                                >
                                  <CardMedia
                                    sx={{
                                      width: "100%",
                                      height: "100%",
                                      backgroundSize: "contain",
                                      objectFit: "contain",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    component="img"
                                    image={
                                      attribute.value
                                        ? `${PATHS.IMG_BASE}${attribute.value}`
                                        : defaultImage
                                    }
                                    alt="component_img"
                                  />
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop: "20px",
                                  color: "#2A79C4",
                                }}
                              >
                                <ProductBomButtonCompare
                                  handleQuickAdd={() => {
                                    handleQuickAdd(attribute.catalogNumber);
                                  }}
                                  user={user}
                                  lastBillOfMaterials={selBOM}
                                />
                              </Box>
                            </TableCell>
                          ))
                        )}
                    </TableRow>
                  </MuiTableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #e5e6e9",
                          borderTop: "none",
                          padding: "0px",
                          paddingRight: "10px",
                          verticalAlign: "bottom",
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: "600", paddingTop: "5px" }}
                        >
                          Catalog Number
                        </Typography>
                      </TableCell>
                      {productSearchCompare
                        ?.filter(
                          (product) =>
                            product.attributeName === "Catalog Number"
                        )
                        .map((p: ProductSearchAttributeComparisons) =>
                          p.attributeValues.map((attribute, index) => (
                            <TableCell
                              size="small"
                              key={attribute.attrSeqIdx + index + 1}
                              sx={{
                                ":not(:last-child)": {
                                  borderRight: "1px solid #e5e6e9",
                                },
                                textAlign: "center",
                                borderTop: "none",
                                padding: "0px",
                                paddingRight: "5px",
                                paddingLeft: "5px",
                                verticalAlign: "bottom",
                              }}
                            >
                              <Typography>{attribute.value}</Typography>
                            </TableCell>
                          ))
                        )}
                    </TableRow>
                    {!user && (
                      <TableRow>
                        <TableCell
                          sx={{
                            borderRight: "1px solid #e5e6e9",
                            padding: "0px",
                            verticalAlign: "bottom",
                          }}
                        >
                          <TableRow sx={{ display: "flex" }}>
                            <TableCell
                              sx={{
                                padding: "0px",
                                verticalAlign: "bottom",
                                width: "100%",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "600", paddingTop: "5px" }}
                              >
                                List Price
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0px",
                                verticalAlign: "bottom",
                                width: "100%",
                                border: "none",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "600", paddingTop: "5px" }}
                              >
                                Lead Time
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableCell>
                        <TableCell
                          colSpan={
                            includeParentProduct
                              ? compareList.length + 1
                              : compareList.length
                          }
                          sx={{
                            padding: 0,
                            margin: 0,
                            backgroundColor: "#003E7E",
                          }}
                        >
                          <LoginAlert
                            user={user}
                            onLoginButtonClicked={onLoginButtonClicked}
                            modalVersion={true}
                          />
                        </TableCell>
                      </TableRow>
                    )}

                    {showDifference &&
                      productSearchCompare
                        ?.filter(
                          (product) =>
                            product.attributeName !== "Photo" &&
                            product.attributeName !== "Catalog Number"
                        )
                        .map((p: ProductSearchAttributeComparisons, index) => {
                          const areAttributeValuesDifferent =
                            p.attributeValues.some(
                              (attr, index, array) =>
                                index > 0 &&
                                attr.value !== array[index - 1].value
                            );

                          return (
                            areAttributeValuesDifferent && (
                              <TableRow key={p.attrSeqIdxOrder + index + 2}>
                                <TableCell
                                  sx={{
                                    borderRight: "1px solid #e5e6e9",
                                    padding: "0px",
                                    paddingRight: "10px",
                                    verticalAlign: "bottom",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: "600",
                                      paddingTop: "5px",
                                    }}
                                  >
                                    {p.attributeName}
                                  </Typography>
                                </TableCell>
                                {p.attributeValues.map((attr, index) => (
                                  <TableCell
                                    key={attr.attrSeqIdx + index + 2}
                                    sx={{
                                      ":not(:last-child)": {
                                        borderRight: "1px solid #e5e6e9",
                                      },
                                      textAlign: "center",
                                      padding: "0px",
                                      verticalAlign: "bottom",
                                    }}
                                  >
                                    {p.attributeName === "List Price" &&
                                    softwareSelectionCheckWithDisplay(
                                      attr.templateId,
                                      attr.value
                                    )
                                      ? "N/A"
                                      : attr.value}
                                  </TableCell>
                                ))}
                              </TableRow>
                            )
                          );
                        })}

                    {!showDifference &&
                      productSearchCompare
                        ?.filter(
                          (product) =>
                            product.attributeName !== "Photo" &&
                            product.attributeName !== "Catalog Number"
                        )
                        .map((p: ProductSearchAttributeComparisons) => (
                          <TableRow>
                            <TableCell
                              sx={{
                                borderRight: "1px solid #e5e6e9",
                                padding: "0px",
                                paddingRight: "10px",
                                verticalAlign: "bottom",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: "600", paddingTop: "5px" }}
                              >
                                {p.attributeName}
                              </Typography>
                            </TableCell>
                            {p.attributeValues.map((attr, index) => {
                              return (
                                <TableCell
                                  key={attr.attrSeqIdx + index + 2}
                                  sx={{
                                    ":not(:last-child)": {
                                      borderRight: "1px solid #e5e6e9",
                                    },
                                    textAlign: "center",
                                    padding: "0px",
                                    verticalAlign: "bottom",
                                  }}
                                >
                                  {p.attributeName === "List Price" &&
                                  softwareSelectionCheckWithDisplay(
                                    attr.templateId,
                                    attr.value
                                  )
                                    ? "N/A"
                                    : attr.value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default CompareModal;
