import axios from "axios";

const token = localStorage.getItem("access_token");
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true;
axios.defaults.headers.common["Access-Control-Allow-Methods"] =
  "GET,PUT,POST,DELETE,UPDATE,OPTIONS";

axios.defaults.headers.common["Authorization"] = "Bearer " + token;
// if additional default headers are added from this point on , please
// go to Cadenas.ts, then within the IsValidCadenasRequest() function, add that header
// to the delete section. Cadenas will not allow for condition headers in the request.
export default axios;
