import {
  Box,
  Button,
  Typography,
  ClickAwayListener,
  Icon,
  IconButton,
  SvgIconProps,
} from "@mui/material";
import React, { ReactNode, useState } from "react";
import {
  ChevronDownIcon,
  CloseIcon,
  StarHalfIcon,
  SupportIcon,
} from "@rockwell-automation-inc/ra-meatball";

import "../../styles/index.scss";
import { selectUser, usePSAppSelector } from "platform-services";

type ReactSVGComp = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

enum TabButtons {
  feedback = "Feedback",
  support = "Contact Us",
}

interface SupportRowsData {
  label: string;
  url: string;
}

const SupportLinks = {
  Tech: {
    label: "TechConnect Support",
    url: "https://www.rockwellautomation.com/en-us/support/knowledgebase/my-techconnect/techconnect-support-how-to.html",
  },
  Sales: {
    label: "Sales",
    url: "https://www.rockwellautomation.com/en-us/sales.html",
  },
  Customer: {
    label: "Customer Support",
    url: "https://www.rockwellautomation.com/en-us/support/customer-care.html",
  },
};
const feedbackForm =
  "https://forms.office.com/Pages/ResponsePage.aspx?id=PglbhUBzx0WfDJYVBBWJPucPIEDvKatAoQbXnShPcZFUQVdXSUJIM0pZR0pVNU44RUYxUDVXMVdDSC4u";

const AdvisorFeedback = () => {
  const user = usePSAppSelector(selectUser);
  const [ShowFormModel, setShowFormModel] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<TabButtons>(TabButtons.feedback);

  const openForm = () => {
    setShowFormModel(true);
  };
  const closeForm = () => {
    setShowFormModel(false);
  };

  interface TabButtonProps {
    id: TabButtons;
    icon: ReactNode;
    label: string;
    onClick: (id: TabButtons) => void;
  }
  const TabButtonStyle = { width: "20px", height: "20px", fill: "#CD163F" };
  const TabButton = (props: TabButtonProps) => {
    return (
      <Button
        startIcon={props.icon}
        sx={{
          display: "flex",
          minWidth: "130px",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          padding: "6px 10px",
          textTransform: "capitalize",
          borderBottom: activeTab === props.id ? "3px solid #F58025" : "",
        }}
        onClick={() => handleTabClick(props.id)}
      >
        <Typography
          sx={{
            lineHeight: "24px",
            fontSize: "18px",
            fontWeight: 500,
            color: "#2D2D2D",
          }}
        >
          {props.label}
        </Typography>
      </Button>
    );
  };
  const handleTabClick = (id: TabButtons) => {
    setActiveTab(id);
  };

  const FeedbackContent = () => {
    const handleFeedbackFormClick = () => {
      window.open(feedbackForm, "_blank");
    };
    return (
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Typography
          sx={{
            pb: 2,
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "22px",
          }}
        >
          Your feedback is important to us. If you’d like to leave a comment or
          suggest a new feature please fill out the form found bellow.
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={handleFeedbackFormClick}
          sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 500 }}
        >
          Feedback Form
        </Button>
      </Box>
    );
  };

  const SupportContent = () => {
    interface SupportRowsProps {
      data: SupportRowsData;
    }
    const SupportRows = (props: SupportRowsProps) => {
      const handleClick = () => {
        window.open(props.data.url, "_blank");
      };
      return (
        <Button
          color="primary"
          sx={{
            ":hover": {
              textDecoration: "underline",
              backgroundColor: "transparent",
            },
          }}
          endIcon={
            <ChevronDownIcon
              style={{
                width: "20px",
                height: "20px",
                fill: "#2A79C4",
                transform: "rotate(-90deg)",
              }}
            />
          }
          onClick={handleClick}
        >
          {props.data.label}
        </Button>
      );
    };

    return (
      <Box
        sx={{
          display: "flex",
          p: 2,
          flexDirection: "column",
          gap: "10px",
          alignItems: "flex-start",
        }}
      >
        <SupportRows data={SupportLinks.Tech} />
        <SupportRows data={SupportLinks.Sales} />
        <SupportRows data={SupportLinks.Customer} />
      </Box>
    );
  };
  const getTabContent = () => {
    return (
      <>
        {activeTab === TabButtons.feedback ? (
          <FeedbackContent />
        ) : (
          <SupportContent />
        )}
      </>
    );
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        sx={{
          writingMode: "vertical-lr",
          position: "fixed",
          right: 0,
          top: "40%",
          minWidth: "30px",
          padding: "12px 6px",
          borderRadius: "4px 0px 0px 4px",
          lineHeight: "18px",
          fontSize: "14px",
          textTransform: "capitalize",
          ":hover": {
            boxShadow: "0px 2px 10px 0px #00000040",
          },
        }}
        onClick={openForm}
      >
        Help & Feedback
      </Button>

      {ShowFormModel && (
        <ClickAwayListener onClickAway={closeForm}>
          <Box
            sx={{
              width: "20rem",
              position: "fixed",
              right: "10px",
              top: "30%",
              boxShadow: "0px 2px 10px 0px #00000040",
              borderRadius: "4px",
              bgcolor: "#FFFFFF",
              zIndex: "3",
            }}
          >
            <Box
              sx={{
                display: "flex",
                background:
                  "linear-gradient(90.1deg, #003E7E 7.44%, #00AEEF 93.59%)",
                padding: "10px 16px 10px 16px",
                gap: "32px",
                borderTop: "4px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Icon
                  fill="#FFF"
                  sx={{
                    w: "20px",
                    h: "20px",
                    fill: "#FFF",
                  }}
                  component={StarHalfIcon}
                />

                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "22px",
                    letterSpacing: "0em",
                    color: "#FFFFFF",
                  }}
                >
                  Hi {user?.profile?.given_name} ,
                  <br />
                  How can we help you today?
                </Typography>
              </Box>
              <IconButton sx={{ p: 0, mb: 2 }} onClick={closeForm}>
                <CloseIcon
                  style={{
                    width: "12px",
                    height: "12px",
                    fill: "#FFFFFF",
                    fontWeight: 700,
                  }}
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                padding: "7px 16px 0px 16px",
                justifyContent: "space-evenly",
                boxShadow: "0px 1px 5px 0px #00000040",
              }}
            >
              <TabButton
                id={TabButtons.feedback}
                icon={<StarHalfIcon style={TabButtonStyle} />}
                label={"Feedback"}
                onClick={handleTabClick}
              />
              <TabButton
                id={TabButtons.support}
                icon={<SupportIcon style={TabButtonStyle} />}
                label={"Contact Us"}
                onClick={handleTabClick}
              />
            </Box>

            {getTabContent()}
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
};

export default AdvisorFeedback;
