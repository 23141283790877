import {
  Breadcrumbs,
  Link as MuiLink,
  Typography,
  Skeleton,
} from "@mui/material";
import ROUTES from "../../constants/Routes";
import CatalogProduct from "../../interfaces/CatalogProduct";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import CatalogTemplate from "../../interfaces/CatalogTemplate";

interface SearchBreadcrumbsProps {
  loading: boolean;
  searchCriteriaGroupTids?: Array<CatalogProduct> | undefined;
  tids?: string;
  searchCriteria?: string | null;
  currentProduct?: CatalogProduct;
  catalogNumber?: string;
  groupId?: string | null;
  template?: CatalogTemplate;
  systemDesigner?: boolean;
}

const SearchBreadcrumbs = (props: SearchBreadcrumbsProps) => {
  const {
    loading,
    searchCriteriaGroupTids,
    tids,
    searchCriteria,
    currentProduct,
    catalogNumber,
    groupId,
    template,
    systemDesigner,
  } = props;

  interface Breadcrumb {
    title: string;
    route?: string;
  }

  const [breadcrumbs, setBreadcrumbs] = useState<Array<Breadcrumb>>([]);

  const buildBreadcrumbs = (catalogs: Array<CatalogProduct>) => {
    catalogs.forEach((catalog) => {
      setBreadcrumbs((breadcrumbs) => [
        ...breadcrumbs,
        {
          title: catalog.title,
          route: `${ROUTES.BROWSE}/${catalog.catalogGroupId}`,
        },
      ]);

      if (catalog.childGroups.length === 1) {
        buildBreadcrumbs(catalog.childGroups);
      }

      if (
        catalog.childGroups.length === 0 &&
        catalog.templates.length === 1 &&
        tids !== "" &&
        !searchCriteria
      ) {
        const tidsArr = tids?.split(",");

        if (
          tidsArr?.length === 1 &&
          tidsArr[0] === catalog.templates[0].templateId
        ) {
          setBreadcrumbs((breadcrumbs) => [
            ...breadcrumbs,
            {
              title: catalog.templates[0].title,
            },
          ]);
        }
      }
    });
  };

  useEffect(
    () => {
      setBreadcrumbs([
        {
          title: "Rockwell Automation",
          route:
            searchCriteriaGroupTids || systemDesigner ? "/browse" : undefined,
        },
      ]);

      if (searchCriteriaGroupTids) {
        buildBreadcrumbs(searchCriteriaGroupTids);
      }

      if (currentProduct && !catalogNumber) {
        setBreadcrumbs((breadcrumbs) => [
          ...breadcrumbs,
          {
            title: currentProduct.title,
          },
        ]);
      }

      if (searchCriteria && !groupId) {
        setBreadcrumbs((breadcrumbs) => [
          ...breadcrumbs,
          {
            title: `Search Results for "${searchCriteria}"`,
          },
        ]);
      }

      if (currentProduct && catalogNumber) {
        setBreadcrumbs((breadcrumbs) => [
          ...breadcrumbs,
          {
            title: currentProduct.title,
            route: `${ROUTES.BROWSE}/${currentProduct.catalogGroupId}`,
          },
        ]);
      }

      if (currentProduct && template && catalogNumber) {
        setBreadcrumbs((breadcrumbs) => [
          ...breadcrumbs,
          {
            title: template.title,
            route: `${ROUTES.SEARCH}?tids=${template.templateId}`,
          },
        ]);
      }

      if (searchCriteria && groupId) {
        setBreadcrumbs((breadcrumbs) => [
          ...breadcrumbs,
          {
            title: `Search Results for "${searchCriteria}"`,
            route: `${ROUTES.SEARCH}?s=${searchCriteria}&g=${groupId}`,
          },
        ]);
      }

      if (currentProduct && catalogNumber) {
        setBreadcrumbs((breadcrumbs) => [
          ...breadcrumbs,
          {
            title: catalogNumber,
          },
        ]);
      }

      if (systemDesigner) {
        setBreadcrumbs((breadcrumbs) => [
          ...breadcrumbs,
          {
            title: "System Designers",
          },
        ]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchCriteriaGroupTids]
  );

  const renderBreadcrumb = (breadcrumb: Breadcrumb, index: number) => {
    if (breadcrumb.route) {
      return (
        <MuiLink
          color="inherit"
          component={RouterLink}
          to={breadcrumb.route}
          key={index}
        >
          {breadcrumb.title}
        </MuiLink>
      );
    } else {
      return (
        <Typography color="text.primary" key={index}>
          {breadcrumb.title}
        </Typography>
      );
    }
  };

  return (
    <>
      {loading && (
        <Breadcrumbs>
          {[...Array(3)].map((value, index) => (
            <Skeleton variant="rounded" width={150} key={index} />
          ))}
        </Breadcrumbs>
      )}
      {!loading && (
        <Breadcrumbs
          aria-label="breadcrumb"
          maxItems={4}
          itemsAfterCollapse={2}
        >
          {breadcrumbs.map((breadcrumb, index) => {
            return renderBreadcrumb(breadcrumb, index);
          })}
        </Breadcrumbs>
      )}
    </>
  );
};

export default SearchBreadcrumbs;
