import { useEffect, useLayoutEffect, useState } from "react";
import { Box, Container, Alert } from "@mui/material";
import Masthead from "../../components/masthead/Masthead";
import SearchForm from "../../components/searchForm/SearchForm";
import SearchBreadcrumbs from "../../components/searchBreadcrumbs/SearchBreadcrumbs";
import CatalogProduct from "../../interfaces/CatalogProduct";
import SearchCategories from "../../components/searchCategories/SearchCategories";
import SearchSelect from "../../components/searchSelect/SearchSelect";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import searchApiService from "../../services/apis/SearchApiService";
import SearchCriteriaRequest from "../../interfaces/SearchCriteriaRequest";
import { selectNumberOfResultsPerPage } from "../../redux/slices/numberOfResultsPerPageSlice";
import { PricingAndAvailability } from "../../interfaces/PricingAndAvailability";
import SearchCriteriaProductsResponse from "../../interfaces/SearchCriteriaProductsResponse";
import ROUTES from "../../constants/Routes";
import SearchCriteriaProducts from "../../interfaces/SearchCriteriaProducts";
import SearchApiService from "../../services/apis/SearchApiService";
import SearchCriteriaAttributesResponse from "../../interfaces/SearchCriteriaAttributesResponse";
import SearchCriteriaAttributes from "../../interfaces/SearchCriteriaAttributes";
import ProductLibraryResponse from "../../interfaces/ProductLibraryResponse";
import { scrollToTop } from "../../helpers/Utils";
import SearchNoResult from "../../components/searchNoResult/SearchNoResult";
import SearchProductAttributeGroup from "../../interfaces/SearchProductAttributeGroup";
import SearchProductAttribute from "../../interfaces/SearchProductAttribute";

const Search = () => {
  const navigate = useNavigate();
  const [searchCriteria, setSearchCriteria] = useState("");
  const [tids, setTids] = useState("");
  const [groupId, setGroupId] = useState("All");
  const [searchCriteriaGroupTids, setSearchCriteriaGroupTids] = useState<
    CatalogProduct[]
  >([]);
  const [productsLoading, setProductsLoading] = useState(true);
  const [criteriaLoading, setCriteriaLoading] = useState(true);
  const [catalogTreeLoading, setCatalogTreeLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [activeFilters, setActiveFilters] = useState("");
  const [enableGuidedSelection, setEnableGuidedSelection] = useState(
    sessionStorage.getItem("enableGuidedSelection") !== undefined
      ? sessionStorage.getItem("enableGuidedSelection") === "true"
        ? true
        : true
      : true
  );
  const [sortOrderType, setSortOrderType] = useState("ClosestMatch");

  useEffect(() => {
    sessionStorage.setItem(
      "enableGuidedSelection",
      enableGuidedSelection.toString()
    );
  }, [enableGuidedSelection]);

  const [pricingAndAvailability, setPricingAndAvailability] =
    useState<PricingAndAvailability>({
      minPrice: null,
      maxPrice: null,
      defaultMinPrice: null,
      defaultMaxPrice: null,
      minListPriceDisplay: null,
      maxListPriceDisplay: null,
      readyToShip: false,
      isReadyToShip: false,
      maxAvailability: null,
    });
  const [searchCriteriaProducts, setSearchCriteriaProducts] =
    useState<SearchCriteriaProducts>({
      totalCount: 0,
      totalPages: 1,
      products: [],
    });
  const [searchCriteriaAttributes, setSearchCriteriaAttributes] =
    useState<SearchCriteriaAttributes>({
      productAttributes: [],
      userCountryName: "string",
    });

  const numberOfResultsPerPage = useAppSelector(selectNumberOfResultsPerPage);
  const [pageNumber, setPageNumber] = useState(1);

  const [showCategories, setShowCategories] = useState(true);

  const location = useLocation();

  const LOCALE = "US";

  useEffect(
    () => {
      // setBreadcrumb([]);
      setShowCategories(groupId === "All" && tids === "");

      setProductsLoading(true);
      setCriteriaLoading(true);
      setCatalogTreeLoading(true);

      setTids("");
      setError(false);
      setSearchCriteria(new URLSearchParams(location.search).get("s") || "");
      setTids(new URLSearchParams(location.search).get("tids") || "");
      setGroupId(new URLSearchParams(location.search).get("g") || "All");
      setPricingAndAvailability({
        minPrice: null,
        maxPrice: null,
        defaultMinPrice: null,
        defaultMaxPrice: null,
        readyToShip: false,
        minListPrice: null,
        maxListPrice: null,
        minListPriceDisplay: null,
        maxListPriceDisplay: null,
        minListPriceUserInputDisplay: null,
        maxListPriceUserInputDisplay: null,
        maxAvailability: null,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search]
  );

  useEffect(
    () => {
      if (
        (searchCriteria === "" && tids === "" && groupId === "All") ||
        isMounted
      ) {
        return;
      }

      // scrollToTop();
      setProductsLoading(true);
      setCriteriaLoading(true);
      setCatalogTreeLoading(true);
      getData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchCriteria, groupId, tids]
  );

  useLayoutEffect(
    () => {
      if ((searchCriteria === "" && tids === "") || isMounted) {
        return;
      }

      // scrollToTop();
      setProductsLoading(true);
      setCriteriaLoading(true);
      setCatalogTreeLoading(true);

      getFilteredData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      numberOfResultsPerPage,
      pageNumber,
      activeFilters,
      sortOrderType,
      //   tids,
      pricingAndAvailability.minPrice,
      pricingAndAvailability.maxPrice,
      pricingAndAvailability.readyToShip,
      pricingAndAvailability.maxAvailability,
      enableGuidedSelection,
    ]
  );

  useEffect(
    () => {
      if (!showCategories) {
        const attributeSearch = SearchApiService.PostSearchCriteriaAttributes(
          getRequest()
        );
        parseAttributeSearch(attributeSearch);
        parseGuidedSelection(attributeSearch);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showCategories]
  );

  useEffect(() => {
    setShowCategories(groupId === "All" && tids === "");
  }, [groupId, tids]);

  const getRequest = () => {
    const request: SearchCriteriaRequest = {
      searchCriteria: searchCriteria,
      tids: tids !== "" ? tids.split(",") : [],
      numberOfResultsPerPage: numberOfResultsPerPage,
      locale: LOCALE,
      attributes: activeFilters.trim() ? JSON.parse(activeFilters) : [],
      catalogGroupId: groupId,
      minPrice: pricingAndAvailability.minPrice,
      maxPrice: pricingAndAvailability.maxPrice,
      maxAvailability: pricingAndAvailability.maxAvailability,
      readyToShip: pricingAndAvailability.readyToShip,
      enableGuidedSelection: enableGuidedSelection,
      orderType: sortOrderType,
    };

    return request;
  };

  const getData = async () => {
    const request = getRequest();

    const response = parseProductSearch(
      searchApiService.PostSearchCriteriaProducts(request)
    );

    const categorySearch =
      SearchApiService.PostSearchCriteriaProductDynamicCatalogGroupTree(
        request
      );
    parseCategorySearch(categorySearch);

    if (response) {
      parsePricingAndAvailability(response);
    }

    if (!showCategories) {
      const attributeSearch =
        SearchApiService.PostSearchCriteriaAttributes(request);
      parseAttributeSearch(attributeSearch);
      parseGuidedSelection(attributeSearch);
    }
  };

  const getFilteredData = async () => {
    const request: SearchCriteriaRequest = {
      searchCriteria: searchCriteria,
      tids: tids !== "" ? tids.split(",") : [],
      numberOfResultsPerPage: numberOfResultsPerPage,
      locale: LOCALE,
      pageNumber: pageNumber,
      attributes: activeFilters.trim() !== "" ? JSON.parse(activeFilters) : [],
      catalogGroupId: groupId,
      minPrice: pricingAndAvailability.minPrice,
      maxPrice: pricingAndAvailability.maxPrice,
      maxAvailability: pricingAndAvailability.maxAvailability,
      readyToShip: pricingAndAvailability.readyToShip,
      enableGuidedSelection: enableGuidedSelection,
      orderType: sortOrderType,
    };

    const categorySearch =
      SearchApiService.PostSearchCriteriaProductDynamicCatalogGroupTree(
        request
      );
    parseCategorySearch(categorySearch);

    const search = SearchApiService.PostSearchCriteriaFilteredProducts(request);
    const response = parseProductSearch(search);

    if (response) {
      parsePricingAndAvailability(response);
    }
    const attributeSearch =
      SearchApiService.PostSearchCriteriaAttributes(request);
    parseAttributeSearch(attributeSearch);
    parseGuidedSelection(attributeSearch);
  };

  const parsePricingAndAvailability = (
    searchCriteriaProductsResponse: Promise<any>
  ) => {
    searchCriteriaProductsResponse
      .then((response: SearchCriteriaProductsResponse) => {
        setPricingAndAvailability({
          ...pricingAndAvailability,
          ...response.data,
        });
      })
      .catch((error: Promise<any>) => {
        setProductsLoading(false);
        setCriteriaLoading(false);
        setCatalogTreeLoading(false);
        setError(true);
      });
  };

  const parseProductSearch = async (
    productSearch: Promise<any>
  ): Promise<SearchCriteriaProductsResponse> => {
    productSearch
      .then((response: SearchCriteriaProductsResponse) => {
        if (!!response.data.hasExactMatch) {
          navigate(
            `${ROUTES.PRODUCT}?catalogNumber=${
              response.data.exactMatchCatalogNumber
            }&type=${
              response.data.exactMatchCatalogNumber !== searchCriteria
                ? "RaiseExactMatchCatalogNumber"
                : response.data.type
            }`,
            { replace: true }
          );
          return productSearch;
        } else {
          setSearchCriteriaProducts(response.data);
          setProductsLoading(false);
          return productSearch;
        }
      })
      .catch((error: Promise<any>) => {
        setProductsLoading(false);
        setCriteriaLoading(false);
        setCatalogTreeLoading(false);
        setError(true);
        return productSearch;
      });
    return productSearch;
  };

  const parseAttributeSearch = (attributeSearch: Promise<any>) => {
    attributeSearch
      .then((response: SearchCriteriaAttributesResponse) => {
        setSearchCriteriaAttributes(response.data);
        setCriteriaLoading(false);
      })
      .catch((error: Promise<any>) => {
        setProductsLoading(false);
        setCriteriaLoading(false);
        setCatalogTreeLoading(false);
        setError(true);
      });
  };

  const parseGuidedSelection = (attributeSearch: Promise<any>) => {
    setIsMounted(true);
    attributeSearch
      .then((response: SearchCriteriaAttributesResponse) => {
        const guidedSelectionActiveFilters: Array<SearchProductAttributeGroup> =
          [];
        response.data.productAttributes.forEach(
          (productAttribute: SearchProductAttributeGroup) => {
            productAttribute.values.forEach((value: SearchProductAttribute) => {
              if (value.isGuidedSelection && value.isSelected) {
                const existingFilter = guidedSelectionActiveFilters.find(
                  (i) => i.name === productAttribute.name
                );
                if (existingFilter) {
                  guidedSelectionActiveFilters[
                    guidedSelectionActiveFilters.indexOf(existingFilter)
                  ].values.push(value);
                } else {
                  guidedSelectionActiveFilters.push({
                    name: productAttribute.name,
                    values: [value],
                  });
                }
              }
            });
          }
        );

        let filters: Array<SearchProductAttributeGroup> =
          activeFilters.trim() !== "" ? JSON.parse(activeFilters) : [];

        const addGuidedSelectionAttributes = () => {
          guidedSelectionActiveFilters.forEach(
            (productAttribute: SearchProductAttributeGroup) => {
              const existingFilter = filters.find(
                (i) => i.name === productAttribute.name
              );

              if (existingFilter) {
                filters[filters.indexOf(existingFilter)].values = filters[
                  filters.indexOf(existingFilter)
                ].values.concat(productAttribute.values);
              } else {
                filters.push(productAttribute);
              }
            }
          );
        };

        const removeUnselectedGuidedSelectionAttributes = () => {
          filters = filters.map(
            (attributeGroup: SearchProductAttributeGroup) => {
              const attribute: SearchProductAttributeGroup = {
                ...attributeGroup,
                values: attributeGroup.values.filter((attribute) => {
                  const guidedSelectionAttributeGroup =
                    guidedSelectionActiveFilters.find(
                      (i) => i.name === attributeGroup.name
                    );
                  if (
                    !guidedSelectionAttributeGroup &&
                    attribute.isGuidedSelection
                  )
                    return false;
                  return true;
                }),
              };
              return attribute;
            }
          );

          filters = filters.filter((i) => i.values.length > 0);
        };

        if (filters.length > 0) {
          addGuidedSelectionAttributes();
          removeUnselectedGuidedSelectionAttributes();
          setActiveFilters(JSON.stringify(filters));
          setTimeout(() => {
            setIsMounted(false);
          }, 500);
        } else {
          setActiveFilters(JSON.stringify(guidedSelectionActiveFilters));
          setTimeout(() => {
            setIsMounted(false);
          }, 500);
        }
      })
      .catch((error: Promise<any>) => {});
  };

  const parseCategorySearch = (categorySearch: Promise<any>) => {
    categorySearch
      .then((response: ProductLibraryResponse) => {
        if (response.data && response.data.length === 1) {
          setShowCategories(false);
        }

        setSearchCriteriaGroupTids(response.data);
        setCatalogTreeLoading(false);
      })
      .catch((error: Promise<any>) => {
        setCatalogTreeLoading(false);
      });
  };

  return (
    <>
      <Masthead />

      {/* breadcrumbs */}
      {!showCategories && (
        <Container sx={{ my: 0, py: 3 }}>
          <SearchBreadcrumbs
            loading={catalogTreeLoading}
            searchCriteriaGroupTids={searchCriteriaGroupTids}
            tids={tids}
            searchCriteria={searchCriteria}
          />
        </Container>
      )}

      <Box sx={{ py: 1, width: "60%", mx: "auto" }}>
        <Container sx={{ my: 3 }}>
          <SearchForm />
        </Container>
      </Box>

      {/* Search content */}
      <Container
        sx={{
          my: 0,
          py: 3,
          bgcolor: "#fff",
          borderRadius: "4px",
        }}
      >
        {error ? (
          <Alert severity="error" sx={{ my: 1 }}>
            There was an error retrieving search results. Please try again later
            or contact Support if the error persists.
          </Alert>
        ) : (
          <>
            {!productsLoading &&
            !catalogTreeLoading &&
            !searchCriteriaProducts ? (
              <Container
                maxWidth="md"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SearchNoResult searchCriteria={searchCriteria} />
              </Container>
            ) : (
              <>
                {showCategories ? (
                  <SearchCategories
                    searchCriteria={searchCriteria}
                    searchCriteriaGroupTids={searchCriteriaGroupTids}
                    productsLoading={productsLoading}
                    catalogTreeLoading={catalogTreeLoading}
                  />
                ) : (
                  <SearchSelect
                    searchCriteriaProducts={searchCriteriaProducts}
                    searchCriteriaGroupTids={searchCriteriaGroupTids}
                    searchCriteriaAttributes={searchCriteriaAttributes}
                    setSearchCriteriaAttributes={setSearchCriteriaAttributes}
                    pricingAndAvailability={pricingAndAvailability}
                    setPricingAndAvailability={setPricingAndAvailability}
                    tids={tids}
                    setTids={setTids}
                    searchCriteria={searchCriteria}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    enableGuidedSelection={enableGuidedSelection}
                    setEnableGuidedSelection={setEnableGuidedSelection}
                    numberOfResultsPerPage={numberOfResultsPerPage}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    productsLoading={productsLoading}
                    criteriaLoading={criteriaLoading}
                    catalogTreeLoading={catalogTreeLoading}
                    groupId={groupId}
                    sortOrderType={sortOrderType}
                    setSortOrderType={setSortOrderType}
                  />
                )}
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default Search;
