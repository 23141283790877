import {
  Alert as MuiAlert,
  Typography,
  IconButton,
  Box,
  Button,
  ButtonProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { User } from "oidc-client";
import { useState } from "react";
import {
  ChevronRightIcon,
  CloseIcon,
  InformationCircleIcon,
} from "@rockwell-automation-inc/ra-meatball";

const StyledAlert = styled(MuiAlert)({
  paddingLeft: "40px",
  paddingRight: "40px",
  minHeight: "48px",
  ".MuiAlert-message": {
    padding: 0,
    width: "100%",
  },
});

const StyledAlertContent = styled(Box)({
  display: "flex",
  gap: "8px",
  alignItems: "center",
});

const StyledButton = styled((props: ButtonProps) => (
  <Button
    variant="link"
    color="white"
    endIcon={<ChevronRightIcon />}
    onClick={props.onClick}
    {...props}
  />
))({
  marginLeft: "16px",
  ".MuiButton-endIcon": {
    marginLeft: "2px",
  },
});

const IS_LOGIN_ALERT_OPEN = "isLoginAlertOpen";

interface Props {
  user: User | undefined;
  onLoginButtonClicked: () => void;
  modalVersion: boolean;
}

const LoginAlert = (props: Props) => {
  const { user, onLoginButtonClicked, modalVersion } = props;
  const isLoginAlertOpenRef =
    sessionStorage.getItem(IS_LOGIN_ALERT_OPEN) !== "false";
  const [loginAlertOpen, setLoginAlertOpen] = useState(isLoginAlertOpenRef);

  const handleLoginAlertClose = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setLoginAlertOpen(false);
    sessionStorage.setItem(IS_LOGIN_ALERT_OPEN, "false");
  };

  return (
    <>
      {!user && loginAlertOpen && (
        <StyledAlert>
          <StyledAlertContent sx={{ overflow: modalVersion ? "hidden" : "" }}>
            <InformationCircleIcon />
            <Typography>
              For access to full data and features, please log in or create an
              account.
            </Typography>
            <StyledButton onClick={onLoginButtonClicked}>Log In</StyledButton>
          </StyledAlertContent>
          {!modalVersion && (
            <IconButton
              onClick={handleLoginAlertClose}
              color="white"
              sx={{ ml: "auto" }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </StyledAlert>
      )}
    </>
  );
};

export default LoginAlert;
