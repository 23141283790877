import { Box } from "@mui/material";
import Drawer from "../../components/drawers/Drawer";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import pstV1ApiService from "../../../../services/apis/PstV1ApiService";
import Response from "../../../../services/apis/Response";
import { IConfiguratorFromTemplateResponse } from "../../components/interfaces/IConfiguratorFromTemplateResponse";
import { IAttribute } from "../../components/interfaces/IAttribute";
import Error from "../../components/errorHandler/Error";

const Home = () => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(true);
  const [isValidConfiguration, setIsValidConfiguration] =
    useState<boolean>(false);
  const location = useLocation();
  const tids = new URLSearchParams(location.search).get("tids");
  const [configFromTemplateData, setConfigFromTemplateData] =
    useState<IConfiguratorFromTemplateResponse>();
  const [noError, setNoError] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    loadConfiguratorFromTemplate();
  }, []);

  useEffect(() => {
    console.log("response data", configFromTemplateData?.state);
    console.log("response data", configFromTemplateData?.pid);
    if (
      configFromTemplateData?.state !== "incomplete" &&
      configFromTemplateData?.state !== undefined
    ) {
      console.log("is valid config", isValidConfiguration);
      console.log(
        "is valid config templateData",
        configFromTemplateData?.state
      );
      setIsValidConfiguration(true);
    }
  }, [configFromTemplateData]);

  useEffect(() => {
    console.log("is valid config templateData", configFromTemplateData?.state);
  });

  const formatResponseData = (response: any) => {
    const formatedResponse: IConfiguratorFromTemplateResponse = {
      UIName: response.data.UIName,
      cid: response.data.cid,
      errPos: response.data.errPos,
      msg: response.data.msg,
      pid: response.data.pid,
      state: response.data.state,
      title: response.data.title,
      properties: response.data.properties,
      isWizard: response.data.isWizard,
      isChild: response.data.isChild,
      presentation: response.data.presentation,
      attributes: [],
    };
    for (const key in response.data) {
      if (response.data[key].class) {
        let attr: any = response.data[key];
        let attribute: IAttribute = {
          class: attr.class,
          displayText: attr.displayText,
          parent: attr.parent,
          id: attr.id,
          isSelected: attr.isSelected,
          name: attr.name,
          isQuantifiable: attr.isQuantifiable,
          isReqOne: attr.isReqOne,
          isAllowed: attr.isAllowed,
          auxText: attr.auxText,
          fullName: attr.fullName,
          qty: attr.qty,
          pidCode: attr.pidCode,
          thumbnail: attr.thumbnail,
          range: attr.range,
          value: attr.value,
          hasSelectables: attr.hasSelectables,
          readOnly: attr.readOnly,
          isConfigured: attr.isConfigured,
          isShowing: true,
        };
        formatedResponse.attributes?.push(attribute);
      }
    }
    setConfigFromTemplateData(formatedResponse);
    setIsLoading(false);
  };

  const loadConfiguratorFromTemplate = async () => {
    try {
      setIsLoading(true);
      const result = await pstV1ApiService.getLoadConfiguratorFromTemplate(
        tids,
        undefined,
        undefined
      );
      const response: any = new Response(result);
      if (response.isSuccessful()) {
        formatResponseData(response);
      }
    } catch (e) {
      console.log("error in loadConfiguratorFromTemplate", e);
      setIsLoading(false);
      setNoError(false);
      setOpenDrawer(false);
    }
  };
  const resetConfiguration = () => {
    loadConfiguratorFromTemplate();
  };

  const updateConfigData = (attribute: IAttribute, response: any) => {
    console.log("updateConfig", response);
    console.log("updateConfig", attribute);

    if (
      configFromTemplateData &&
      response !== null &&
      response[attribute.id] //&&
      //response[attribute.id].value
    ) {
      // console.log("updateConfig item", response[attribute.id]);
      // console.log("updateConfig item value", response[attribute.id].value);
      // console.log("updateConfig item isSelected", response[attribute.id].isSelected);
      // console.log("updateConfig item isAllowed", response[attribute.id].isAllowed);
      // console.log("is valid config", configFromTemplateData?.state);
      // const updatedAttribute : IAttribute = attribute;
      // updatedAttribute.value = response[attribute.id].value;
      const updatedData: IConfiguratorFromTemplateResponse = {
        ...configFromTemplateData,
        pid: response.pid ? response.pid : configFromTemplateData.pid,
        cid: configFromTemplateData.cid,
        state: response.state,
        properties: configFromTemplateData.properties,
        // attributes: updatedAttribute,
      };
      const index: number | undefined =
        configFromTemplateData?.attributes?.indexOf(attribute) === undefined
          ? 0
          : configFromTemplateData?.attributes?.indexOf(attribute);
      console.log(
        "updateConfig original data",
        configFromTemplateData?.attributes?.[index]
      );
      console.log(
        "updateConfig updated Data",
        updatedData?.attributes?.[index]
      );
      if (
        JSON.stringify(configFromTemplateData) !== JSON.stringify(updatedData)
      ) {
        console.log("resonse data is different");
        setConfigFromTemplateData(updatedData);
      } else {
        console.log("response data is the same");
      }
    }

    for (const key in response) {
      let updatedItem: IAttribute;
      if (key !== attribute.id) {
        // if (response[key].isSelected) {
        //   const itemAtr = configFromTemplateData?.attributes?.find(
        //     (atr) => atr.id === key
        //   );
        //   if (itemAtr) {
        //     itemAtr.isSelected = true;
        //   }
        //   console.log("this data itemAtr", itemAtr);
        //   console.log("this data key", key);
        //   console.log("this data", configFromTemplateData?.attributes);
        //   console.log(
        //     "this data",
        //     configFromTemplateData?.attributes?.find((atr) => atr.id === key)
        //   );
        //   console.log("this data passed attribute", attribute);
        // }
        if (response[key].hasSelectables) {
          const itemAtr = configFromTemplateData?.attributes?.find(
            (atr) => atr.id === key
          );
          if (itemAtr) {
            updatedItem = { ...itemAtr, hasSelectables: true };

            const index = configFromTemplateData?.attributes?.findIndex(
              (atr) => atr.id === key
            );

            if (
              index !== -1 &&
              index !== undefined &&
              configFromTemplateData?.attributes
            ) {
              console.log("index", index);
              const updatedAttributes = [
                ...configFromTemplateData?.attributes?.slice(0, index),
                updatedItem,
                ...configFromTemplateData?.attributes?.slice(index + 1),
              ];

              const updatedData: IConfiguratorFromTemplateResponse = {
                ...configFromTemplateData,
                attributes: updatedAttributes,
              };

              setConfigFromTemplateData(updatedData);
            }
          }
        }
      }
    }
  };

  const updateConfigurator = async (
    attributes: IAttribute[],
    attribute: IAttribute
  ) => {
    console.log("home update config attributes", attributes);
    console.log("home update config atr", attribute);
    setIsLoading(true);
    try {
      if (configFromTemplateData) {
        const result = await pstV1ApiService.getToggleConfiguration(
          configFromTemplateData.cid,
          attribute.id
        );
        const response: any = new Response(result);
        if (response.isSuccessful()) {
          console.log("home update config response", response);
          if (response.data.state === "complete") {
            setIsValidConfiguration(true);
          }
          console.log("response data", response.data);
          updateConfigData(attribute, response.data);
          setIsLoading(false);
        }
      }
    } catch (e) {
      console.log("error in updateConfigurator", e);
      setIsLoading(false);
      setNoError(false);
      setOpenDrawer(false);
    }
  };

  const handleConfiguratorDrawerClose = () => {
    setIsValidConfiguration(false);
    setOpenDrawer(false);
  };

  return (
    <>
      {configFromTemplateData && (
        <Drawer
          isLoading={isLoading}
          drawerPosition="right"
          setOpenDrawer={openDrawer}
          handleConfiguratorDrawerClose={handleConfiguratorDrawerClose}
          isValidConfiguration={isValidConfiguration}
          configFromTemplateDataFromParent={configFromTemplateData}
          updateConfigurator={updateConfigurator}
          resetConfiguration={resetConfiguration}
        ></Drawer>
      )}
      {/* PDP / SUMMARY OF CONFIG WILL GO HERE */}
      <Box sx={{ display: "flex" }}>
        {noError ? (
          <Box sx={{ height: "500px", width: "500px" }}></Box>
        ) : (
          <Error></Error>
        )}
      </Box>
    </>
  );
};

export default Home;
