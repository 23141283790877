import { AxiosRequestConfig } from "axios";

import { getApiUrl } from "./ApiUtil";
import axios from "./Axios";

class CatalogApiService {
  api: string;
  config: AxiosRequestConfig;

  constructor() {
    this.api = "RAISE_API";

    this.config = {
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
      },
    };
  }

  GetProductLibrary() {
    const url = `${getApiUrl(this.api)}/Catalog/GetProductLibrary`;

    return axios.get(url);
  }
}

const catalogApiService = new CatalogApiService();
export default catalogApiService;
