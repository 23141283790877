import React, { useEffect, useState } from "react";
import { Button, Box, Typography, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ProjectBom } from "../../interfaces/ProposalWorks";
import { BOM } from "../../interfaces/BOM";

interface ProductBomButtonProps {
  handleQuickAdd: () => void;
  user: Oidc.User | undefined;
  lastBillOfMaterials?: BOM | null;
}

const ProductBomButtonCompare = (props: ProductBomButtonProps) => {
  const { handleQuickAdd, user, lastBillOfMaterials } = props;

  const [titleMessage, setTitleMessage] = useState<string>();
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (!user) {
      setTitleMessage("Log in to add product to Project");
      setDisabled(true);
    } else {
      setTitleMessage("add product to Project");
      setDisabled(false);
    }
  }, [user, lastBillOfMaterials]);

  return (
    <>
      {!disabled && (
        <Tooltip placement="top" arrow title={titleMessage}>
          <Box>
            <Button
              variant="outlined"
              sx={{
                marginTop: "20px",
              }}
              onClick={handleQuickAdd}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <AddIcon fontSize="small" />
              </Box>
              <Typography
                sx={{
                  textAlign: "center",
                  marginLeft: "10px",
                }}
              >
                ADD TO PROJECT
              </Typography>
            </Button>
          </Box>
        </Tooltip>
      )}
    </>
  );
};

export default ProductBomButtonCompare;
