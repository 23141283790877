import { MulesoftApiResponse } from "../../interfaces/MulesoftApiResponse";

export const BOM_SELECTOR_GROUP = "bom-selector__";

export const LOCAL_STORAGE_PROJECT = "selectedProject";
export const PROJECT_GUID = "projectGuid";
export const PROJECT_ID = "projectId";
const MULESOFT_API_RESPONSE_SESSION_STORAGE = "mulesoftApiResponse";

export const getBomSelectorGroupItem = (projectBomId: number): string => {
  return BOM_SELECTOR_GROUP + projectBomId;
};

export const getSessionMulesoftApiResponse = () => {
  try {
    const response = localStorage.getItem(
      MULESOFT_API_RESPONSE_SESSION_STORAGE
    );
    if (response) {
      return JSON.parse(response) as MulesoftApiResponse;
    }
  } catch {
    return null;
  }
};

export const setSessionMulesoftApiResponse = (
  response: MulesoftApiResponse
) => {
  try {
    localStorage.setItem(
      MULESOFT_API_RESPONSE_SESSION_STORAGE,
      JSON.stringify(response)
    );
    return response;
  } catch {
    return null;
  }
};
