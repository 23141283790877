import React, { useEffect, useState } from "react";
import {
  Drawer,
  Toolbar,
  Typography,
  Divider,
  Box,
  Unstable_Grid2 as Grid,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Chip,
  Stack,
  Tooltip,
  useTheme,
} from "@mui/material";
import FilterIcon from "@mui/icons-material/FilterAlt";
import SearchSidebar from "../searchSidebar/SearchSidebar";
import CatalogProduct from "../../interfaces/CatalogProduct";
import SearchCriteriaAttributes from "../../interfaces/SearchCriteriaAttributes";
import { PricingAndAvailability } from "../../interfaces/PricingAndAvailability";
import SearchProductAttribute from "../../interfaces/SearchProductAttribute";
import SearchProductAttributeGroup from "../../interfaces/SearchProductAttributeGroup";
import SearchCriteriaProducts from "../../interfaces/SearchCriteriaProducts";
import PageSelector from "../common/PageSelector/PageSelector";
import SearchProduct from "../../interfaces/SearchProduct";
import { useAppDispatch } from "../../redux/hooks";
import { setNumberOfResultsPerPage } from "../../redux/slices/numberOfResultsPerPageSlice";
import { useSelector } from "react-redux";
import CompareBar from "../compareBar/CompareBar";
import CatalogTemplate from "../../interfaces/CatalogTemplate";
import SearchSidebarLoader from "../searchLoader/SearchSidebarLoader";
import SearchResultLoader from "../searchLoader/SearchResultLoader";
import ProductCard from "../productResultItem/ProductCard";
import LayoutType from "../../constants/LayoutType";
import LayoutSwitchButton from "../../components/LayoutSwitchButton/LayoutSwicthButton";
import { setCardValue, selectCardValue } from "../../redux/slices/compareSlice";
import { InformationCircleIcon } from "@rockwell-automation-inc/ra-meatball";
import { selectUser, usePSAppSelector } from "platform-services";

interface SearchSelectProps {
  _window?: () => Window;
  searchCriteriaProducts: SearchCriteriaProducts;
  searchCriteriaGroupTids: Array<CatalogProduct>;
  searchCriteriaAttributes: SearchCriteriaAttributes;
  setSearchCriteriaAttributes: Function;
  pricingAndAvailability: PricingAndAvailability;
  setPricingAndAvailability: Function;
  tids: string;
  setTids: Function;
  searchCriteria: string;
  activeFilters: string;
  setActiveFilters: Function;
  enableGuidedSelection: boolean;
  setEnableGuidedSelection: Function;
  numberOfResultsPerPage: number;
  pageNumber: number;
  setPageNumber: Function;
  productsLoading: boolean;
  criteriaLoading: boolean;
  catalogTreeLoading: boolean;
  groupId?: string;
  sortOrderType?: string;
  setSortOrderType: Function;
}

const SearchSelect = (props: SearchSelectProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const {
    _window,
    searchCriteriaProducts,
    searchCriteriaGroupTids,
    searchCriteriaAttributes,
    setSearchCriteriaAttributes,
    pricingAndAvailability,
    setPricingAndAvailability,
    tids,
    setTids,
    searchCriteria,
    activeFilters,
    setActiveFilters,
    enableGuidedSelection,
    setEnableGuidedSelection,
    numberOfResultsPerPage,
    pageNumber,
    setPageNumber,
    productsLoading,
    criteriaLoading,
    catalogTreeLoading,
    groupId,
    sortOrderType,
    setSortOrderType,
  } = props;

  const user = usePSAppSelector(selectUser);
  const cardValue = useSelector(selectCardValue);
  const [searchTerm, setSearchTerm] = useState("");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [currentProductCardLayout, setCurrentProductCardLayout] =
    useState<string>(LayoutType.Grid);

  // allows drawer to work within an iframe
  const container =
    _window !== undefined ? () => _window().document.body : undefined;

  const drawerWidth = 350;

  useEffect(() => {
    if (cardValue === 3) {
      dispatch(setCardValue(4));
    }
  }, [cardValue, dispatch]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleTemplateChange = (templateId: string, templateAdd: boolean) => {
    let tidsArray = tids !== "" ? tids.split(",") : [];

    if (templateAdd) {
      tidsArray = [...tidsArray, templateId];
    } else {
      tidsArray = tidsArray.filter((tid) => tid !== templateId);
    }

    setTids(tidsArray.join(","));
  };

  const handleFilterChange = (
    name: string,
    attribute: SearchProductAttribute,
    filterAdd: boolean
  ) => {
    const label: string = filterAdd ? " chip added" : "chip deleted";
    const filters: Array<SearchProductAttributeGroup> = activeFilters.trim()
      ? JSON.parse(activeFilters)
      : [];
    const filterIndex = filters.findIndex((o) => o.name === name);
    if (filterAdd) {
      if (filterIndex !== -1) {
        // TODO check to see if attribute already exists
        filters[filterIndex].values.push(attribute);
      } else {
        filters.push({
          name: name,
          values: [attribute],
        });
      }
    } else {
      filters[filterIndex].values.forEach((currentAttribute, index, array) => {
        if (currentAttribute.name === attribute.name) {
          array.splice(index, 1);
        }
      });
    }

    // remove empty filters to prevent sending superfluous data
    filters.forEach((group, index, array) => {
      if (group.values && group.values.length === 0) {
        array.splice(index, 1);
      }
    });

    setActiveFilters(JSON.stringify(filters));

    const tabClick = new CustomEvent("chipAction", {
      detail: {
        action: "Chip Action",
        properties: {
          category: "WebApp",
          label: label,
          facet: attribute.name,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handlePriceAndAvailabilityChange = (
    pricingAndAvailability: PricingAndAvailability
  ) => {
    setPricingAndAvailability(pricingAndAvailability);
  };

  const handleChangePage = (newPageNumber: number) => {
    if (
      newPageNumber > 1 ||
      newPageNumber < searchCriteriaProducts?.totalPages
    ) {
      setPageNumber(newPageNumber);
    }
  };

  const handleChangePageClick = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    handleChangePage(newPage);
  };

  const handleChangePageSelect = (event: SelectChangeEvent) => {
    handleChangePage(parseInt(event.target.value));
  };

  const handleSortChange = (event: SelectChangeEvent) => {
    console.log(event);
    setSortOrderType(event.target.value);
    if (event.target.value === "ClosestMatch") {
      const tabClick = new CustomEvent("closestMatchSortClick", {
        detail: {
          action: "Closest Match Sort Click",
          properties: {
            category: "WebApp",
            label: "Sort By: Closest Match",
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    } else if (event.target.value === "Availability") {
      const tabClick = new CustomEvent("availabilitySortClick", {
        detail: {
          action: "Availability Sort Click",
          properties: {
            category: "WebApp",
            label: "Sort By: Availability",
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    } else if (event.target.value === "PriceAscending") {
      const tabClick = new CustomEvent("priceAscendingSortClick", {
        detail: {
          action: "Price Ascending Sort Click",
          properties: {
            category: "WebApp",
            label: "Sort By: Price Ascending",
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    } else if (event.target.value === "PriceDescending") {
      const tabClick = new CustomEvent("priceDescendingSortClick", {
        detail: {
          action: "Price Descending Sort Click",
          properties: {
            category: "WebApp",
            label: "Sort By: Price Descending",
          },
        },
      });
      document.getElementById("root")?.dispatchEvent(tabClick);
    }
  };

  const handleChangeProductsPerPage = (event: SelectChangeEvent) => {
    console.log(event);
    dispatch(setNumberOfResultsPerPage(parseInt(event.target.value)));
    setPageNumber(1); // reset to the first page
  };

  const handleInStockDelete = () => {
    if (pricingAndAvailability.readyToShip === true)
      setPricingAndAvailability({
        ...pricingAndAvailability,
        readyToShip: false,
      });
    const tabClick = new CustomEvent("inStockChipDelete", {
      detail: {
        action: "Deleted In-Stock Chip",
        properties: {
          category: "WebApp",
          label: "Deleted In-Stock Chip",
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleUserPriceDelete = () => {
    if (pricingAndAvailability.maxListPriceUserInputDisplay) {
      handlePriceAndAvailabilityChange({
        ...pricingAndAvailability,
        maxListPriceUserInputDisplay: "",
      });
    }
    const tabClick = new CustomEvent("userEnteredPriceChipDelete", {
      detail: {
        action: "User Entered Price Chip Deleted",
        properties: {
          category: "WebApp",
          label: "User Entered Price Chip Deleted",
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleLeadTimeDelete = () => {
    if (pricingAndAvailability.maxAvailability) {
      handlePriceAndAvailabilityChange({
        ...pricingAndAvailability,
        maxAvailability: null,
      });
    }
    const tabClick = new CustomEvent("userEnteredLeadTimeChipDelete", {
      detail: {
        action: "User Entered Lead Time Chip Deleted",
        properties: {
          category: "WebApp",
          label: "User Entered Lead Time Chip Deleted",
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  interface GroupTidChips {
    groupTemplate: CatalogProduct;
  }
  const TidChips: React.FC<GroupTidChips> = ({ groupTemplate }) => {
    return (
      <>
        <>
          {groupTemplate.childGroups.length > 1 &&
            groupTemplate.childGroups.map(
              (childTemplate: CatalogProduct, index: number) => {
                return (
                  <TidChips
                    key={childTemplate.title + index}
                    groupTemplate={childTemplate}
                  />
                );
              }
            )}
          {groupTemplate.templates.length > 0 &&
            groupTemplate.templates.map(
              (template: CatalogTemplate, index: number) => {
                if (!!tids.split(",").find((v) => v === template.templateId))
                  return (
                    <Stack
                      key={template.templateId}
                      sx={{
                        display: "inline-grid",
                        mb: 1,
                        ml: 1,
                      }}
                      direction="row"
                      spacing={1}
                    >
                      <Chip
                        label={
                          <Typography sx={{ marginTop: "3px" }} color="white">
                            {template.title}
                          </Typography>
                        }
                        onDelete={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          handleTemplateChange(
                            template.templateId,
                            event.target.checked
                          )
                        }
                        color="info"
                        size="small"
                      />
                    </Stack>
                  );
              }
            )}
        </>
      </>
    );
  };

  return (
    <>
      {/* TODO: fix duplicate markup */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        anchor="left"
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        <Toolbar>
          <Typography sx={{ display: "flex", gap: 1 }}>
            <FilterIcon />
            Search Filters
          </Typography>
        </Toolbar>
        <Divider />
        <Box sx={{ py: 2, px: 3 }}>
          <SearchSidebar
            searchCriteriaGroupTids={searchCriteriaGroupTids}
            searchCriteriaAttributes={searchCriteriaAttributes}
            pricingAndAvailability={pricingAndAvailability}
            handleTemplateChange={handleTemplateChange}
            handleFilterChange={handleFilterChange}
            handlePriceAndAvailabilityChange={handlePriceAndAvailabilityChange}
            tids={tids}
            setTids={setTids}
            searchCriteria={searchCriteria}
            catalogTreeLoading={catalogTreeLoading}
            groupId={groupId}
            enableGuidedSelection={enableGuidedSelection}
            setEnableGuidedSelection={setEnableGuidedSelection}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
          />
        </Box>
      </Drawer>

      <Grid container spacing={3}>
        <Grid
          md={4}
          sx={{
            display: { xs: "none", md: "block " },
          }}
        >
          {criteriaLoading ? (
            <SearchSidebarLoader />
          ) : (
            <SearchSidebar
              searchCriteriaGroupTids={searchCriteriaGroupTids}
              searchCriteriaAttributes={searchCriteriaAttributes}
              pricingAndAvailability={pricingAndAvailability}
              handleTemplateChange={handleTemplateChange}
              handleFilterChange={handleFilterChange}
              handlePriceAndAvailabilityChange={
                handlePriceAndAvailabilityChange
              }
              tids={tids}
              setTids={setTids}
              searchCriteria={searchCriteria}
              catalogTreeLoading={catalogTreeLoading}
              groupId={groupId}
              enableGuidedSelection={enableGuidedSelection}
              setEnableGuidedSelection={setEnableGuidedSelection}
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
            />
          )}
        </Grid>
        <Grid xs={12} md={8}>
          {productsLoading ? (
            <SearchResultLoader />
          ) : (
            <>
              <Box>
                <Box
                  sx={{
                    display: { md: "none" },
                  }}
                >
                  <Button
                    startIcon={<FilterIcon />}
                    onClick={handleDrawerToggle}
                  >
                    Search Filters
                  </Button>
                </Box>

                {searchCriteriaGroupTids
                  .filter((c) => c.showCatalogGroup === true)
                  .map((template: CatalogProduct, index: number) => {
                    return (
                      <TidChips key={index} groupTemplate={template}></TidChips>
                    );
                  })}
                {!criteriaLoading && (
                  <>
                    {searchCriteriaAttributes.productAttributes.map(
                      (group: SearchProductAttributeGroup, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            {group.values
                              .filter((i) =>
                                i.name
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                              )
                              .map(
                                (
                                  attribute: SearchProductAttribute,
                                  index: number
                                ) => {
                                  if (
                                    attribute.isGuidedSelection === false &&
                                    attribute.isSelected === true
                                  ) {
                                    return (
                                      <Stack
                                        key={index}
                                        sx={{
                                          display: "inline-grid",
                                          mb: 1,
                                          ml: 1,
                                        }}
                                        direction="row"
                                        spacing={1}
                                      >
                                        <Chip
                                          label={
                                            <Typography
                                              sx={{ marginTop: "3px" }}
                                              color="white"
                                            >
                                              {attribute.name}
                                            </Typography>
                                          }
                                          onDelete={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                          ) =>
                                            handleFilterChange(
                                              group.name,
                                              attribute,
                                              false
                                            )
                                          }
                                          color="info"
                                          size="small"
                                        />
                                      </Stack>
                                    );
                                  }
                                }
                              )}
                          </React.Fragment>
                        );
                      }
                    )}
                  </>
                )}
                {pricingAndAvailability.readyToShip === true && (
                  <Stack
                    sx={{
                      display: "flex",
                      mb: 1,
                      ml: 1,
                    }}
                    direction="row"
                    spacing={1}
                  >
                    <Chip
                      label={
                        <Typography sx={{ marginTop: "3px" }} color="white">
                          In-Stock
                        </Typography>
                      }
                      onDelete={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInStockDelete()
                      }
                      color="info"
                      size="small"
                    />
                  </Stack>
                )}

                {pricingAndAvailability.maxListPriceUserInputDisplay ===
                  pricingAndAvailability.maxListPriceUserInputDisplay &&
                  pricingAndAvailability.maxListPriceUserInputDisplay && (
                    <Stack
                      sx={{
                        display: "flex",
                        mb: 1,
                        ml: 1,
                      }}
                      direction="row"
                      spacing={1}
                    >
                      <Chip
                        label={
                          <Typography sx={{ marginTop: "3px" }} color="white">
                            {
                              pricingAndAvailability.maxListPriceUserInputDisplay
                            }{" "}
                            List Price Maximum
                          </Typography>
                        }
                        onDelete={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleUserPriceDelete()}
                        color="info"
                        size="small"
                      />
                    </Stack>
                  )}
                {pricingAndAvailability.maxAvailability && (
                  <Stack
                    sx={{
                      display: "flex",
                      mb: 1,
                      ml: 1,
                    }}
                    direction="row"
                    spacing={1}
                  >
                    <Chip
                      label={
                        <Typography sx={{ marginTop: "3px" }} color="white">
                          {pricingAndAvailability.maxAvailability} Days Lead
                          Time Maximum
                        </Typography>
                      }
                      onDelete={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleLeadTimeDelete()
                      }
                      color="info"
                      size="small"
                    />
                  </Stack>
                )}

                {user && enableGuidedSelection && (
                  <>
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                    >
                      <Typography fontWeight="bold">
                        Location Based Results
                      </Typography>
                      <Tooltip
                        placement="bottom"
                        title={
                          <Box
                            sx={{
                              color: theme.palette.mediumGrey.main,
                              display: "flex",
                              gap: "10px",
                              flexDirection: "column",
                              fontSize: "12px",
                              lineHeight: "18px",
                              p: 1,
                            }}
                          >
                            <span>
                              The most selected options offered in your location
                              have been preselected to help you focus on what
                              matters to you when searching for our products.
                            </span>
                            <span>
                              These preselected options can be turned off by
                              toggling off the Location Based Results filter and
                              would allow for our entire standard family product
                              offerings to be searched on.
                            </span>
                          </Box>
                        }
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <InformationCircleIcon />
                        </Box>
                      </Tooltip>
                    </Box>
                  </>
                )}
                {!criteriaLoading && (
                  <>
                    {searchCriteriaAttributes.productAttributes.map(
                      (group: SearchProductAttributeGroup, index: number) => {
                        return (
                          <>
                            {group.values
                              .filter((i) =>
                                i.name
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                              )
                              .map(
                                (
                                  attribute: SearchProductAttribute,
                                  index: number
                                ) => {
                                  if (
                                    user &&
                                    enableGuidedSelection &&
                                    attribute.isGuidedSelection === true &&
                                    attribute.isSelected === true
                                  ) {
                                    return (
                                      <Stack
                                        key={index}
                                        sx={{
                                          display: "inline-grid",
                                          mb: 1,
                                          ml: 1,
                                          mt: 1,
                                        }}
                                        direction="row"
                                        spacing={1}
                                      >
                                        <Chip
                                          sx={{
                                            background:
                                              theme.palette.purple.main,
                                          }}
                                          label={
                                            <Typography
                                              sx={{ marginTop: "3px" }}
                                              color="white"
                                            >
                                              {attribute.name}
                                            </Typography>
                                          }
                                          onDelete={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                          ) =>
                                            handleFilterChange(
                                              group.name,
                                              attribute,
                                              false
                                            )
                                          }
                                          color="secondary"
                                          size="small"
                                        />
                                      </Stack>
                                    );
                                  }
                                }
                              )}
                          </>
                        );
                      }
                    )}
                  </>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: { xs: "left", md: "center" },
                    flexDirection: { xs: "column", md: "row" },
                    mt: 2,
                  }}
                >
                  {searchCriteriaProducts.totalCount > 0 && (
                    <>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Showing Results:{` `}
                        {(pageNumber - 1) * numberOfResultsPerPage + 1}-
                        {(pageNumber - 1) * numberOfResultsPerPage +
                          numberOfResultsPerPage >
                        searchCriteriaProducts.totalCount
                          ? searchCriteriaProducts.totalCount
                          : (pageNumber - 1) * numberOfResultsPerPage +
                            numberOfResultsPerPage}
                        {` `}/{` `}
                        {searchCriteriaProducts.totalCount.toLocaleString("US")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        <InputLabel
                          id="sort-select-label"
                          sx={{
                            color: "rgba(0, 0, 0, 0.87)",
                            fontWeight: "bold",
                          }}
                        >
                          Sort By:
                        </InputLabel>
                        {user && (
                          <FormControl size="small">
                            <Select
                              labelId="sort-select-label"
                              id="sort-select"
                              value={sortOrderType}
                              onChange={handleSortChange}
                              MenuProps={{
                                sx: {
                                  position: "absolute",
                                },
                              }}
                            >
                              <MenuItem value="ClosestMatch">
                                Closest Match
                              </MenuItem>
                              <MenuItem value="Availability">
                                Availability
                              </MenuItem>
                              <MenuItem value="PriceAscending">
                                Price Ascending
                              </MenuItem>
                              <MenuItem value="PriceDescending">
                                Price Descending
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}
                        {!user && <Typography>Closest Match</Typography>}
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              {/* RESULTS */}

              <LayoutSwitchButton
                currentLayout={currentProductCardLayout}
                handleLayoutChange={setCurrentProductCardLayout}
                sx={{
                  my: 4,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                }}
              />
              {searchCriteriaProducts.products &&
                searchCriteriaProducts.products.length > 0 && (
                  <Box sx={{ my: 2 }}>
                    {currentProductCardLayout === LayoutType.Grid ? (
                      <Grid container spacing={3}>
                        {searchCriteriaProducts.products.map(
                          (product: SearchProduct, index: number) => {
                            return (
                              <Grid key={index} sm={12} md={6} xl={4}>
                                <ProductCard
                                  product={product}
                                  currentLayout={currentProductCardLayout}
                                  searchCriteria={searchCriteria}
                                  groupId={groupId}
                                  pageNumber={pageNumber}
                                />
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    ) : (
                      <Grid container rowGap={3}>
                        {searchCriteriaProducts.products.map(
                          (product: SearchProduct, index: number) => {
                            return (
                              <Grid key={index} sm={12}>
                                <ProductCard
                                  product={product}
                                  currentLayout={currentProductCardLayout}
                                  searchCriteria={searchCriteria}
                                  groupId={groupId}
                                  pageNumber={pageNumber}
                                />
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    )}
                    <PageSelector
                      handleChangePageClick={(
                        event: React.ChangeEvent<unknown>,
                        newPage: number
                      ) => handleChangePageClick(event, newPage)}
                      handleChangePageSelect={(event: SelectChangeEvent) =>
                        handleChangePageSelect(event)
                      }
                      handleChangeProductsPerPage={(event: SelectChangeEvent) =>
                        handleChangeProductsPerPage(event)
                      }
                      totalCount={searchCriteriaProducts.totalCount}
                      totalPages={searchCriteriaProducts.totalPages}
                      pageNumber={pageNumber}
                      numberOfResultsPerPage={numberOfResultsPerPage}
                      rowsPerPageOptions={[25, 50, 100]}
                    />
                  </Box>
                )}
            </>
          )}
        </Grid>
      </Grid>
      <CompareBar></CompareBar>
    </>
  );
};

export default SearchSelect;
