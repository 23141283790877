import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonBase,
  CardMedia,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import SearchForm from "../../components/searchForm/SearchForm";
import { useAppSelector } from "../../redux/hooks";
import "../../components/searchSidebar/SearchSidebar.scss";
import Masthead from "../../components/masthead/Masthead";
import SearchBreadcrumbs from "../../components/searchBreadcrumbs/SearchBreadcrumbs";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { selectCatalog } from "../../redux/slices/catalogSlice";
import searchApiService from "../../services/apis/SearchApiService";
import { useEffect, useState } from "react";
import { TemplateAttribute } from "../../interfaces/TemplateAttribute";
import Response from "../../services/apis/Response";
import { PostTemplateAttributesResponse } from "../../interfaces/PostTemplateAttributesResponse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TemplateAttributeValue } from "../../interfaces/TemplateAttributeValue";
import SearchResultLoader from "../../components/searchLoader/SearchResultLoader";
import {
  TeaserCard,
  TeaserCardActions,
  TeaserCardDescription,
  TeaserCardMedia,
  TeaserCardTitle,
} from "@rockwell-automation-inc/ra-meatball";
import SearchSidebarLoader from "../../components/searchLoader/SearchSidebarLoader";
import { findNestedProduct } from "../../helpers/Utils";
import CatalogTemplate from "../../interfaces/CatalogTemplate";
import CatalogProduct from "../../interfaces/CatalogProduct";
import PATHS from "../../constants/Paths";
import defaultImage from "../../assets/Image_Unavailable.svg";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import FlashOnOutlinedIcon from "@mui/icons-material/FlashOnOutlined";
import numberOfResultsPerPage from "../../redux/slices/numberOfResultsPerPageSlice";
import PageSelector from "../../components/common/PageSelector/PageSelector";
import ROUTES from "../../constants/Routes";

const DriveFamilyPage = () => {
  const { catalogGroupId } = useParams();
  const [templates, setTemplates] =
    useState<PostTemplateAttributesResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const catalog = useAppSelector(selectCatalog);
  const [page, setPage] = useState(1);
  const [numberOfResultsPerPage, setNumberOfResultsPerPage] = useState(25);
  const theme = useTheme();
  const [breadcrumb, setBreadcrumb] = useState<Array<CatalogProduct>>([]);
  const navigate = useNavigate();

  const fetchTemplateAttributeData = async (
    catalogGroupId: string,
    templates: PostTemplateAttributesResponse | null
  ): Promise<PostTemplateAttributesResponse> => {
    setIsLoading(true);
    try {
      const result = await searchApiService.PostTemplateAttributes({
        catalogGroup: catalogGroupId,
        attributes: templates?.attributes || [],
      });
      setIsLoading(false);
      return new Response(result).getData();
    } catch {
      setIsLoading(false);
      throw new Error();
    }
  };

  const products = catalogGroupId
    ? findNestedProduct(catalog, catalogGroupId)
        ?.childGroups.map((child) => {
          return child.templates.filter((i) =>
            templates?.matchedTemplates.includes(i.templateId)
          );
        })
        .flat()
    : [];

  const handleClearAll = async () => {
    const newAttributes: TemplateAttribute[] =
      templates?.attributes.map((attribute) => {
        return {
          ...attribute,
          values: attribute.values.map((value) => {
            return {
              ...value,
              isSelected: false,
            };
          }),
        };
      }) || [];
    if (templates && catalogGroupId) {
      setTemplates(
        await fetchTemplateAttributeData(catalogGroupId, {
          ...templates,
          attributes: newAttributes,
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      if (catalogGroupId) {
        setTemplates(
          await fetchTemplateAttributeData(catalogGroupId, templates)
        );
      }
    })();
  }, [catalogGroupId]);

  const handleCheck = async (
    attribute: TemplateAttribute,
    value: TemplateAttributeValue,
    checked: boolean
  ) => {
    const newAttributes: TemplateAttribute[] = templates
      ? templates?.attributes.map((_attribute) => {
          if (_attribute.id === attribute.id) {
            return {
              ...attribute,
              values: _attribute.values.map((_value) => {
                if (_value.name === value.name) {
                  return {
                    ..._value,
                    isSelected: checked,
                  };
                }
                return _value;
              }),
            };
          }
          return _attribute;
        })
      : [];
    if (templates && catalogGroupId) {
      setTemplates(
        await fetchTemplateAttributeData(catalogGroupId, {
          ...templates,
          attributes: newAttributes,
        })
      );
    }
  };

  interface TemplateGroupProps {
    attribute: TemplateAttribute;
  }

  const TemplateGroup: React.FC<TemplateGroupProps> = ({ attribute }) => {
    const [searchTerm, setSearchTerm] = useState("");

    return (
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`group-${attribute.name}-content`}
          id={`group-${attribute.name}-header`}
          style={{ fontWeight: "bold" }}
        >
          {attribute.name}
        </AccordionSummary>
        <AccordionDetails
          id={`group-${attribute.name}-content`}
          sx={{
            maxHeight: "14rem",
            overflow: "auto",
            padding: "10px",
          }}
        >
          {attribute.values.length >= 20 && (
            // <SearchBar
            //   index={index}
            //   searchTerm={searchTerm}
            //   handleChange={handleChange}
            // />
            <></>
          )}
          {attribute.values
            .filter((i) =>
              i.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((value: TemplateAttributeValue, index: number) => {
              return (
                <FormControl key={index} fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          handleCheck(attribute, value, event.target.checked)
                        }
                        checked={value.isSelected}
                        disabled={!value.isSelectable}
                        sx={{ padding: "5px" }}
                      />
                    }
                    label={
                      <Box display="flex" style={{ fontSize: ".9rem" }}>
                        <Typography color="black">{value.name}</Typography>
                      </Box>
                    }
                  />
                </FormControl>
              );
            })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const Cost: React.FC<{ cost: number }> = ({ cost }) => {
    const inner = "#EDF7ED";
    const outer = "#468C4A";
    const disabled = "#BFEEA0";
    return (
      <Box
        sx={{
          display: "flex",
          borderRadius: "10px",
          backgroundColor: inner,
          border: `1px solid ${outer}`,
          width: "fit-content",
        }}
      >
        {[...Array(3)].map((_, index: number) => {
          return (
            <AttachMoneyOutlinedIcon
              fontSize="large"
              sx={{ color: cost >= index + 1 ? outer : disabled }}
            />
          );
        })}
      </Box>
    );
  };

  const Features: React.FC<{ features: number }> = ({ features }) => {
    const inner = "#E5F6FD";
    const outer = "#0288D1";
    const disabled = "#BBDEFB";
    return (
      <Box
        sx={{
          display: "flex",
          borderRadius: "10px",
          backgroundColor: inner,
          border: `1px solid ${outer}`,
          width: "fit-content",
        }}
      >
        {[...Array(3)].map((_, index: number) => {
          return (
            <FlashOnOutlinedIcon
              fontSize="large"
              sx={{ color: features >= index + 1 ? outer : disabled }}
            />
          );
        })}
      </Box>
    );
  };

  const Results = () => {
    return (
      <Typography sx={{ fontWeight: "bold" }}>
        Showing Results:{` `}
        {(page - 1) * numberOfResultsPerPage + 1} -
        {(page - 1) * numberOfResultsPerPage + numberOfResultsPerPage >
        (products?.length || 0)
          ? products?.length || 0
          : (page - 1) * numberOfResultsPerPage + numberOfResultsPerPage}
        {` `}/{` `}
        {products?.length.toLocaleString("US")}
      </Typography>
    );
  };

  const Chips = () => {
    return (
      <>
        {templates?.attributes.map((attribute) => {
          return (
            <>
              {attribute.values
                .filter((i) => i.isSelected)
                .map((value, index) => {
                  return (
                    <Stack
                      key={index}
                      sx={{
                        display: "inline-grid",
                        mb: 1,
                        ml: 1,
                        mt: 1,
                      }}
                      direction="row"
                      spacing={1}
                    >
                      <Chip
                        sx={{
                          background: theme.palette.primary.main,
                        }}
                        label={
                          <Typography color="white">{value.name}</Typography>
                        }
                        onDelete={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          handleCheck(attribute, value, event.target.checked)
                        }
                        color="secondary"
                        size="small"
                      />
                    </Stack>
                  );
                })}
            </>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Masthead />

      {/* breadcrumbs */}
      <Container sx={{ my: 0, py: 3 }}>
        <SearchBreadcrumbs
          loading={false}
          searchCriteriaGroupTids={[]}
          tids={""}
          searchCriteria={""}
          groupId={templates?.catalogGroup}
          currentProduct={findNestedProduct(
            catalog,
            templates?.catalogGroup || "",
            setBreadcrumb
          )}
        />
      </Container>

      <Box sx={{ py: 1, width: "60%", mx: "auto" }}>
        <Container sx={{ my: 3 }}>
          <SearchForm />
        </Container>
      </Box>

      <Container
        sx={{
          my: 0,
          py: 3,
          bgcolor: "#fff",
          borderRadius: "4px",
        }}
      >
        <Grid container spacing={3}>
          <Grid
            item
            md={4}
            sx={{
              display: { xs: "none", md: "block " },
            }}
          >
            {isLoading ? (
              <SearchSidebarLoader />
            ) : (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ pl: 2, pr: 4, mb: 2 }}
                >
                  <Typography fontWeight="bold">Filter and Refine</Typography>
                  <Button
                    style={{
                      border: "none",
                      background: "none",
                      color: "blue",
                    }}
                    size="small"
                    onClick={handleClearAll}
                  >
                    Clear All
                  </Button>
                </Box>
                {templates?.attributes.map((attribute) => {
                  return <TemplateGroup attribute={attribute} />;
                })}
              </>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            {isLoading ? (
              <SearchResultLoader />
            ) : (
              <Box sx={{ my: 2, px: 2 }}>
                <Box sx={{ mb: 2 }}>
                  <Chips />
                  <Box sx={{ mb: 2 }} />
                  <Results />
                </Box>
                <Grid container spacing={2}>
                  {products?.map((product: CatalogTemplate, index: number) => {
                    return (
                      <Grid item key={index} sm={12} md={6} xl={4}>
                        <ButtonBase
                          onClick={() =>
                            navigate(
                              `${ROUTES.SEARCH}?tids=${product.templateId}`
                            )
                          }
                        >
                          <TeaserCard
                            raVariant="vertical"
                            hover={false}
                            sx={{
                              p: 2,
                              height: "450px",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>
                              <Box display="flex" justifyContent="flex-end">
                                <Cost cost={product.cost} />
                                <Box sx={{ mr: 2 }} />
                                <Features features={product.features} />
                              </Box>
                              <CardMedia
                                component="img"
                                sx={{
                                  width: "110px",
                                  height: "120px",
                                  backgroundSize: "contain",
                                  objectFit: "contain",
                                  margin: "auto",
                                }}
                                image={`${PATHS.IMG_BASE}${product.photo}`}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = defaultImage;
                                }}
                                alt={`${product.title}_photo`}
                              />
                              <TeaserCardTitle
                                sx={{
                                  fontSize: "20px",
                                  lineHeight: "28px",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  mb: 2,
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  WebkitLineClamp: "2",
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {product.title}
                              </TeaserCardTitle>
                              <TeaserCardDescription>
                                <List>
                                  {product.webFeatures.map((val) => {
                                    return (
                                      <Tooltip title={val} placement="top">
                                        <ListItem>
                                          <Typography
                                            sx={{
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                              width: "250px",
                                            }}
                                          >
                                            &bull; {val}
                                          </Typography>
                                        </ListItem>
                                      </Tooltip>
                                    );
                                  })}
                                </List>
                              </TeaserCardDescription>
                            </Box>
                            <TeaserCardActions sx={{ mb: 0 }}>
                              <Button
                                component={Link}
                                to={`${ROUTES.SEARCH}?tids=${product.templateId}`}
                                variant="outlined"
                                sx={{
                                  width: "100%",
                                }}
                              >
                                More Info
                              </Button>
                            </TeaserCardActions>
                          </TeaserCard>
                        </ButtonBase>
                      </Grid>
                    );
                  })}
                </Grid>
                <PageSelector
                  handleChangePageClick={(
                    event: React.ChangeEvent<unknown>,
                    newPage: number
                  ) => setPage(newPage)}
                  handleChangePageSelect={(event: SelectChangeEvent) =>
                    setPage(Number(event.target.value))
                  }
                  handleChangeProductsPerPage={(event: SelectChangeEvent) =>
                    setNumberOfResultsPerPage(Number(event.target.value))
                  }
                  totalCount={products?.length || 0}
                  totalPages={
                    products
                      ? Math.ceil(products.length / numberOfResultsPerPage)
                      : 0
                  }
                  pageNumber={page}
                  numberOfResultsPerPage={numberOfResultsPerPage}
                  rowsPerPageOptions={[25, 50, 100]}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default DriveFamilyPage;
