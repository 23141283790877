import * as React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ROUTES from "../../constants/Routes";

import Box from "@mui/material/Box";

import Home from "../../pages/home/Home";
import Search from "../../pages/search/Search";
import Product from "../../pages/product/Product";
import Browse from "../../pages/browse/Browse";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { setCatalog, selectCatalog } from "../../redux/slices/catalogSlice";
import CatalogApiService from "../../services/apis/CatalogApiService";
import ProductLibraryResponse from "../../interfaces/ProductLibraryResponse";
import { ToastContainer } from "react-toastify";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import toast from "../../util/toast";
import AdvisorFeedback from "../AdvisorFeedback/AdvisorFeedback";
import GlobalRouteListener from "../../util/GlobalRouteListener";
import SystemDesigners from "../../pages/systemDesigners/SystemDesigners";
import ProjectChangeDetection from "../../util/ProjectChangeDetection";
import ConfiguratorHome from "../../components/rockwellConfigurator/pages/home/Home";
import FamilySearch from "../../pages/familySearch/FamilySearch";
import { RockwellFooter, RockwellHeader } from "platform-services";
import OldAside from "../oldAside/oldAside";

const LayoutView = () => {
  const dispatch = useAppDispatch();
  const catalog = useAppSelector(selectCatalog);

  React.useEffect(
    () => {
      if (catalog?.length === 0) {
        CatalogApiService.GetProductLibrary().then(
          (response: ProductLibraryResponse) => {
            dispatch(setCatalog(response.data));
          }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <BrowserRouter>
        <RockwellHeader />
        <Box component="main">
          <Box>
            <Routes>
              <Route
                path={ROUTES.HOME}
                element={
                  <GlobalRouteListener>
                    <Home />
                  </GlobalRouteListener>
                }
              />
              <Route
                path={ROUTES.BROWSE}
                element={
                  <GlobalRouteListener>
                    <Browse />
                  </GlobalRouteListener>
                }
              />
              <Route
                path={`${ROUTES.BROWSE}/:catalogGroupId`}
                element={
                  <GlobalRouteListener>
                    <Browse />
                  </GlobalRouteListener>
                }
              />
              <Route
                path={ROUTES.SEARCH}
                element={
                  <GlobalRouteListener>
                    <Search />
                  </GlobalRouteListener>
                }
              />
              <Route
                path={ROUTES.PRODUCT}
                element={
                  <GlobalRouteListener>
                    <Product />
                  </GlobalRouteListener>
                }
              />
              <Route
                path={ROUTES.SYSTEM_DESIGNERS}
                element={
                  <GlobalRouteListener>
                    <SystemDesigners />
                  </GlobalRouteListener>
                }
              />
              <Route
                path={`${ROUTES.FAMILY_SEARCH}/:catalogGroupId`}
                element={
                  <GlobalRouteListener>
                    <FamilySearch />
                  </GlobalRouteListener>
                }
              />
              <Route
                path={ROUTES.CONFIGURATOR}
                element={
                  <GlobalRouteListener>
                    <ConfiguratorHome />
                  </GlobalRouteListener>
                }
              />
            </Routes>
          </Box>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            closeButton={
              <CloseOutlinedIcon
                sx={{ fontSize: "2rem", alignSelf: "center", color: "#757575" }}
              />
            }
            style={{
              width: "35rem",
            }}
            toastStyle={{
              color: toast.PALLETE_COLORS.BLACK,
            }}
          />
        </Box>
      </BrowserRouter>
      <AdvisorFeedback />
      <OldAside />
      <RockwellFooter />
      <ProjectChangeDetection />
    </>
  );
};

export default LayoutView;
