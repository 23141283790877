import React, { useEffect, useState } from "react";
import {
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Tooltip,
  Box,
  Typography,
} from "@mui/material";
import { AddIcon } from "@rockwell-automation-inc/ra-meatball";
import { ProjectBom } from "../../interfaces/ProposalWorks";
import { useSelector } from "react-redux";
import { selectSelProject } from "../../redux/slices/selectedProjectSlice";
import { useAppSelector } from "../../redux/hooks";
import { selectSelBOM } from "../../redux/slices/selectedBOMSlice";

interface ProductBomButtonProps {
  isLoading: boolean;
  setIsOpen: Function;
  handleQuickAdd: () => void;
  user: Oidc.User | undefined;
  setCurrentPage: Function;
}

const ProductBomButton = (props: ProductBomButtonProps) => {
  const { isLoading, setIsOpen, handleQuickAdd, user, setCurrentPage } = props;
  const bom = useAppSelector(selectSelBOM);
  const ACTIONS = {
    BOM_SELECT: "BOM_SELECT",
    BOM_ITEM_SELECT: "BOM_ITEM_SELECT",
    BOM_CREATE: "BOM_CREATE",
  };
  const selectedProject = useSelector(selectSelProject);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  const handleBomDropdownClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <>
        {user && (
          <Button onClick={handleQuickAdd} variant="outlined">
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <AddIcon
                fontSize="small"
                sx={{
                  marginRight: "10px",
                }}
              />
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Barlow !important",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              ADD TO PROJECT
            </Typography>
          </Button>
        )}
        {!user && (
          <Tooltip placement="top" arrow title={"Log In to Add To Project"}>
            <div>
              <Button disabled={true} variant="outlined">
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <AddIcon
                    fontSize="small"
                    sx={{
                      marginRight: "10px",
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontFamily: "Barlow !important",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  ADD TO PROJECT
                </Typography>
              </Button>
            </div>
          </Tooltip>
        )}
      </>

      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleBomDropdownClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem
                    onClick={() => {
                      setCurrentPage(ACTIONS.BOM_SELECT);
                      setIsOpen(true);
                    }}
                  >
                    Add to new Project
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ProductBomButton;
