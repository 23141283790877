import { Box, Button, Container, Grid, Typography } from "@mui/material";
import {
  TeaserCard,
  TeaserCardContent,
  TeaserCardSubtitle,
  TeaserCardTitle,
} from "@rockwell-automation-inc/ra-meatball";
import { useEffect, useState } from "react";
import { Accessory, SelectableAccessory } from "../../interfaces/Accessory";
import ProductSelectorModal from "../common/ProductSelectorModal/ProductSelectorModal";
import "./Accessories.scss";
import QuantityAdjusterButton from "../common/QuantityAdjusterButton/QuantityAdjusterButton";

interface AccessoriesProps {
  accessories: Accessory[] | undefined;
  setSelectedAccessories: (accessories: SelectableAccessory[]) => void;
}

const Accessories: React.FC<AccessoriesProps> = ({
  accessories,
  setSelectedAccessories,
}) => {
  const minResults = 6;

  const [selectableAccessories, setSelectableAccessories] = useState<
    SelectableAccessory[]
  >([]);
  const [productAccessories, setProductAccessories] = useState<
    SelectableAccessory[]
  >([]);
  const [numberOfResults, setNumberOfResults] = useState(minResults);

  useEffect(() => {
    if (accessories) {
      const arr: SelectableAccessory[] = accessories?.map(
        (accessory, index) => {
          return {
            id: index,
            quantity: 0,
            ...accessory,
          };
        }
      );
      setSelectableAccessories(arr);
    }
  }, [accessories]);

  useEffect(() => {
    setSelectedAccessories(productAccessories);
  }, [productAccessories, setSelectedAccessories]);

  const handleShowMore = () => {
    if (numberOfResults === minResults) {
      setNumberOfResults(accessories?.length || minResults);
    } else {
      setNumberOfResults(minResults);
    }
  };

  const handleQtyChange = (qty: number, accessory: SelectableAccessory) => {
    if (qty < 1) {
      return;
    }
    const existingAccessory = productAccessories.find(
      (i) => i.id === accessory.id
    );

    if (!existingAccessory) {
      const copyOfAccessory = { ...accessory };
      copyOfAccessory.quantity = qty;

      const copyOfAccessoriesArr: SelectableAccessory[] = [
        ...productAccessories,
        copyOfAccessory,
      ];

      setProductAccessories(copyOfAccessoriesArr);
    } else {
      const copyOfAccessory = { ...accessory };

      copyOfAccessory.quantity = qty;

      const copyOfArr: SelectableAccessory[] = productAccessories.map(
        (item) => {
          if (item.id === accessory.id) {
            return copyOfAccessory;
          }
          return item;
        }
      );
      setProductAccessories(copyOfArr);
    }
  };

  const handleRemove = (accessory: SelectableAccessory) => {
    const copyOfArr = productAccessories.filter((i) => i.id !== accessory.id);
    setProductAccessories(copyOfArr);
  };

  const handleQtyAdd = (accessory: SelectableAccessory) => {
    const existingAccessory = productAccessories.find(
      (i) => i.id === accessory.id
    );

    if (!existingAccessory) {
      const copyOfAccessory = { ...accessory };
      copyOfAccessory.quantity = copyOfAccessory.quantity + 1;

      const copyOfAccessoriesArr: SelectableAccessory[] = [
        ...productAccessories,
        copyOfAccessory,
      ];

      setProductAccessories(copyOfAccessoriesArr);
    } else {
      const existingAccessoryCopy = { ...existingAccessory };

      existingAccessoryCopy.quantity = existingAccessoryCopy.quantity + 1;

      const copyOfArr: SelectableAccessory[] = productAccessories.map(
        (item) => {
          if (item.id === accessory.id) {
            return existingAccessoryCopy;
          }
          return item;
        }
      );
      setProductAccessories(copyOfArr);
    }
    const tabClick = new CustomEvent("quantityAdded", {
      detail: {
        action: "Quantity Increased",
        properties: {
          category: "WebApp",
          label: "Quantity Increased from Accessories",
          product: accessory.catalogNumber,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleQtySubtract = (accessory: SelectableAccessory) => {
    const existingAccessory = productAccessories.find(
      (i) => i.id === accessory.id
    );
    if (!existingAccessory) {
      const copyOfAccessory = { ...accessory };
      copyOfAccessory.quantity = copyOfAccessory.quantity - 1;

      const copyOfAccessoriesArr: SelectableAccessory[] = [
        ...productAccessories,
        copyOfAccessory,
      ];

      setProductAccessories(copyOfAccessoriesArr);
    } else {
      const existingAccessoryCopy = { ...existingAccessory };

      existingAccessoryCopy.quantity = existingAccessoryCopy.quantity - 1;

      const copyOfArr: SelectableAccessory[] = productAccessories.map(
        (item) => {
          if (item.id === accessory.id) {
            return existingAccessoryCopy;
          }
          return item;
        }
      );
      setProductAccessories(copyOfArr);
    }
  };

  const getValue = (accessory: SelectableAccessory) => {
    return productAccessories.find((i) => i.id === accessory.id)?.quantity || 0;
  };

  interface AccessoryProps {
    accessory: SelectableAccessory;
  }

  const Accessory: React.FC<AccessoryProps> = ({ accessory }) => {
    const [isOpen, setIsOpen] = useState(false);

    const SelectorButtonGroup = () => {
      return (
        <>
          <Button variant="outlined" onClick={() => setIsOpen(true)}>
            Select
          </Button>
        </>
      );
    };

    const isSubtractDisabled = () => {
      const quantity = productAccessories.find(
        (i) => i.id === accessory.id
      )?.quantity;
      if (quantity && quantity <= 1) {
        return true;
      }
      if (!quantity && accessory.quantity <= 1) {
        return true;
      } else {
        return false;
      }
    };

    const getButtonGroup = () => {
      if (accessory.catalogNumber != null) {
        return (
          <QuantityAdjusterButton
            value={getValue(accessory)}
            handleQtyAdd={() => handleQtyAdd(accessory)}
            handleQtyChange={(e) => handleQtyChange(Number(e), accessory)}
            handleQtySubtract={() => handleQtySubtract(accessory)}
            subtractDisabled={isSubtractDisabled()}
          />
        );
      }
      return <SelectorButtonGroup />;
    };

    const handleSubmit = (selectableAccessories: SelectableAccessory[]) => {
      const arr: SelectableAccessory[] = [...productAccessories];
      selectableAccessories.forEach((item, index) => {
        const accessory = arr.find((i) => i.id === item.id);

        if (accessory) {
          const indexValue = arr.findIndex((i) => i.id === accessory.id);
          if (item.quantity <= 0) {
            arr.splice(indexValue, 1);
          } else {
            arr[indexValue] = item;
          }
        } else {
          arr.push(item);
        }
      });
      setProductAccessories(arr);
    };

    const renderChildGroups = () => {
      return (
        <>
          {productAccessories
            .filter(
              (i) => i.parentAccessory && i?.parentAccessory.id === accessory.id
            )
            ?.map((childGroups) => {
              return (
                <TeaserCardSubtitle fontWeight={"bold"}>
                  {childGroups.catalogNumber}
                </TeaserCardSubtitle>
              );
            })}
        </>
      );
    };

    return (
      <>
        <Grid item sm={12} key={accessory.id}>
          <TeaserCard
            hover={false}
            raVariant="horizontal"
            sx={{
              width: "100%",
              justifyContent: "space-between",
              borderRadius: 0,
              borderTop: 0,
              borderLeft: 0,
              borderRight: 0,
            }}
          >
            <Grid container>
              <Grid item lg={10} md={9} sm={8}>
                <TeaserCardContent sx={{ p: 0, pt: 3 }}>
                  {!accessory.catalogNumber && (
                    <>
                      <TeaserCardTitle>{accessory.description}</TeaserCardTitle>
                    </>
                  )}
                  {accessory.catalogNumber && (
                    <>
                      <TeaserCardTitle>
                        {accessory.catalogNumber}
                      </TeaserCardTitle>
                      <TeaserCardSubtitle>
                        {accessory.description}
                      </TeaserCardSubtitle>
                    </>
                  )}
                  <Box display={"flex"} flexDirection={"row"}>
                    {renderChildGroups()}
                  </Box>
                </TeaserCardContent>
              </Grid>
              <Grid item lg={2} md={3} sm={4}>
                <TeaserCardContent
                  sx={{
                    p: 0,
                    pt: 3,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {getButtonGroup()}
                </TeaserCardContent>
              </Grid>
            </Grid>
          </TeaserCard>
        </Grid>
        {!accessory.catalogNumber && (
          <ProductSelectorModal
            isOpen={isOpen}
            handleClose={() => {
              setIsOpen(false);
            }}
            handleSubmit={handleSubmit}
            searchCriteria={accessory.catalogNumber}
            tids={accessory.description}
            productAccessories={productAccessories}
            accessory={accessory}
          />
        )}
      </>
    );
  };

  return (
    <>
      {productAccessories.length > 0 && (
        <Container sx={{ backgroundColor: "#f2f3f4", p: 4 }}>
          <Box>
            {productAccessories
              .reduce((rows, key, index) => {
                //@ts-ignore
                return (
                  (index % 2 === 0
                    ? //@ts-ignore
                      rows.push([key])
                    : //@ts-ignore
                      rows[rows.length - 1].push(key)) && rows
                );
              }, [])
              .map((row: SelectableAccessory[], index) => {
                return (
                  <Grid
                    container
                    flexDirection="row"
                    justifyContent="space-between"
                    rowSpacing={0}
                    columnSpacing={0}
                    key={index}
                  >
                    {row.map((accessory: SelectableAccessory) => {
                      return (
                        <Grid item sm={12} key={accessory.id}>
                          <TeaserCard
                            raVariant="horizontal-outlined"
                            sx={{ width: "100%", height: "110px" }}
                            style={{ justifyContent: "space-between" }}
                            hover={false}
                          >
                            <TeaserCardContent>
                              <TeaserCardTitle style={{ fontSize: "1.2rem" }}>
                                {accessory.catalogNumber}
                              </TeaserCardTitle>
                              <TeaserCardSubtitle>
                                {accessory.description}
                              </TeaserCardSubtitle>
                            </TeaserCardContent>
                            <TeaserCardContent>
                              <TeaserCardSubtitle style={{ textAlign: "end" }}>
                                Qty: {accessory.quantity}
                              </TeaserCardSubtitle>
                              <Button
                                style={{
                                  border: "none",
                                  background: "none",
                                  padding: 0,
                                  cursor: "pointer",
                                  justifyContent: "flex-end",
                                }}
                                onClick={() => handleRemove(accessory)}
                              >
                                <Typography
                                  color="primary"
                                  sx={{ py: 0, px: 0 }}
                                >
                                  Remove
                                </Typography>
                              </Button>
                            </TeaserCardContent>
                          </TeaserCard>
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              })}
          </Box>
        </Container>
      )}

      <Grid container flexDirection="column" rowGap={0} columnGap={0}>
        {selectableAccessories &&
          selectableAccessories.length > 0 &&
          selectableAccessories
            .slice(0, numberOfResults)
            .reduce((rows, key, index) => {
              //@ts-ignore
              return (
                (index % 2 === 0
                  ? //@ts-ignore
                    rows.push([key])
                  : //@ts-ignore
                    rows[rows.length - 1].push(key)) && rows
              );
            }, [])
            .map((row: SelectableAccessory[], index) => {
              return (
                <>
                  {row.map((accessory: SelectableAccessory, index) => {
                    return <Accessory accessory={accessory}></Accessory>;
                  })}
                </>
              );
            })}
      </Grid>
      {selectableAccessories.length > 0 && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            style={{
              border: "none",
              background: "none",
              padding: 0,
              cursor: "pointer",
            }}
            sx={{ mt: 2 }}
            onClick={handleShowMore}
          >
            <Typography color="primary" sx={{ py: 2 }}>
              {numberOfResults === minResults ? "Show More" : "Show Less"}
            </Typography>
          </Button>
        </Box>
      )}
    </>
  );
};

export default Accessories;
