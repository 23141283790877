import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { IAttribute } from "../interfaces/IAttribute";
import { useEffect, useState } from "react";

interface AttributeRadioProps {
  attributes: IAttribute[];
  selectedAttributeID: string;
  updateConfigurator: Function;
  setIsContinueButtonDisabled: any;
}

const AttributeRadio = (props: AttributeRadioProps) => {
  const {
    attributes,
    selectedAttributeID,
    updateConfigurator,
    setIsContinueButtonDisabled,
  } = props;
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    getDefaultValue();
  }, [attributes]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    for (const attribute of attributes) {
      if (attribute.isSelected) {
        attribute.isSelected = false;
      }
    }
    for (const attribute of attributes) {
      if (attribute.displayText === event.target.value) {
        updateConfigurator(attributes, attribute);
        attribute.isSelected = true;
      }
    }
    setValue((event.target as HTMLInputElement).value);
    setIsContinueButtonDisabled(false);
  };

  const getDefaultValue = () => {
    let noSelect = true;
    let value = "";
    for (const attribute of attributes) {
      if (attribute.isSelected) {
        noSelect = false;
        value = attribute.displayText;
      }
    }
    setIsContinueButtonDisabled(noSelect);
    setValue(value);
  };

  return (
    <>
      <FormControl sx={{ padding: "20px" }}>
        <RadioGroup
          aria-labelledby={selectedAttributeID + "-radio-buttons-group-label"}
          name={selectedAttributeID + "radio-buttons-group"}
          value={value}
          onChange={handleChange}
        >
          {attributes.map((attribute: IAttribute) => {
            if (attribute.isShowing) {
              return (
                <FormControlLabel
                  key={attribute.id}
                  value={attribute.displayText}
                  control={<Radio />}
                  label={`${attribute.displayText} : ${attribute.id}`}
                />
              );
            }
            return null;
          })}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default AttributeRadio;
