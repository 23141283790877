import { Box, ClickAwayListener, Typography } from "@mui/material";
import "./Aside.scss";

interface AsideProps {
  isOpen: boolean;
  handleClose: () => void;
}

const Aside: React.FC<AsideProps> = ({ isOpen, handleClose }) => {
  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <Box
          component="aside"
          style={{
            transition: "all 1s ease 0s",
            position: "fixed",
            zIndex: 91,
            width: "26rem",
            boxShadow: "0 1px 5px 0 rgb(0 0 0 / 25%)",
            background: "black",
          }}
          sx={{
            pt: 2,
            pb: 1.5,
            px: 2,
          }}
          position="relative"
          top="375px"
          left="1300px"
        >
          <Box sx={{ mb: 3 }}>
            <Typography
              variant="subtitle2"
              component="li"
              color="#fff"
              lineHeight="1.5rem"
              fontSize="1rem"
              sx={{ mb: 1, ml: 1 }}
            >
              We are pleased to announce the launch of Control System Advisor!
              Control System will allow you to configure{` `}
              <b>ControlLogix L8x</b>, <b>CompactLogix 5069</b> Controllers and
              remote I/O, as well as{` `}
              <b>Flex 5000 I/O</b> with quick rule base platform level hardware
              generation.
            </Typography>
            <Typography
              variant="subtitle2"
              component="li"
              color="#fff"
              lineHeight="1.5rem"
              fontSize="1rem"
              sx={{ mb: 1, ml: 1 }}
            >
              Alerts if there are any changes, such as lead-time or price, to
              the products contained in your project item.
            </Typography>
          </Box>
          <Box>
            {/*<Button variant="primary" sx={{ width: "100%", fontSize: "1rem" }}>*/}
            {/*  Site Feedback*/}
            {/*</Button>*/}
          </Box>
        </Box>
      </ClickAwayListener>
    </>
  );
};

export default Aside;
