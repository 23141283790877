import { Box, Container } from "@mui/material";
import SearchForm from "../searchForm/SearchForm";
import {
  AdvisorNavbar,
  LoginBar,
  authStatus,
  useAuthContext,
  usePSAppSelector,
} from "platform-services";
import { useAppDispatch } from "../../redux/hooks";
import SearchFormBanner from "../searchFormBanner/SearchFormBanner";
import { setSelProject } from "../../redux/slices/selectedProjectSlice";
import { Project } from "../../interfaces/Project";
import { setSelBOM } from "../../redux/slices/selectedBOMSlice";
import { BOM } from "../../interfaces/BOM";

interface Props {
  isBanner?: boolean;
  sticky?: boolean;
}

const Masthead: React.FC<Props> = ({ isBanner = false, sticky = true }) => {
  const isDisplay = false;
  const dispatch = useAppDispatch();
  const authContext = useAuthContext();
  const authStat = usePSAppSelector(authStatus);

  const onLoginButtonClicked = () => {
    authContext.signinRedirect();
  };

  return (
    <>
      <Box
        sx={{
          borderBottom: "1px solid rgba(0,0,0,.2)",
          backgroundColor: "white",
          position: sticky ? "sticky" : "inherit",
          left: 0,
          right: 0,
          top: 0,
          zIndex: 9,
        }}
      >
        <AdvisorNavbar
          homePath={window.location.origin}
          authStatus={authStat}
          onProjectChange={(project: Project | null) =>
            dispatch(setSelProject(project))
          }
          onBOMChange={(bom: BOM | null) => {
            setSelBOM(bom);
          }}
        />
        {isBanner && (
          <Box sx={{ marginBottom: "1px" }}>
            <SearchFormBanner />
          </Box>
        )}
        <LoginBar onLoginButtonClicked={onLoginButtonClicked} />
      </Box>

      {isDisplay && (
        <Box sx={{ py: 1 }}>
          <Container sx={{ my: 3 }}>
            <SearchForm />
          </Container>
        </Box>
      )}
    </>
  );
};

export default Masthead;
