import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../../constants/Routes";

interface ConfiguratorButtonProps {
  templateID: string;
}

const ConfiguratorButton: React.FC<ConfiguratorButtonProps> = ({
  templateID,
}) => {
  const navigate = useNavigate();

  // Logged in user vs. not logged in user - To Do

  const handleNavigation = () => {
    navigate(`${ROUTES.CONFIGURATOR}?tids=${templateID}`);
    // const browsePageProducts = new CustomEvent("browseProductSelection", {
    //   detail: {
    //     action: "BrowseProduct",
    //     properties: {
    //       category: "WebApp",
    //       label: catalogGroupId,
    //     },
    //   },
    // });
    //document.getElementById("root")?.dispatchEvent(browsePageProducts);
  };

  const handleConfigureClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleNavigation();
  };

  return (
    <>
      <Button
        onClick={(event) => handleConfigureClick(event)}
        variant="outlined"
      >
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Barlow !important",
            fontWeight: "500",
            fontSize: "16px",
          }}
        >
          CONFIGURE
        </Typography>
      </Button>
    </>
  );
};

export default ConfiguratorButton;
