import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardMedia,
  Typography,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LensIcon from "@mui/icons-material/Lens";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import {
  selectCompare,
  selectParent,
  setCompare,
  setCardValue,
} from "../../redux/slices/compareSlice";
import CompareModal from "../compareModal/CompareModal";
import { useAppDispatch } from "../../redux/hooks";
import SearchProduct from "../../interfaces/SearchProduct";
import PATHS from "../../constants/Paths";
import { DetailedProduct } from "../../interfaces/DetailedProduct";
import defaultImage from "../../assets/Image_Unavailable.svg";
import { softwareSelectionCheck } from "../../util/ProductCatalog";

interface CompareBarProps {
  includeParentProduct?: boolean;
}

const CompareBar = (props: CompareBarProps) => {
  const dispatch = useAppDispatch();
  const { includeParentProduct } = props;
  const compareList = useSelector(selectCompare) || [];
  const parentComponent: DetailedProduct | undefined =
    useSelector(selectParent);
  const [parentProduct, setParentProduct] = useState<SearchProduct>();
  const numberOfCards = includeParentProduct
    ? 3 - compareList.length
    : 4 - compareList.length;
  const emptyCompareArray = Array.from(
    { length: numberOfCards },
    (_, index) => index
  );
  const [isCompareModalOpen, setIsCompareModalOpen] = useState<boolean>(false);

  const handleIconButtonClick = (product: SearchProduct) => {
    const existingCompareList = Array.isArray(compareList) ? compareList : [];
    let updatedCompareList: SearchProduct[] = [];
    updatedCompareList = existingCompareList.filter((p) => p !== product);
    dispatch(setCompare(updatedCompareList));
  };

  const handleOnClose = () => {
    dispatch(setCompare([]));
  };

  useEffect(() => {
    if (includeParentProduct) {
      dispatch(setCardValue(3));
      if (compareList.length > 0) {
        dispatch(setCompare([]));
      }
      if (parentComponent !== undefined) {
        const mappedParentProduct: SearchProduct = {
          id: parentComponent.id,
          catalogNumber: parentComponent.catalogNumber,
          listPrice: parentComponent.listPrice || -1,
          listPriceDisplay: parentComponent.displayPrice,
          listPriceCurrency: "",
          description: parentComponent.description,
          photo: parentComponent.photo,
          templateId: parentComponent.templateId,
          prodLifeCycleStatus: parentComponent.prodLifeCycleStatus,
          estimatedLeadTime: parentComponent.estimatedLeadTime,
          pricing: "",
          sortWeight: parseInt(parentComponent.weight),
          stockStatus: parentComponent.stockStatus || "",
          stockStatusDisplay: parentComponent.stockStatusDisplay || "",
          templateTitle: "",
        };
        setParentProduct(mappedParentProduct);
      }
    }
  }, [includeParentProduct, dispatch, parentComponent]);

  const CustomGridItem: React.FC<{
    product: SearchProduct | null;
    isParent: boolean;
  }> = ({ product, isParent }) => (
    <Grid item>
      <Card
        sx={{
          minWidth: 180,
          minHeight: 93,
          position: "relative",
          zIndex: 0,
          marginRight: "17px",
          overflow: "visible",
          border: isParent ? "solid 2px gray" : "",
        }}
      >
        {product && (
          <>
            {!isParent && (
              <IconButton
                sx={{
                  position: "absolute",
                  top: -5,
                  left: -5,
                  color: "gray",
                  zIndex: 1,
                }}
                onClick={() => handleIconButtonClick(product)}
              >
                <HighlightOffIcon
                  sx={{
                    position: "absolute",
                    color: "gray",
                    zIndex: 1,
                  }}
                />
                <LensIcon
                  style={{
                    position: "absolute",
                    color: "white",
                  }}
                />
              </IconButton>
            )}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CardMedia
                sx={{
                  width: "50px",
                  height: "93px",
                  backgroundSize: "contain",
                  objectFit: "contain",
                }}
                component="img"
                image={
                  product.photo
                    ? `${PATHS.IMG_BASE}${product.photo}`
                    : defaultImage
                }
                alt="component_img"
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  paddingTop: "10px",
                  paddingRight: "10px",
                }}
              >
                <Typography sx={{ fontWeight: "600" }}>
                  {product?.catalogNumber}
                </Typography>
                {product?.listPriceDisplay && (
                  <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                    {softwareSelectionCheck(
                      product.templateId,
                      product.listPrice
                    )
                      ? "N/A"
                      : product?.listPriceDisplay}
                  </Typography>
                )}
                {product?.estimatedLeadTime && (
                  <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                    {product?.estimatedLeadTime} days
                  </Typography>
                )}
              </Box>
            </Box>
          </>
        )}
      </Card>
    </Grid>
  );

  const handleCompareButtonClick = () => {
    setIsCompareModalOpen(true);
    const tabClick = new CustomEvent("compareModalClick", {
      detail: {
        action: "Compare Modal Click",
        properties: {
          category: "WebApp",
          label: "Compare Modal Launched",
          products: compareList,
          includeParentProduct: includeParentProduct,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(tabClick);
  };

  const handleCompareModalClose = () => {
    setIsCompareModalOpen(false);
  };

  return (
    <>
      {compareList.length > 0 && (
        <Box
          position="fixed"
          bottom={0}
          left={0}
          width="100vw"
          height="124px"
          bgcolor="#E1EBF4"
          textAlign="center"
          padding={2}
          zIndex={2}
          sx={{ display: "flex" }}
        >
          <Grid container sx={{ justifyContent: "end", width: "80%" }}>
            {includeParentProduct &&
              parentComponent !== undefined &&
              parentProduct !== undefined && (
                <CustomGridItem
                  product={parentProduct}
                  isParent={true}
                ></CustomGridItem>
              )}
            {compareList.map((p) => (
              <CustomGridItem
                key={p.id}
                product={p}
                isParent={false}
              ></CustomGridItem>
            ))}
            {emptyCompareArray.map((index) => (
              <CustomGridItem
                key={index}
                product={null}
                isParent={false}
              ></CustomGridItem>
            ))}
          </Grid>
          <Grid
            container
            sx={{
              width: "20%",
              justifyContent: "space-between",
              paddingRight: "15px",
            }}
          >
            <Grid item>
              <Button
                disabled={
                  includeParentProduct
                    ? compareList?.length < 1
                    : compareList?.length < 2
                }
                onClick={handleCompareButtonClick}
                sx={{ border: "none", color: "#2A79C4", fontSize: "20px" }}
              >
                Compare
              </Button>
            </Grid>
            <Grid item>
              <IconButton onClick={handleOnClose}>
                <CloseIcon sx={{ color: "#2A79C4" }}></CloseIcon>
              </IconButton>
            </Grid>
            <Grid item sx={{ paddingLeft: "25px", fontSize: "20px" }}>
              {compareList.length} of {includeParentProduct ? 3 : 4} products
              selected
            </Grid>
          </Grid>
        </Box>
      )}

      {isCompareModalOpen && (
        <CompareModal
          open={true}
          onClose={handleCompareModalClose}
          compareList={compareList}
          includeParentProduct={includeParentProduct ? true : false}
          parentProductCatalogNumber={parentProduct?.catalogNumber || ""}
        ></CompareModal>
      )}
    </>
  );
};

export default CompareBar;
