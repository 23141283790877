import { PROJECT_GUID, PROJECT_ID } from "../services/util/SessionStorage";
import { GetURLQueryVariable } from "./GetURLQueryVariable";

export const SetURLQueryVariables = () => {
  const guid = sessionStorage.getItem(PROJECT_GUID);
  const id = sessionStorage.getItem(PROJECT_ID);

  const url = new URL(window.location.href);
  if (guid && !GetURLQueryVariable(PROJECT_GUID))
    url.searchParams.set(PROJECT_GUID, guid);
  if (id && !GetURLQueryVariable(PROJECT_ID))
    url.searchParams.set(PROJECT_ID, id);
  window.history.replaceState(null, "", url);
};
