import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import storageSession from "redux-persist/lib/storage/session";

import preferencesReducer from "./slices/preferencesSlice";
import numberOfResultsPerPageReducer from "./slices/numberOfResultsPerPageSlice";
import catalogReducer from "./slices/catalogSlice";
import compareReducer from "./slices/compareSlice";
import selectedProject from "./slices/selectedProjectSlice";
import selectedBOM from "./slices/selectedBOMSlice";
import mulesoftApiResponseReducer from "./slices/mulesoftSlice";
import expireInTransform from "redux-persist-transform-expire-in";
const expireIn = 24 * 60 * 60 * 1000; // expire in 48h
const catalogExpirationKey = "catalogExpirationKey";

const rootPersistConfig = {
  key: "root",
  storage, // defaults to local storage
};

const catalogPersistConfig = {
  key: "catalog",
  storage: storageSession,
  transforms: [expireInTransform(expireIn, catalogExpirationKey, [])],
};

const numberOfResultsPerPageConfig = {
  key: "numberOfResultsPerPage",
  storage: storage,
};

const preferencesConfig = {
  key: "preferences",
  storage: storage,
};

const rootReducer = combineReducers({
  numberOfResultsPerPage: numberOfResultsPerPageReducer,
  preferences: preferencesReducer,
  catalog: catalogReducer,
  compare: compareReducer,
  project: selectedProject,
  bom: selectedBOM,
  mulesoftApiResponse: mulesoftApiResponseReducer,
});

//const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const persistedReducer = rootReducer;

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
