import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import Accordion from "@mui/material/Accordion";
import { ChevronDownIcon } from "@rockwell-automation-inc/ra-meatball";
import React, { useEffect, useState } from "react";
import { IGroup } from "../interfaces/IGroup";
import { IAttribute } from "../interfaces/IAttribute";
import { IConfiguratorFromTemplateResponse } from "../interfaces/IConfiguratorFromTemplateResponse";

interface GroupAccordionProps {
  group: IGroup;
  configFromTemplateDataFromParent: IConfiguratorFromTemplateResponse;
  handleSetSelectedAttribute: Function;
  groupArray: Function;
  groupAttributeIndex: number;
  currentGroupIndex: number;
  index: number;
}

const GroupAccordion = (props: GroupAccordionProps) => {
  const {
    group,
    configFromTemplateDataFromParent,
    handleSetSelectedAttribute,
    groupAttributeIndex,
    currentGroupIndex,
    index,
    groupArray,
  } = props;
  const [configData, setConfigData] =
    useState<IConfiguratorFromTemplateResponse>();
  const [expanded, setExpanded] = useState<string | false>(
    currentGroupIndex === index ? group.displayText : false
  );
  const [groupAttributes, setGroupAttributes] = useState<IAttribute[]>();

  useEffect(() => {
    setConfigData(configFromTemplateDataFromParent);
  }, [configFromTemplateDataFromParent]);

  useEffect(() => {
    setExpanded(currentGroupIndex === index ? group.displayText : false);
  }, [currentGroupIndex, index, group.displayText]);

  useEffect(() => {
    getGroupAttributeData(group.attributes);
  }, [configData]);

  useEffect(() => {
    if (currentGroupIndex === index && groupAttributes) {
      setAttributeBorder(groupAttributes[groupAttributeIndex]);
      handleSetSelectedAttribute(
        groupAttributes[groupAttributeIndex],
        groupAttributeIndex,
        index
      );
    }
  }, [groupAttributes, groupAttributeIndex]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const getGroupAttributeData = (groupAttributes: string[]) => {
    console.log("getGroupAttributesData triggered", index);
    const attributesForGroup: IAttribute[] = [];
    groupAttributes.forEach((attribute: string) => {
      if (configData?.attributes) {
        for (let i = 0; i < configData.attributes.length; i++) {
          if (
            attribute === configData.attributes[i].id &&
            configData.attributes[i].hasSelectables &&
            configData.attributes[i].displayText
              .toLowerCase()
              .includes("accessor") === false
          ) {
            console.log("getGroupAttributesData", attribute);
            attributesForGroup.push(configData.attributes[i]);
          }
          //FOR TESTING ONLY
          else if (
            attribute === configData.attributes[i].id &&
            configData.attributes[i].hasSelectables === false
          ) {
            console.log(
              `getGroupAttributesData no selectable ${index}`,
              configData.attributes[i]
            );
          }
        }
      }
    });
    groupArray(index, attributesForGroup);
    setGroupAttributes(attributesForGroup);
  };

  const hasAttributeSelected = (attribute: IAttribute) => {
    if (attribute.range && configData?.attributes) {
      const attributeRange = attribute.range.split(",");
      for (const range of attributeRange) {
        for (const configAttribute of configData.attributes) {
          if (range === configAttribute.id && configAttribute.isSelected) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const handleAttributeClick = (
    attribute: IAttribute,
    groupAttributeIndex: number
  ) => {
    setAttributeBorder(attribute);
    handleSetSelectedAttribute(attribute, groupAttributeIndex, index);
  };

  const setAttributeBorder = (attribute: IAttribute) => {
    const elements = document.querySelectorAll(".accordion_attribute");
    const elementsArray = Array.from(elements) as HTMLElement[];
    elementsArray.forEach((element) => {
      if (element.id === attribute.id) {
        element.style.border = "solid 2px #003E7E";
        element.style.borderRadius = "4px";
      } else {
        element.style.border = "";
        element.style.borderRadius = "";
      }
    });
  };

  return (
    <>
      <p>length : {groupAttributes?.length}</p>
      <p>cid : {configData?.cid}</p>
      <Accordion
        expanded={expanded === group.displayText}
        onChange={handleChange(group.displayText)}
        sx={{
          width: "100%",
        }}
      >
        <AccordionSummary
          sx={{ paddingLeft: "0px" }}
          expandIcon={
            <ChevronDownIcon
              sx={{
                width: "18px",
                height: "18px",
              }}
            />
          }
          aria-controls={group.displayText + "-content"}
          id={group.displayText + "-header"}
        >
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "28px",
              fontWeight: "500",
              fontFamily: "Barlow !important",
            }}
          >
            {group.displayText}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {groupAttributes?.map((attribute: IAttribute, index: any) => {
            return (
              <Box
                onClick={() => handleAttributeClick(attribute, index)}
                key={attribute.id}
                id={attribute.id}
                className="accordion_attribute"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                  cursor: "pointer",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Barlow !important",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "24px",
                  }}
                >
                  {attribute.displayText} : {attribute.id}
                </Typography>
                {hasAttributeSelected(attribute) ? (
                  <CheckCircleOutlineOutlinedIcon
                    sx={{ width: "24px", height: "24px", color: "#12850A" }}
                  ></CheckCircleOutlineOutlinedIcon>
                ) : (
                  <WarningAmberOutlinedIcon
                    sx={{ width: "24px", height: "24px", color: "#FFCC00" }}
                  ></WarningAmberOutlinedIcon>
                )}
              </Box>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default GroupAccordion;
