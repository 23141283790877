import {
  Box,
  Button,
  ButtonBase,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import {
  CloseIcon,
  LoadingIndicator,
} from "@rockwell-automation-inc/ra-meatball";
import ProductRealTimeData from "../productRealTimeData/ProductRealTimeData";
import ConfiguratorSummary from "../configurationSummary/ConfigurationSummary";
import { useEffect, useState } from "react";
import { IConfiguratorFromTemplateResponse } from "../interfaces/IConfiguratorFromTemplateResponse";

interface ConfiguratorLayoutProps {
  handleConfiguratorDrawerClose: Function;
  isValidConfiguration: boolean;
  isLoading: boolean;
  configFromTemplateDataFromParent: IConfiguratorFromTemplateResponse;
  updateConfigurator: Function;
  resetConfiguration: Function;
}

const ConfiguratorLayout = (props: ConfiguratorLayoutProps) => {
  const {
    handleConfiguratorDrawerClose,
    isValidConfiguration,
    configFromTemplateDataFromParent,
    updateConfigurator,
    resetConfiguration,
    isLoading,
  } = props;

  return (
    <>
      <Box
        sx={{
          width: "60vw",
          height: "95vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Barlow !important",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "28px",
            }}
          >
            Configurator
          </Typography>
          <ButtonBase onClick={() => handleConfiguratorDrawerClose()}>
            <CloseIcon />
          </ButtonBase>
        </DialogTitle>

        <ProductRealTimeData
          configFromTemplateDataFromParent={configFromTemplateDataFromParent}
          resetConfiguration={resetConfiguration}
        ></ProductRealTimeData>
        {/* {isLoading && <LoadingIndicator></LoadingIndicator>} */}
        <ConfiguratorSummary
          configFromTemplateDataFromParent={configFromTemplateDataFromParent}
          updateConfigurator={updateConfigurator}
          handleConfiguratorDrawerClose={handleConfiguratorDrawerClose}
          isValidConfiguration={isValidConfiguration}
        ></ConfiguratorSummary>
      </Box>
    </>
  );
};

export default ConfiguratorLayout;
