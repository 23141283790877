import { useEffect, useState } from "react";
import AttributeValueSearch from "../attributeValueSearch/AttributeValueSearch";
import { Box, Typography } from "@mui/material";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { IConfiguratorFromTemplateResponse } from "../interfaces/IConfiguratorFromTemplateResponse";
import { IAttribute } from "../interfaces/IAttribute";
import AttributeCard from "./AttributeCard";
import AttributeRadio from "./AttributeRadio";

interface AttributeSelectorProps {
  selectedAttribute: IAttribute;
  configFromTemplateDataFromParent: IConfiguratorFromTemplateResponse;
  updateConfigurator: Function;
  setIsContinueButtonDisabled: any;
}

const AttributeSelector = (props: AttributeSelectorProps) => {
  const {
    selectedAttribute,
    configFromTemplateDataFromParent,
    updateConfigurator,
    setIsContinueButtonDisabled,
  } = props;
  const [attribute, setAttribute] = useState<IAttribute>();
  const [selectorArray, setSelectorArray] = useState<IAttribute[]>();
  const [configData, setConfigData] =
    useState<IConfiguratorFromTemplateResponse>();
  const [selectorHasImage, setSelectorHasImage] = useState<boolean>(false);
  const [searchHasResults, setSearchHasResults] = useState<boolean>(true);
  const createArrayFromAttributeString = (attributesString: string) => {
    const attributeStringArray: string[] = attributesString.split(",");
    const attributeChildArray: IAttribute[] = [];
    attributeStringArray.forEach((atr: string) => {
      if (configFromTemplateDataFromParent?.attributes) {
        for (
          let i = 0;
          i < configFromTemplateDataFromParent?.attributes?.length;
          i++
        ) {
          if (atr === configFromTemplateDataFromParent.attributes[i].id) {
            configFromTemplateDataFromParent.attributes[i].isShowing = true;
            attributeChildArray.push(
              configFromTemplateDataFromParent.attributes[i]
            );
            break;
          }
        }
      }
    });
    setSelectorArray(attributeChildArray);
  };

  const hasAttributeSelected = () => {
    if (selectorArray?.length) {
      for (const selector of selectorArray) {
        if (selector.isSelected) {
          return true;
        }
      }
    } else {
      return false;
    }
  };
  const handleCardSelect = (atr: IAttribute) => {
    const updatedArray = selectorArray?.map((selector) => {
      if (atr.id === selector.id && !atr.isSelected) {
        return { ...selector, isSelected: true };
      }
      return { ...selector, isSelected: false };
    });
    setSelectorArray(updatedArray);
  };

  const resetIsShowing = () => {
    const updatedArray = selectorArray?.map((selector) => {
      return { ...selector, isShowing: true };
    });
    setSearchHasResults(true);
    setSelectorArray(updatedArray);
  };

  const normalize = (value: string) => {
    let removeWhiteSpace = value.split(" ").join("");
    return removeWhiteSpace.toLowerCase();
  };

  const handleSearchSubmit = (query: string) => {
    let hasResults = false;
    const normalizedQuery = normalize(query);
    const updatedArray = selectorArray?.map((selector) => {
      if (normalize(selector.displayText).includes(normalizedQuery)) {
        hasResults = true;
        return { ...selector, isShowing: true };
      }
      return { ...selector, isShowing: false };
    });
    setSearchHasResults(hasResults);
    setSelectorArray(updatedArray);
  };

  useEffect(() => {
    if (selectorArray) {
      let hasImage = false;
      for (const attribute of selectorArray) {
        if (attribute.thumbnail) {
          hasImage = true;
          break;
        }
      }
      setSelectorHasImage(hasImage);
    }
  }, [selectorArray]);

  useEffect(() => {
    setConfigData(configFromTemplateDataFromParent);
  }, []);

  useEffect(() => {
    createArrayFromAttributeString(selectedAttribute.range);
    setAttribute(selectedAttribute);
  }, [selectedAttribute]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Barlow !important",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "24px",
          }}
        >
          {attribute?.displayText}
        </Typography>
        {hasAttributeSelected() ? (
          <CheckCircleOutlineOutlinedIcon
            sx={{ width: "24px", height: "24px", color: "#12850A" }}
          ></CheckCircleOutlineOutlinedIcon>
        ) : (
          <WarningAmberOutlinedIcon
            sx={{ width: "24px", height: "24px", color: "#FFCC00" }}
          ></WarningAmberOutlinedIcon>
        )}
      </Box>
      <AttributeValueSearch
        handleAttributeSearchSubmit={handleSearchSubmit}
        resetIsShowing={resetIsShowing}
      ></AttributeValueSearch>
      <Box
        sx={{
          //border: "solid 1px red",
          marginTop: "10px",
          marginBottom: "10px",
          paddingTop: "5px",
          paddingBottom: "5px",
          height: "85%",
          overflow: "auto",
        }}
      >
        {selectedAttribute.range && selectorArray && searchHasResults ? (
          <>
            {selectorHasImage ? (
              <>
                {selectorArray.map((attribute: IAttribute) => {
                  return (
                    <AttributeCard
                      key={attribute.id}
                      attribute={attribute}
                      handleCardSelect={handleCardSelect}
                    ></AttributeCard>
                  );
                })}
              </>
            ) : (
              <>
                <AttributeRadio
                  attributes={selectorArray}
                  selectedAttributeID={selectedAttribute.id}
                  updateConfigurator={updateConfigurator}
                  setIsContinueButtonDisabled={setIsContinueButtonDisabled}
                ></AttributeRadio>
              </>
            )}
          </>
        ) : (
          <>
            <Typography sx={{ paddingLeft: "20px" }}>
              No results found
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

export default AttributeSelector;
