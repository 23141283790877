import {
  InfoOutlined,
  HighlightOffOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import {
  ClearWaitingQueueParams,
  Id,
  toast as toastr,
  ToastContent,
  ToastOptions,
  TypeOptions,
  UpdateOptions,
} from "react-toastify";
import { OnChangeCallback } from "react-toastify/dist/core";
import ToastError from "./ToastError";

type Colors =
  | "#008700"
  | "#EDF7ED"
  | "#2A79C4"
  | "#FDB81E"
  | "#DF3704"
  | "#000000"
  | "#FFFFFF"
  | "#2D2D2D"
  | "#E5F6FD"
  | "#FFDDDD"
  | "#FFF8C4";

interface PalleteOptions {
  PROGRESS: Colors;
  ICON: Colors;
  TEXT: Colors;
  BORDER: Colors;
  BACKGROUND: Colors;
}

interface PalletLightDark {
  LIGHT: Colors;
  DARK: Colors;
  OPACITY: Colors;
}

class toast {
  PALLETE_COLORS: {
    RED: PalletLightDark;
    GREEN: PalletLightDark;
    YELLOW: PalletLightDark;
    BLUE: PalletLightDark;
    BLACK: Colors;
    WHITE: Colors;
    DARKTEXT: Colors;
  };

  PALLETE: {
    INFO: PalleteOptions;
    SUCCESS: PalleteOptions;
    WARNING: PalleteOptions;
    ERROR: PalleteOptions;
    DEFAULT: PalleteOptions;
  };

  POSITION: {
    TOP_LEFT: import("react-toastify/dist/types").ToastPosition;
    TOP_RIGHT: import("react-toastify/dist/types").ToastPosition;
    TOP_CENTER: import("react-toastify/dist/types").ToastPosition;
    BOTTOM_LEFT: import("react-toastify/dist/types").ToastPosition;
    BOTTOM_RIGHT: import("react-toastify/dist/types").ToastPosition;
    BOTTOM_CENTER: import("react-toastify/dist/types").ToastPosition;
  };

  TYPE: {
    INFO: TypeOptions;
    SUCCESS: TypeOptions;
    WARNING: TypeOptions;
    ERROR: TypeOptions;
    DEFAULT: TypeOptions;
  };

  constructor() {
    this.POSITION = {
      TOP_LEFT: "top-left",
      TOP_RIGHT: "top-right",
      TOP_CENTER: "top-center",
      BOTTOM_LEFT: "bottom-left",
      BOTTOM_RIGHT: "bottom-right",
      BOTTOM_CENTER: "bottom-center",
    };

    this.TYPE = {
      INFO: "info",
      SUCCESS: "success",
      WARNING: "warning",
      ERROR: "error",
      DEFAULT: "default",
    };

    this.PALLETE_COLORS = {
      RED: {
        LIGHT: "#DF3704",
        DARK: "#DF3704",
        OPACITY: "#FFDDDD",
      },
      GREEN: {
        LIGHT: "#008700",
        DARK: "#008700",
        OPACITY: "#EDF7ED",
      },
      YELLOW: {
        LIGHT: "#FDB81E",
        DARK: "#FDB81E",
        OPACITY: "#FFF8C4",
      },
      BLUE: {
        LIGHT: "#2A79C4",
        DARK: "#2A79C4",
        OPACITY: "#E5F6FD",
      },
      BLACK: "#000000",
      WHITE: "#FFFFFF",
      DARKTEXT: "#2D2D2D",
    };

    this.PALLETE = {
      INFO: {
        PROGRESS: this.PALLETE_COLORS.BLUE.DARK,
        ICON: this.PALLETE_COLORS.DARKTEXT,
        TEXT: this.PALLETE_COLORS.DARKTEXT,
        BORDER: this.PALLETE_COLORS.BLUE.DARK,
        BACKGROUND: this.PALLETE_COLORS.BLUE.OPACITY,
      },
      SUCCESS: {
        PROGRESS: this.PALLETE_COLORS.GREEN.DARK,
        ICON: this.PALLETE_COLORS.DARKTEXT,
        TEXT: this.PALLETE_COLORS.DARKTEXT,
        BORDER: this.PALLETE_COLORS.GREEN.DARK,
        BACKGROUND: this.PALLETE_COLORS.GREEN.OPACITY,
      },
      WARNING: {
        PROGRESS: this.PALLETE_COLORS.YELLOW.DARK,
        ICON: this.PALLETE_COLORS.DARKTEXT,
        TEXT: this.PALLETE_COLORS.DARKTEXT,
        BORDER: this.PALLETE_COLORS.YELLOW.DARK,
        BACKGROUND: this.PALLETE_COLORS.YELLOW.OPACITY,
      },
      ERROR: {
        PROGRESS: this.PALLETE_COLORS.RED.DARK,
        ICON: this.PALLETE_COLORS.DARKTEXT,
        TEXT: this.PALLETE_COLORS.DARKTEXT,
        BORDER: this.PALLETE_COLORS.RED.DARK,
        BACKGROUND: this.PALLETE_COLORS.RED.OPACITY,
      },
      DEFAULT: {
        PROGRESS: this.PALLETE_COLORS.BLUE.DARK,
        ICON: this.PALLETE_COLORS.DARKTEXT,
        TEXT: this.PALLETE_COLORS.DARKTEXT,
        BORDER: this.PALLETE_COLORS.BLUE.DARK,
        BACKGROUND: this.PALLETE_COLORS.BLUE.OPACITY,
      },
    };
  }

  success<TData = unknown>(
    content: ToastContent<TData>,
    options?: ToastOptions<{}> | undefined
  ): Id {
    return toastr.success(content, {
      ...options,

      hideProgressBar: true,
      progressStyle: { background: this.PALLETE.SUCCESS.PROGRESS },
      icon: <CheckIcon />,
      closeButton: <CloseIcon />,
      style: {
        background: this.PALLETE.SUCCESS.BACKGROUND,
        border: "1px solid" + this.PALLETE.SUCCESS.BORDER,
        alignItems: "center",
      },
    });
  }

  info<TData = unknown>(
    content: ToastContent<TData>,
    options?: ToastOptions<{}> | undefined
  ): Id {
    return toastr.info(content, {
      ...options,
      hideProgressBar: true,
      progressStyle: { background: this.PALLETE.INFO.PROGRESS },
      icon: <InfoOutlined />,
      closeButton: <CloseIcon />,
      style: {
        background: this.PALLETE.INFO.BACKGROUND,
        border: "1px solid" + this.PALLETE.INFO.BORDER,
        alignItems: "center",
      },
    });
  }

  warn<TData = unknown>(
    content: ToastContent<TData>,
    options?: ToastOptions<{}> | undefined
  ): Id {
    return toastr.warn(content, {
      ...options,
      hideProgressBar: true,
      progressStyle: { background: this.PALLETE.WARNING.PROGRESS },
      icon: <WarningAmberOutlined />,
      closeButton: <CloseIcon />,
      style: {
        background: this.PALLETE.WARNING.BACKGROUND,
        border: "1px solid" + this.PALLETE.WARNING.BORDER,
        alignItems: "center",
      },
    });
  }

  loading(text: string | React.ReactNode): Id {
    return toastr.info(text, {
      hideProgressBar: true,
      isLoading: true,
    });
  }

  loadedSuccess(id: Id, text: string | React.ReactNode) {
    toastr.update(id, {
      render: text,
      type: this.TYPE.SUCCESS,
      autoClose: 5000,
      closeOnClick: true,
      hideProgressBar: true,
      isLoading: false,
      progressStyle: { background: this.PALLETE.SUCCESS.PROGRESS },
      icon: <CheckIcon />,
      closeButton: <CloseIcon />,
      style: {
        background: this.PALLETE.SUCCESS.BACKGROUND,
        border: "1px solid" + this.PALLETE.SUCCESS.BORDER,
        alignItems: "center",
      },
    });
  }

  loaded(id: Id, text: string | React.ReactNode) {
    toastr.update(id, {
      render: text,
      type: this.TYPE.SUCCESS,
      autoClose: 5000,
      closeOnClick: true,
      closeButton: true,
      hideProgressBar: true,
      isLoading: false,
      progressStyle: { background: this.PALLETE.SUCCESS.PROGRESS },
      icon: <CheckIcon />,
    });
  }

  copiedToClipboard(): Id {
    return toastr.info(
      <Box>
        Copied to clipboard! <br /> Try pasting to add another line item
      </Box>,
      {
        progressStyle: { background: this.PALLETE.INFO.PROGRESS },
        icon: <InfoOutlined sx={{ color: this.PALLETE.INFO.ICON, mr: 2 }} />,
      }
    );
  }

  error(
    text: string | React.ReactNode,
    options?: ToastOptions<{}> | null,
    onUndo?: () => void,
    onRetry?: () => void
  ): Id {
    const errorToastOptions: ToastOptions<{}> = {
      autoClose: false,
      closeOnClick: false,
      hideProgressBar: true,
      progressStyle: { background: this.PALLETE.ERROR.PROGRESS },
      icon: <HighlightOffOutlined />,
      closeButton: <CloseIcon />,
      style: {
        background: this.PALLETE.ERROR.BACKGROUND,
        border: "1px solid" + this.PALLETE.ERROR.BORDER,
        alignItems: "center",
      },
    };
    return toastr.error(
      <ToastError text={text} onUndo={onUndo} onRetry={onRetry} />,
      options || text
        ? {
            ...options,
            hideProgressBar: true,
            icon: <HighlightOffOutlined />,
            closeButton: <CloseIcon />,
            style: {
              background: this.PALLETE.ERROR.BACKGROUND,
              border: "1px solid" + this.PALLETE.ERROR.BORDER,
              alignItems: "center",
            },
          }
        : errorToastOptions
    );
  }

  dark(content: ToastContent, options?: ToastOptions<{}> | undefined): void {
    toastr.dark(content, {
      ...options,
    });
  }

  dismiss(id?: Id | undefined): void {
    toastr.dismiss(id);
  }

  clearWaitingQueue(params?: ClearWaitingQueueParams): void {
    toastr.clearWaitingQueue(params);
  }

  isActive(id: Id): boolean {
    return toastr.isActive(id);
  }

  update<TData = unknown>(id: Id, options?: UpdateOptions<TData>): void {
    return toastr.update(id, options);
  }

  done(id: Id): void {
    toastr.done(id);
  }

  onChange(callback: OnChangeCallback): () => void {
    return toastr.onChange(callback);
  }
}

//eslint-disable-next-line import/no-anonymous-default-export
export default new toast();
