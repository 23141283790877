import {
  Box,
  CardMedia,
  ButtonBase,
  Checkbox,
  Tooltip,
  IconButton,
  Avatar,
  Grid,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import "./ProductCard.scss";
import ROUTES from "../../constants/Routes";
import SearchProduct from "../../interfaces/SearchProduct";
import { useSelector } from "react-redux";
import {
  selectCompare,
  setCompare,
  selectCardValue,
} from "../../redux/slices/compareSlice";
import { useAppDispatch } from "../../redux/hooks";
import PATHS from "../../constants/Paths";
import {
  TeaserCard,
  TeaserCardContent,
  TeaserCardDescription,
  TeaserCardSubtitle,
  TeaserCardTitle,
  InformationCircleIcon,
} from "@rockwell-automation-inc/ra-meatball";
import LayoutType from "../../constants/LayoutType";
import ProductCardBomButton from "../productBomButton/ProductCardBomButton";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../assets/Image_Unavailable.svg";
import { softwareSelectionCheck } from "../../util/ProductCatalog";
import { selectUser, usePSAppSelector } from "platform-services";

interface ProductCardProps {
  product: SearchProduct;
  handleSelectedProduct?: () => void;
  currentLayout: string;
  searchCriteria?: string;
  groupId?: string;
  pageNumber: number;
}
const ProductCard = (props: ProductCardProps) => {
  const dispatch = useAppDispatch();
  const { product, currentLayout, searchCriteria, groupId, pageNumber } = props;
  const user = usePSAppSelector(selectUser);
  const cardValue = useSelector(selectCardValue);
  const compareList = useSelector(selectCompare);
  const [checked, setChecked] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    if (compareList !== undefined) {
      let checked = false;
      for (let i = 0; i < compareList.length; i++) {
        if (compareList[i].id === product.id) {
          checked = true;
          setChecked(true);
          break;
        }
      }
      if (!checked) {
        setChecked(false);
      }
    }
  }, [pageNumber, compareList, product.id]);

  useEffect(() => {
    if (compareList !== undefined) {
      if (compareList.length === cardValue) {
        let hidden = "hidden";
        for (let i = 0; i < compareList.length; i++) {
          if (compareList[i].id === product.id) {
            hidden = "";
            setIsHidden("");
            break;
          }
        }
        if (hidden.length > 0) {
          setIsHidden("hidden");
        }
      } else {
        setIsHidden("");
      }
    }
  }, [pageNumber, compareList, product.id, checked]);

  const getStockStatusDisplay = () => {
    if (!product?.stockStatusDisplay) return;

    const stockStatusSplit = product?.stockStatusDisplay.split(":");

    if (stockStatusSplit.length > 1) {
      return (
        <>
          <strong>{stockStatusSplit[0]}: </strong>
          {stockStatusSplit[1]}
        </>
      );
    } else {
      return <strong>{product.stockStatusDisplay}</strong>;
    }
  };

  const getPreferredProductText = () => {
    if (!product?.preferredProduct) return;
    if (product?.preferredProduct) {
      return <strong>Best Selling</strong>;
    }
  };

  const getCardStatusText = () => {
    if (!product?.stockStatusDisplay) return;

    if (product?.stockStatusDisplay.includes(":")) return;
    return <strong>In Stock</strong>;
  };

  const loginDisplay = () => {
    if (!user)
      return (
        <Grid container columnGap={1} alignItems="center">
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Barlow !important",
                fontWeight: "500",
                fontSize: "16px",
                color: "black",
              }}
              textTransform="none"
            >
              Login to see more details
            </Typography>
          </Grid>
        </Grid>
      );
  };

  const handleCheckBoxClick = (e: any, product: SearchProduct) => {
    setChecked(!checked);
    const existingCompareList = Array.isArray(compareList) ? compareList : [];
    let updatedCompareList: SearchProduct[] = [];

    if (!checked) {
      updatedCompareList = [...existingCompareList, product];
    } else {
      updatedCompareList = existingCompareList.filter(
        (p) => p.id !== product.id
      );
    }

    dispatch(setCompare(updatedCompareList));
    e.stopPropagation();
  };

  const handleNavigation = (catalogNumber: string) => {
    let route = `${ROUTES.PRODUCT}?catalogNumber=${product.catalogNumber}&type=RaiseExactMatchCatalogNumber`;
    if (searchCriteria && groupId) {
      route += `&s=${searchCriteria}&g=${groupId}`;
    }
    navigate(route);
    const productCardSelection = new CustomEvent("productCardSelection", {
      detail: {
        action: "ProductSelection",
        properties: {
          category: "WebApp",
          label: product.catalogNumber,
        },
      },
    });
    document.getElementById("root")?.dispatchEvent(productCardSelection);
  };

  const PriceInformationRow = () => {
    return (
      <Grid container columnGap={1} alignItems="center">
        <Grid
          item
          sx={{
            fontFamily: "BarlowRegular",
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "24px",
            mt: "2px",
          }}
        >
          {softwareSelectionCheck(product.templateId, product.listPrice)
            ? "N/A"
            : product.listPriceDisplay}
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip
            title={
              softwareSelectionCheck(product.templateId, product.listPrice)
                ? `Visit Software Portal for pricing.`
                : `List Price (${product.listPriceCurrency})`
            }
            placement="right-start"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              size="small"
              aria-label="price_information"
              sx={{
                px: 0,
                py: 0,
              }}
            >
              <InformationCircleIcon sx={{ width: "16px", height: "16px" }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  if (currentLayout === LayoutType.Grid) {
    return (
      // new card Grid view
      <ButtonBase
        onClick={() => handleNavigation(product.catalogNumber)}
        sx={{
          display: "block",
          width: "100%",
        }}
      >
        <TeaserCard
          raVariant="vertical"
          hover={false}
          sx={{
            fontFamily: "inherit",
            //widht: "290px",
            height: "515px",
            borderRadius: "4px",
            justifyContent: "center",
            gap: "8px",
            border: "0px 0px 4px 0px",
            padding: "20px",
            boxShadow: "0px 1px 5px 0px #00000040",
            ":hover": {
              boxShadow: "2px 2px 10px 2px #00000040",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TeaserCardSubtitle
              sx={{
                minHeight: "22px",
                backgroundColor: "#F9C20A",
                fontWeight: "500",
                padding: "0px 8px 0px 8px",
                border: "1px solid #F9C20A",
                borderRadius: "30px",
                fontSize: "14px",
                lineHeight: "22px",
                color: "#ffffff",
                visibility:
                  user && getPreferredProductText() ? "visible" : "hidden",
              }}
            >
              {getPreferredProductText()}
            </TeaserCardSubtitle>
            <TeaserCardSubtitle
              sx={{
                minHeight: "22px",
                backgroundColor: "#edf7ed",
                fontWeight: "500",
                padding: "0px 8px 0px 8px",
                border: "1px solid #2e7d32",
                borderRadius: "30px",
                fontSize: "14px",
                lineHeight: "22px",
                color: "#2e7d32",
                visibility: user && getCardStatusText() ? "visible" : "hidden",
              }}
            >
              {getCardStatusText()}
            </TeaserCardSubtitle>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {product.photo ? (
              <CardMedia
                sx={{
                  width: "110px",
                  height: "120px",
                  backgroundSize: "contain",
                  objectFit: "contain",
                }}
                component="img"
                image={`${PATHS.IMG_BASE}${product.photo}`}
                alt="component_img"
              />
            ) : (
              <Avatar
                variant="square"
                src={defaultImage}
                sx={{
                  height: "120px",
                  width: "120px",
                  bgcolor: "transparent",
                }}
              />
            )}
          </Box>

          <TeaserCardSubtitle
            sx={{
              fontSize: "16px",
              lineHeight: "22px",
              fontWeight: 400,
              textAlign: "center",
              marginBottom: "0px",
            }}
          >
            {product.catalogNumber}
          </TeaserCardSubtitle>
          <TeaserCardTitle
            sx={{
              fontSize: "20px",
              lineHeight: "28px",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "0px",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {product.templateTitle}
          </TeaserCardTitle>
          <ButtonBase
            sx={{
              display: "flex",
              justifyContent: "start",
              py: "4px",
              visibility: isHidden,
            }}
            onClick={(event) => handleCheckBoxClick(event, product)}
          >
            <Checkbox
              checked={checked}
              sx={{ padding: 0, marginRight: "10px" }}
            />
            <Typography
              sx={{
                fontFamily: "Barlow !important",
                fontWeight: "500",
                fontSize: "16px",
                color: "#2A79C4",
              }}
            >
              COMPARE
            </Typography>
          </ButtonBase>
          {loginDisplay()}
          {user && <PriceInformationRow />}

          {user && (
            <TeaserCardDescription
              sx={{
                m: 0,
                fontWeight: 500,
                lineHeight: "18px",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              {getStockStatusDisplay()}
            </TeaserCardDescription>
          )}
          {product.description && (
            <TeaserCardDescription
              sx={{
                color: "#43434D",
                fontSize: "14px",
                fontWeight: "400",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: "4",
                WebkitBoxOrient: "vertical",
                textAlign: "left",
                lineHeight: "18px",
              }}
            >
              {product.description}
            </TeaserCardDescription>
          )}
          <ProductCardBomButton product={product} />
        </TeaserCard>
      </ButtonBase>
    );
  } else {
    return (
      // new card  List view
      <ButtonBase
        onClick={() => handleNavigation(product.catalogNumber)}
        sx={{
          display: "block",
          width: "100%",
        }}
      >
        <TeaserCard
          raVariant="vertical"
          sx={{
            fontFamily: "inherit",
            height: "245px",
            borderRadius: "4px",
            justifyContent: "center",
            gap: "8px",
            border: "0px 0px 4px 0px",
            padding: "20px 19px",
            boxShadow: "0px 1px 5px 0px #00000040",
            ".MuiCardContent-root:last-child": {
              pb: 0,
            },
            ":hover": {
              boxShadow: "2px 2px 10px 2px #00000040",
            },
          }}
          hover={false}
        >
          <Box
            sx={{
              top: "22px",
              right: "20px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TeaserCardSubtitle
              sx={{
                minHeight: "22px",
                backgroundColor: "#F9C20A",
                fontWeight: "500",
                padding: "0px 8px 0px 8px",
                border: "1px solid #F9C20A",
                borderRadius: "30px",
                fontSize: "14px",
                lineHeight: "22px",
                color: "#ffffff",
                visibility:
                  user && getPreferredProductText() ? "visible" : "hidden",
              }}
            >
              {getPreferredProductText()}
            </TeaserCardSubtitle>
            <TeaserCardSubtitle
              sx={{
                minHeight: "22px",
                backgroundColor: "#edf7ed",
                fontWeight: "500",
                padding: "0px 8px 0px 8px",
                border: "1px solid #2e7d32",
                borderRadius: "30px",
                fontSize: "14px",
                lineHeight: "22px",
                color: "#2e7d32",
                visibility: user && getCardStatusText() ? "visible" : "hidden",
              }}
            >
              {getCardStatusText()}
            </TeaserCardSubtitle>
          </Box>
          <TeaserCardContent
            sx={{
              padding: "0px",
              margin: 0,
              display: "flex",
              gap: 0.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: "120px",
                minWidth: "110px",
              }}
            >
              {product.photo ? (
                <CardMedia
                  sx={{
                    width: "110px",
                    height: "120px",
                    backgroundSize: "contain",
                    objectFit: "contain",
                  }}
                  component="img"
                  image={`${PATHS.IMG_BASE}${product.photo}`}
                  alt="component_img"
                />
              ) : (
                <Avatar
                  variant="square"
                  src={defaultImage}
                  sx={{
                    height: "120px",
                    minWidth: "110px",
                    bgcolor: "transparent",
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "3px",
                justifyContent: "flex-start",
              }}
            >
              <TeaserCardSubtitle
                sx={{
                  fontSize: "16px",
                  lineHeight: "22px",
                  fontWeight: 400,
                  textAlign: "start",
                  marginBottom: "0px",
                  color: "#2D2D2D",
                }}
              >
                {product.catalogNumber}
              </TeaserCardSubtitle>

              <ButtonBase
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  visibility: isHidden,
                }}
                onClick={(event) => handleCheckBoxClick(event, product)}
              >
                <Checkbox
                  checked={checked}
                  sx={{ padding: 0, marginRight: "10px" }}
                />
                <Typography
                  sx={{
                    fontFamily: "Barlow !important",
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#2A79C4",
                  }}
                >
                  COMPARE
                </Typography>
              </ButtonBase>
              {loginDisplay()}
              {user && <PriceInformationRow />}

              {user && (
                <TeaserCardDescription
                  sx={{
                    m: 0,
                    fontWeight: 500,
                    lineHeight: "18px",
                    fontSize: "14px",
                    textAlign: "left",
                  }}
                >
                  {getStockStatusDisplay()}
                </TeaserCardDescription>
              )}
              {product.description && (
                <TeaserCardDescription
                  sx={{
                    color: "#43434D",
                    fontSize: "14px",
                    fontWeight: "400",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    textAlign: "left",
                    lineHeight: "18px",
                    m: 0,
                  }}
                >
                  {product.description}
                </TeaserCardDescription>
              )}
            </Box>
          </TeaserCardContent>
          <ProductCardBomButton product={product} />
          <></>
        </TeaserCard>
      </ButtonBase>
    );
  }
};

export default ProductCard;
